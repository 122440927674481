<template>
	<div>
		<b-row style="margin: 0;width: 100%;">
			<!-- 手机端顶部导航栏 -->
			<b-col cols="12" class="d-block d-sm-none" style="padding: 0;">
				<div class="phone_nav">
					<img src="../../static/logo.png" alt="Kitten" class="phone_nav_logo">
					<div class="phone_nav_menu" @click="toggleNav()">
						<img src="../../static/menu.png" alt="">
					</div>
				</div>
			</b-col>
			<transition name="slide">
				<b-row class="d-block d-sm-none slider_row" v-if="showSlider" @click="showSlider = false">
					<b-col cols="9" style="padding: 0;">
						<div style="height: 100vh;background-color: #ffffff;">
							<div class="package_left">
								<div class="package_left_logo">
									<img src="../../static/logo.png" alt="Kitten" class="package_left_logo_logo">
									<div class="package_left_logoInfo">
										<img src="../../static/pic_qb_touzi@2x.png" alt=""
											class="package_left_logoInfo_bgimg">
										<div class="package_left_logoInfo_mid">
											<div>
												<div class="package_left_logoInfo_mid_tit" @click="pageShowFun()">
													<p>{{ $t('package.portfolio_value') }}</p>
													<img src="../../static/whitedown.png" alt="">
												</div>
												<p class="package_left_logoInfo_mid_address">
													{{maskString(wallet.address)}}
												</p>
											</div>
											<p class="package_left_logoInfo_mid_zichan">$ {{ethJiazhi}}</p>
											<div class="package_left_logoInfo_mid_bot">
												<p>{{balance}} {{rpcListDefaul.symbol}}</p>
												<div style="display: flex;">
													<img src="../../static/icon_qb_tz_erweima@2x.png" alt=""
														@click="showErweimaFun()">
													<img src="../../static/icon_qb_tz_fuzhi@2x.png" alt=""
														v-clipboard:copy="wallet.address" v-clipboard:success="onCopy"
														v-clipboard:error="onError">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_one" @click="goUrl()">
										<img src="../../static/icon_qb_wenjianjia@2x.png" alt="">
										<p>{{ $t('package.wallet') }}</p>
									</div>
									<div class="package_left_nav_one" style="background-color:#E5F4FE;">
										<img src="../../static/icon_qb_fasong@2x.png" alt="">
										<p>{{ $t('package.send') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_one" @click="pageSteShow()">
										<img src="../../static/icon_qb_shezhi@2x.png" alt="">
										<p>{{ $t('package.settings') }}</p>
									</div>
									<div class="package_left_nav_one" @click="logout()">
										<img src="../../static/icon_qb_dengchu@2x.png" alt="">
										<p>{{ $t('package.logout') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_two" @click.stop="xiasi()">
										<p>{{ $t('package.other_apps') }}</p>
										<img :src="!showall?require('../../static/icon_qb_lb_zhankai@2x.png'):require('../../static/icon_qb_lb_yzk@2x.png')"
											alt="">
									</div>
									<b-collapse id="collapseExample" v-model="showall">
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_yingyong@2x.png" alt="">
											<p>{{ $t('package.apps') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_nft@2x.png" alt="">
											<p>{{ $t('package.nft_manager') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_jiaohuan@2x.png" alt="">
											<p>{{ $t('package.exchange') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_duzhu@2x.png" alt="">
											<p>{{ $t('package.stake') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_shoudao@2x.png" alt="">
											<p>{{ $t('package.received') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_maimai@2x.png" alt="">
											<p>{{ $t('package.buy_sell') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_hetong@2x.png" alt="">
											<p>{{ $t('package.contract') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_xinxi@2x.png" alt="">
											<p>{{ $t('package.info') }}</p>
										</div>
									</b-collapse>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</transition>
			<!-- pc端显示 -->
			<b-col md="3" class="d-none d-md-block" style="padding: 0;">
				<div class="package_left add_height">
					<div class="package_left_logo">
						<img src="../../static/logo.png" alt="Kitten" class="package_left_logo_logo">
						<div class="package_left_logoInfo">
							<img src="../../static/pic_qb_touzi@2x.png" alt="" class="package_left_logoInfo_bgimg">
							<div class="package_left_logoInfo_mid">
								<div>
									<div class="package_left_logoInfo_mid_tit" @click="pageShowFun()">
										<p>{{ $t('package.portfolio_value') }}</p>
										<img src="../../static/whitedown.png" alt="">
									</div>
									<p class="package_left_logoInfo_mid_address">{{maskString(wallet.address)}}</p>
								</div>
								<p class="package_left_logoInfo_mid_zichan">$ {{ethJiazhi}}</p>
								<div class="package_left_logoInfo_mid_bot">
									<p>{{balance}} {{rpcListDefaul.symbol}}</p>
									<div style="display: flex;">
										<img src="../../static/icon_qb_tz_erweima@2x.png" alt=""
											@click="showErweimaFun()">
										<img src="../../static/icon_qb_tz_fuzhi@2x.png" alt=""
											v-clipboard:copy="wallet.address" v-clipboard:success="onCopy"
											v-clipboard:error="onError">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="package_left_nav">
									<div class="package_left_nav_one" @click="goUrl()">
										<img src="../../static/icon_qb_wenjianjia@2x.png" alt="">
										<p>{{ $t('package.wallet') }}</p>
									</div>
									<div class="package_left_nav_one" style="background-color:#E5F4FE;">
										<img src="../../static/icon_qb_fasong@2x.png" alt="">
										<p>{{ $t('package.send') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_one" @click="pageSteShow()">
										<img src="../../static/icon_qb_shezhi@2x.png" alt="">
										<p>{{ $t('package.settings') }}</p>
									</div>
									<div class="package_left_nav_one" @click="logout()">
										<img src="../../static/icon_qb_dengchu@2x.png" alt="">
										<p>{{ $t('package.logout') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_two" @click.stop="xiasi()">
										<p>{{ $t('package.other_apps') }}</p>
										<img :src="!showall?require('../../static/icon_qb_lb_zhankai@2x.png'):require('../../static/icon_qb_lb_yzk@2x.png')"
											alt="">
									</div>
									<b-collapse id="collapseExample" v-model="showall">
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_yingyong@2x.png" alt="">
											<p>{{ $t('package.apps') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_nft@2x.png" alt="">
											<p>{{ $t('package.nft_manager') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_jiaohuan@2x.png" alt="">
											<p>{{ $t('package.exchange') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_duzhu@2x.png" alt="">
											<p>{{ $t('package.stake') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_shoudao@2x.png" alt="">
											<p>{{ $t('package.received') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_maimai@2x.png" alt="">
											<p>{{ $t('package.buy_sell') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_hetong@2x.png" alt="">
											<p>{{ $t('package.contract') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_xinxi@2x.png" alt="">
											<p>{{ $t('package.info') }}</p>
										</div>
						</b-collapse>
					</div>
				</div>
			</b-col>
			<b-col md="9">
				<div class="package_gg">
					<div class="package_gg_left">
						<img src="../../static/pic_xgn_logo@2x.png" alt="" class="d-none d-md-block">
						<div class="package_gg_left_right d-none d-md-block">
							<p class="package_gg_left_right_p1">{{ $t('package.new_feature_stake_any_amount_of_eth') }}</p>
							<p class="package_gg_left_right_p2">{{ $t('package.stake_eth_no_minimum') }}<!-- <span>{{ $t('package.stake_now') }}</span> -->
							</p>
						</div>
						<div class="package_gg_left_right_sm d-block d-sm-none">
							<p class="package_gg_left_right_p1">{{ $t('package.new_feature_stake_any_amount_of_eth') }}</p>
							<p class="package_gg_left_right_p2">{{ $t('package.stake_eth_no_minimum') }}<!-- <span>{{ $t('package.stake_now') }}</span> -->
							</p>
						</div>
					</div>
					<!-- <div class="d-none d-md-block">
						<div class="package_gg_right">
							<div>{{ $t('package.advertise_with_us') }}</div>
							<img src="../../static/icon_xiaoxi@2x.png" alt="">
						</div>
					</div> -->
				</div>
				<div style="width: 100%;display: table;">
					<b-row class="mb-4" style="margin: 0;width: 100%;">
						<b-col md="4" style="padding: 0;" class="d-block d-sm-none">
							<div class="package_net_sm">
								<p class="package_net_tit d-none d-md-block">{{ $t('package.network') }}</p>
								<div class="package_net_one" @click="choseneting()">
									<div>
										<p class="package_net_one_p1">{{rpcListDefaul.name}}</p>
										<p class="package_net_one_p2">{{ $t('package.block_height') }}{{blockNumber}}</p>
									</div>
									<img :src="rpcListDefaul.img" alt="">
								</div>
							</div>
						</b-col>
						<div style="width: 100%;height: 15px;" class="d-block d-sm-none"></div>
						<b-col md="8" style="padding: 0;">
							<div class="send_tokens">
								<div class="send_tokens_mid">
									<p class="send_tokens_tit">{{ $t('package.send') }}</p>
									<div class="send_tokens_mid_yue">{{ $t('zhensend.balance') }}{{balance_yue}}</div>
									<b-row style="margin: 0;width: 100%;">
										<b-col cols="12" md="6" style="padding: 0;">
											<div class="d-none d-md-block">
												<div class="send_tokens_chose" v-b-toggle.tokenlist>
													<div class="send_tokens_chose_left" v-if="transToken">
														<img :src="transToken.img" alt="">
														<p>{{transToken.symbol}}</p>
													</div>
													<img src="../../static/down.png" alt=""
														class="send_tokens_chose_right">
												</div>
												<b-collapse id="tokenlist" v-model="tokenShow">
													<div class="token_list">
														<div class="token_list_one" v-for="(value,index) in tokenList"
															:key="index" @click="choseToken(index)">
															<div class="send_tokens_chose_left">
																<img :src="value.img" alt="">
																<p>{{value.symbol}}</p>
															</div>
															<img :src="value.active?require('../../static/icon_xuanzhong@2x.png'):require('../../static/icon_weixuanzhong@2x.png')"
																alt="" class="token_list_one_right">
														</div>
													</div>
												</b-collapse>
											</div>
											<div class="d-block d-sm-none">
												<div class="send_tokens_chose" v-b-toggle.tokenlist
													style="width: 100%;margin-top: 5px;">
													<div class="send_tokens_chose_left" v-if="transToken">
														<img :src="transToken.img" alt="">
														<p>{{transToken.symbol}}</p>
													</div>
													<img src="../../static/down.png" alt=""
														class="send_tokens_chose_right">
												</div>
												<b-collapse id="tokenlist" v-model="tokenShow">
													<div class="token_list">
														<div class="token_list_one" v-for="(value,index) in tokenList"
															:key="index" @click="choseToken(index)">
															<div class="send_tokens_chose_left">
																<img :src="value.img" alt="">
																<p>{{value.name}}</p>
															</div>
															<img :src="value.active?require('../../static/icon_xuanzhong@2x.png'):require('../../static/icon_weixuanzhong@2x.png')"
																alt="" class="token_list_one_right">
														</div>
													</div>
												</b-collapse>
											</div>
										</b-col>
										<b-col cols="12" md="6" style="padding: 0;">
											<div class="d-none d-md-block">
												<div class="send_tokens_chose" style="margin-left: 7px;">
													<input type="text" :placeholder="$t('zhensend.token_amount_prompt')"
														class="send_tokens_chose_inp" v-model="amount">
													<div class="send_tokens_chose_right2">
														<img src="../../static/icon_erjym_guanbi@2x.png" alt=""
															class="send_tokens_chose_right" @click="amount = ''">
														<div class="send_tokens_chose_right2_max" @click="mostPost()">
															{{ $t('zhensend.max_limit') }}</div>
													</div>
												</div>
											</div>
											<div class="d-block d-sm-none">
												<div class="send_tokens_chose" style="width: 100%;margin-top: 15px;">
													<input type="text" :placeholder="$t('zhensend.token_amount_prompt')"
														class="send_tokens_chose_inp" v-model="amount">
													<div class="send_tokens_chose_right2">
														<img src="../../static/icon_erjym_guanbi@2x.png" alt=""
															class="send_tokens_chose_right" @click="amount = ''">
														<div class="send_tokens_chose_right2_max" @click="mostPost()">
															{{ $t('zhensend.max_limit') }}</div>
													</div>
												</div>
											</div>
										</b-col>
									</b-row>
									<div class="send_tokens_mid_tip" v-if="Number(balance) < 0.00042">
										<div class="send_tokens_mid_tip_top">
											<img src="../../static/tanhao.png" alt="">
											<p>{{ $t('zhensend.your') }} {{rpcListDefaul.symbol}} {{ $t('zhensend.low_balance') }}</p>
										</div>
										<p class="send_tokens_mid_tip_bot">{{ $t('zhensend.transaction_fee') }} {{rpcListDefaul.symbol}} {{ $t('zhensend.cannot_send') }} {{rpcListDefaul.symbol}}
											{{ $t('zhensend.near_zero_balance') }}</p>
									</div>
									<div class="send_one">
										<div class="set_one_top">
											<input type="text" :placeholder="$t('zhensend.enter_address')" class="send_tokens_chose_inp"
												v-model="transAddress">
											<img src="../../static/down.png" alt="" v-b-toggle.addrss>
										</div>
										<b-collapse id="addrss" v-model="addressListShow">
											<div class="youxianji_one" v-for="(value,index) in addressList" :key="index"
												@click="setAddress(value)">
												<div class="youxianji_one_left">
													<p style="font-size: calc(8px + 0.4vw);margin-left: 0;">{{value}}
													</p>
												</div>
											</div>
										</b-collapse>
									</div>
									<div class="send_shouxufei">
										<p class="send_shouxufei_tit">{{ $t('zhensend.fee') }}</p>
										<div class="send_shouxufei_top">
											<!-- <div class="send_shouxufei_top_left">
												<div>
													<div class="send_shouxufei_top_one">
														<p style="margin-left: 0;">0.42 美元</p>
														<img src="../../static/icon_qbzz_jiantou@2x.png" alt="">
														<img src="../../static/icon_qbzz_shijian@2x.png" alt="">
														<p>15分钟</p>
														<img src="../../static/down.png" alt="" v-b-toggle.youxianji>
													</div>
													<b-collapse id="youxianji">
														<div v-for="(value,index) in list" :key="index"
															:class="value.active?'youxianji_one_active youxianji_one':'youxianji_one'"
															@click="choseing(index)">
															<div class="youxianji_one_left">
																<img :src="value.img" alt="">
																<p>{{value.name}}</p>
															</div>
															<div class="youxianji_one_right">
																<img src="../../static/icon_sz_sz@2x.png" alt="">
																<p>{{value.times}}</p>
															</div>
														</div>
													</b-collapse>
												</div>
												<p>0.000121 以太币</p>
											</div> -->
											<p class="send_shouxufei_top_right">{{ $t('zhensend.total') }} {{transactionFeeInEth}}
												{{rpcListDefaul.symbol}}
											</p>
										</div>
										<div class="send_shouxufei_top" v-if="showGasBz">
											<div class="send_shouxufei_top_left">
												<p>{{rpcListDefaul.symbol}}{{ $t('zhensend.insufficient_fee') }}</p>
											</div>
										</div>
									</div>
									<div class="send_one">
										<div class="set_one_top">
											<p>{{ $t('zhensend.custom') }}</p>
											<img src="../../static/down.png" alt="" v-b-toggle.zidingyi>
										</div>
										<b-collapse id="zidingyi">
											<p class="youxianji_tip">{{ $t('zhensend.max_fee') }}</p>
											<div class="youxianji_one">
												<input type="text" placeholder="50" class="send_tokens_chose_inp"
													style="background: none;" v-model="maxFeePerGas">
												<div class="youxianji_one_left">
													<p style="font-size: calc(8px + 0.4vw);margin-left: 0;">
														Gwei</p>
												</div>
											</div>
											<p class="youxianji_tip">{{ $t('zhensend.max_tip') }}</p>
											<div class="youxianji_one">
												<input type="text" placeholder="2" class="send_tokens_chose_inp"
													style="background: none;" v-model="maxPriorityFeePerGas">
												<div class="youxianji_one_left">
													<p style="font-size: calc(8px + 0.4vw);margin-left: 0;">
														Gwei</p>
												</div>
											</div>
											<p class="youxianji_tip">Gas Limit</p>
											<div class="youxianji_one">
												<input type="text" placeholder="21000" class="send_tokens_chose_inp"
													style="background: none;" v-model="customGasLimit">
												<div class="youxianji_one_left">
													<p style="font-size: calc(8px + 0.4vw);margin-left: 0;">
														Gas</p>
												</div>
											</div>
										</b-collapse>
									</div>
									<div class="popup_content_buttons">
										<div class="popup_content_buttons_right" @click="sendTransaction()">{{ $t('zhensend.send_transaction') }}</div>
									</div>
								</div>
							</div>
						</b-col>
						<b-col md="4" style="padding: 0;" class="d-none d-md-block">
							<div class="package_net_sm">
								<p class="package_net_tit d-none d-md-block">{{ $t('package.network') }}</p>
								<div class="package_net_one" @click="choseneting()">
									<div>
										<p class="package_net_one_p1">{{rpcListDefaul.name}}</p>
										<p class="package_net_one_p2">{{ $t('package.block_height') }}{{blockNumber}}</p>
									</div>
									<img :src="rpcListDefaul.img" alt="">
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</b-col>
		</b-row>
		<!-- 选择网络 -->
		<chose-net :showPage="showPage" @updateShowPage="updateShowPage" @updateNet="updateNet"></chose-net>
		<!-- 我的钱包二维码 -->
		<main-erweima :showErweiam="showErweiam" @updateErweima="updateShowPage"></main-erweima>
		<!-- 我的纸钱包 -->
		<page-package :pageShow="pageShow" @updatePageshow="updateShowPage"></page-package>
		<!-- 设置 -->
		<page-set :pageSet="pageSet" @updatePageSet="updateShowPage"></page-set>
		<!-- 添加代币 -->
		<add-token :showAdd="showAdd" @updateShowAdd="updateShowPage"></add-token>
		<div style="position: fixed;width: 100%;height:100%;background-color: rgba(0, 0, 0, 0.2);top:0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 999999999999;"
			v-if="isLoading">
			<b-spinner v-if="isLoading" label="Loading..." style="width: 3rem; height: 3rem;"
				variant="primary"></b-spinner>
		</div>
		<b-modal id="modal-center" centered :title="$t('send.line3')" :ok-title="$t('send.line4')" :cancel-title="$t('cancel')" @ok="logOutSure()">
			<p class="my-4">{{ $t('package.are_you_sure_logout') }}</p>
		</b-modal>
	</div>
</template>

<script>
	import ChoseNet from '@/components/choseNet.vue';
	import MainErweima from '@/components/main.vue';
	import PagePackage from '@/components/pagePackage.vue';
	import PageSet from '@/components/set.vue';
	import AddToken from '@/components/addToken.vue';
	// import jsonData from '../../static/rpc.json';
	import abiData from '../../static/abi.json';
	import {
		ethers
	} from 'ethers';
	import axios from 'axios';
	var web3 = null;
	export default {
		name: "MyPackage",
		components: {
			ChoseNet,
			MainErweima,
			PagePackage,
			PageSet,
			AddToken
		},
		data() {
			return {
				showall: false,
				showSlider: false,
				currentPage: 1,
				perPage: 4,
				showPage: false,
				showErweiam: false,
				pageShow: false,
				pageSet: false,
				showAdd: false,
				tokenShow: false,
				list: [{
						name: this.$t('mps.normal_priority'),
						active: true,
						img: require('../../static/right.png'),
						times: this.$t('mps.fifteen_minutes')
					},
					{
						name: this.$t('mps.higher_priority'),
						active: false,
						img: require('../../static/icon_ggyxj@2x.png'),
						times: this.$t('mps.fifteen_minutes')
					},
					{
						name: this.$t('mps.highest_priority'),
						active: false,
						img: require('../../static/icon_zgyxj@2x.png'),
						times: this.$t('mps.fifteen_minutes')
					}
				],
				tokenList: [],
				isLoading: false,
				wallet: null,
				rpcListDefaul: null,
				rpcList: null,
				blockNumber: 0,
				balance: 0,
				extendedTokenContractABI: null,
				addressList: [],
				transAddress: '',
				addressListShow: false,
				balance_yue: 0,
				transToken: null,
				amount: '', // 转账金额
				maxFeePerGas: '', // 自定义最高费用 (gwei)
				maxPriorityFeePerGas: '', // 自定义最高小费 (gwei)
				customGasLimit: '', // 自定义 Gas Limit
				transactionHash: null, // 交易哈希
				provider: null, // 网络提供者
				timer: null,
				showGasBz: false,
				transactionFeeInEth: '0',
				ethBalance: 0,
				ethPrice: 0,
				ethJiazhi: 0
			};
		},
		computed: {
			dynamicClass() {
				return {
					'popup_content_buttons_right defaul': this.passwo == '',
					'popup_content_buttons_right': this.passwo != ''
				};
			},
		},
		created() {
			this.wallet = JSON.parse(localStorage.getItem("wallet"));
			console.log(this.wallet)
			this.rpcListDefaul = JSON.parse(localStorage.getItem("rpc"));
			this.extendedTokenContractABI = abiData.tokenContractABI;
			web3 = this.$store.state.web3;

			if (localStorage.getItem("addressList") && localStorage.getItem("addressList") != null) {
				this.addressList = JSON.parse(localStorage.getItem("addressList"));
			}
			this.initData();
		},
		methods: {
			logout() {
				this.$bvModal.show('modal-center')
			},
			logOutSure() {
				this.$router.push('/')
			},
			async mostPost() {
				if (this.transToken.balance == 0) {
					this.$bvToast.toast(this.$t('zhensend.zero_balance'), {
						title: this.$t('zhensend.zero_balance'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					return;
				}
				if (this.transToken.address) {
					this.amount = this.transToken.balance;
				} else {
					this.isLoading = true;
					const maxTransferableAmount = await this.calculateMaxTransferableAmount();
					this.isLoading = false;
					this.amount = maxTransferableAmount;
				}
			},
			async calculateMaxTransferableAmount() {
				const provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
				const walletNew = new ethers.Wallet(this.wallet.privateKey, provider);

				// 获取账户余额
				const balanceBigNumber = await walletNew.getBalance();

				// 获取默认或自定义的 Gas 数据
				let gasPrice = await provider.getGasPrice();
				let gasLimit = this.customGasLimit ? ethers.BigNumber.from(this.customGasLimit) : ethers.BigNumber
					.from('21000'); // 默认 Gas Limit

				// 使用自定义的 maxFeePerGas 和 maxPriorityFeePerGas
				const maxFeePerGas = this.maxFeePerGas ?
					ethers.utils.parseUnits(this.maxFeePerGas, "gwei") :
					ethers.utils.parseUnits("50", "gwei"); // 默认 50 Gwei

				const maxPriorityFeePerGas = this.maxPriorityFeePerGas ?
					ethers.utils.parseUnits(this.maxPriorityFeePerGas, "gwei") :
					ethers.utils.parseUnits("2", "gwei"); // 默认 2 Gwei
				console.log(maxPriorityFeePerGas)
				// 如果用户没有指定 maxFeePerGas，则使用 gasPrice
				const effectiveGasPrice = maxFeePerGas || gasPrice;

				// 计算交易费用
				const transactionFee = effectiveGasPrice.mul(gasLimit);

				// 计算最大可转账金额
				const maxTransferableAmountBigNumber = balanceBigNumber.sub(transactionFee);

				// 如果计算结果小于 0，则不能转账任何金额
				if (maxTransferableAmountBigNumber.lt(ethers.constants.Zero)) {
					return ethers.constants.Zero;
				}

				// 返回可转账的最大金额（以 ETH 为单位）
				return ethers.utils.formatEther(maxTransferableAmountBigNumber);
			},
			setAddress(address) {
				this.transAddress = address;
				this.addressListShow = false;
			},
			initData(type) {
				this.isLoading = true;
				Promise.all([this.getEthBalance(), this.getBlockHeight(), this.calculateDefaultFee(), this.getZichan()])
					.then((resList) => {
						console.log('resList--', resList);
						var tokenLocaList = JSON.parse(localStorage.getItem(this.wallet.address + '_' + this
							.rpcListDefaul.chainId));
						if (type && type == 1) {
							for (var l = 0; l < tokenLocaList.length; l++) {
								tokenLocaList[l].active = false;
							}
							this.transToken = tokenLocaList[0];
							tokenLocaList[0].active = true;
							this.tokenList = tokenLocaList;
							this.balance_yue = this.balance;
						} else {
							if (this.$route.query.toekninfo == undefined) {
								for (var i = 0; i < tokenLocaList.length; i++) {
									tokenLocaList[i].active = false;
								}
								this.transToken = tokenLocaList[0];
								tokenLocaList[0].active = true;
								this.tokenList = tokenLocaList;
								this.balance_yue = this.balance;
							} else {
								var data = JSON.parse(this.$route.query.toekninfo);
								this.transToken = data;
								for (var j = 0; j < tokenLocaList.length; j++) {
									tokenLocaList[j].active = false;
									if (tokenLocaList[j].address == data.address) {
										tokenLocaList[j].active = true;
										this.balance_yue = data.balance;
									}
								}
								this.tokenList = tokenLocaList;
							}
						}

						this.isLoading = false;
					}).catch(error => {
						this.isLoading = false;
						console.log('error--', error);
					})
			},
			getZichan() {
				var ethRequestUrl =
					'https://api.ethplorer.io/getAddressInfo/' + this.wallet.address + '?apiKey=EK-3wfck-EXTpbdU-umU1Y'
				axios.get(ethRequestUrl)
					.then(response => {
						console.log(response)
						this.ethBalance = response.data.ETH.balance == 0 ? 0 : Number(response.data.ETH.balance)
							.toFixed(6);
						this.ethPrice = Number(response.data.ETH.price.rate).toFixed(6);
						if (this.ethBalance != 0 && this.ethPrice != 0) {
							this.ethJiazhi = (this.ethBalance * this.ethPrice).toFixed(6);
						} else {
							this.ethJiazhi = 0;
						}
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			async calculateDefaultFee() {
				// // 创建Provider实例
				// const provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);

				// // 获取当前的 Gas Price
				// let gasPrice = await provider.getGasPrice();

				// // 使用自定义的 maxFeePerGas 或当前网络的 Gas Price
				// let maxFeePerGas = this.maxFeePerGas ?
				// 	ethers.utils.parseUnits(this.maxFeePerGas, "gwei") :
				// 	gasPrice;

				// // 使用自定义的 maxPriorityFeePerGas 或默认值 2 Gwei
				// const maxPriorityFeePerGas = this.maxPriorityFeePerGas ?
				// 	ethers.utils.parseUnits(this.maxPriorityFeePerGas, "gwei") :
				// 	ethers.utils.parseUnits("2", "gwei");

				// // 如果 gasPrice 仍未获取到，则使用默认值 20 Gwei
				// if (!maxFeePerGas) {
				// 	maxFeePerGas = ethers.utils.parseUnits("20", "gwei");
				// }

				// // 使用自定义的 Gas Limit 或默认值
				// const gasLimit = this.customGasLimit ?
				// 	ethers.BigNumber.from(this.customGasLimit) :
				// 	ethers.BigNumber.from("21000"); // ETH 转账的默认 Gas Limit

				// // 计算默认手续费（Wei）
				// const defaultFeeWei = maxFeePerGas.add(maxPriorityFeePerGas).mul(gasLimit);

				// // 将手续费转换为 ETH
				// const defaultFeeEth = ethers.utils.formatEther(defaultFeeWei);
				// 创建Provider实例
				const provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);

				// 获取当前的 Gas Price
				let gasPrice = await provider.getGasPrice();

				// 如果无法获取 Gas Price，则设置为默认值 20 Gwei
				if (!gasPrice) {
					gasPrice = ethers.utils.parseUnits("20", "gwei");
				}

				// 设置默认的 Gas Limit
				const defaultGasLimit = ethers.BigNumber.from("21000"); // ETH 转账的默认 Gas Limit

				// 计算默认手续费（Wei）
				const defaultFeeWei = gasPrice.mul(defaultGasLimit);

				// 将手续费转换为 ETH
				const defaultFeeEth = ethers.utils.formatEther(defaultFeeWei);
				this.transactionFeeInEth = defaultFeeEth;
				console.log(defaultFeeEth)
				return defaultFeeEth;
			},
			// async copyText(data) {
			// 	try {
			// 		const textToCopy = data;
			// 		await navigator.clipboard.writeText(textToCopy);
			// 		this.$bvToast.toast("复制成功", {
			// 			title: `提示`,
			// 			variant: "success",
			// 			solid: true,
			// 		});
			// 	} catch (err) {
			// 		console.error("复制失败", err);
			// 	}
			// },
			// 复制成功 or 失败（提示信息！！！）
			onCopy: function(e) {
				console.log('复制成功！' + e)
				this.$bvToast.toast(this.$t('copy_success'), {
					title: this.$t('tip'),
					variant: "success",
					solid: true,
				});
			},
			onError: function(e) {
				console.log('复制失败！' + e)
			},
			maskString(str) {
				if (str.length <= 12) return str; // 如果长度小于12，则不需要遮罩
				return str.slice(0, 6) + '...' + str.slice(-6); // 截取前6和后6个字符，中间用三个点代替
			},
			// 获取主币数量
			async getEthBalance(isLoad) {
				if (isLoad == 1) {
					this.isLoading = true;
				}
				// 获取主币余额
				this.provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
				// 获取钱包地址的余额
				const balanceBigNumber = await this.provider.getBalance(this.wallet.address);
				// 将余额转换为以太单位（ETH 或 BNB）
				const balance = ethers.utils.formatEther(balanceBigNumber);
				this.balance = Number(balance).toFixed(6);
				// this.isLoading = false;
			},
			async getBlockHeight(isLoad) {
				try {
					if (isLoad == 1) {
						this.isLoading = true;
					}
					const blockNumber = await web3.eth.getBlockNumber();
					this.blockNumber = blockNumber;
					// this.isLoading = false;
				} catch (error) {
					console.error('Error fetching block height:', error);
					this.$bvToast.toast( this.$t('package.failed_to_get_height') + error, {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
				}
			},
			updateNet(val) {
				web3 = this.$store.state.web3;
				this.rpcListDefaul = JSON.parse(val);
				this.initData(1);
			},
			toggleNav() {
				this.showSlider = true;
			},
			xiasi() {
				this.showall = !this.showall;
			},
			choseneting() {
				this.showPage = true;
			},
			showErweimaFun() {
				this.showErweiam = true;
			},
			pageShowFun() {
				this.pageShow = true;
			},
			pageSteShow() {
				this.pageSet = true;
			},
			pageShowAdd() {
				this.showAdd = true;
			},
			updateShowPage(newValue) {
				this.showPage = newValue;
				this.showErweiam = newValue;
				this.pageShow = newValue;
				this.pageSet = newValue;
				this.showAdd = newValue;
				if (localStorage.getItem("addressList") && localStorage.getItem("addressList") != null) {
					this.addressList = JSON.parse(localStorage.getItem("addressList"));
				}
			},
			goUrl() {
				this.$router.push({
					path: '/Package'
				})
			},
			choseing(index) {
				for (var i = 0; i < this.list.length; i++) {
					this.list[i].active = false;
				}
				this.list[index].active = true;
			},
			async choseToken(index) {
				this.isLoading = true;
				for (var i = 0; i < this.tokenList.length; i++) {
					this.tokenList[i].active = false;
				}
				this.tokenList[index].active = true;
				this.tokenShow = false;
				this.transToken = this.tokenList[index];
				this.amount = '';
				if (this.tokenList[index].address == '') {
					// 获取主币余额
					this.provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
					// 获取钱包地址的余额
					const balanceBigNumber = await this.provider.getBalance(this.wallet.address);
					// 将余额转换为以太单位（ETH 或 BNB）
					const balance = ethers.utils.formatEther(balanceBigNumber);
					this.balance_yue = balance;
					this.isLoading = false;
				} else {
					const extendedContract = new web3.eth.Contract(this.extendedTokenContractABI, this.tokenList[index]
						.address);
					try {
						const balance = await extendedContract.methods.balanceOf(this.wallet.address).call();
						const decimals = await extendedContract.methods.decimals().call();
						this.balance_yue = balance / (10 ** decimals);
						this.isLoading = false;
					} catch (error) {
						console.error(`Error fetching balance for ${this.tokenList[index].address}:`, error);
					}
				}
			},
			async sendTransaction() {
				if (!this.amount) {
					this.$bvToast.toast(this.$t('zhensend.enter_amount'), {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					return;
				}
				if (!this.transAddress) {
					this.$bvToast.toast(this.$t('zhensend.enter_address'), {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					return;
				}
				this.isLoading = true;
				try {
					this.provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
					// 使用私钥创建钱包实例
					var walletNew = new ethers.Wallet(this.wallet.privateKey, this.provider);
					// 获取发送者账户的余额
					const balanceBigNumber = await walletNew.getBalance();
					// const balance = ethers.utils.formatEther(balanceBigNumber);

					// 转换金额为 Wei
					const value = ethers.utils.parseEther(this.amount);

					// 获取默认的 Gas 数据
					let gasPrice = await this.provider.getGasPrice();
					let gasLimit = this.customGasLimit ? ethers.BigNumber.from(this.customGasLimit) : ethers.BigNumber
						.from('21000'); // 默认 21000

					// 如果获取 gasPrice 失败，使用默认值 20 Gwei
					if (!gasPrice) {
						gasPrice = ethers.utils.parseUnits("20", "gwei");
					}

					// 处理自定义的费用设置
					const maxFeePerGas = this.maxFeePerGas ?
						ethers.utils.parseUnits(this.maxFeePerGas, "gwei") :
						ethers.utils.parseUnits("50", "gwei"); // 默认 50 Gwei

					const maxPriorityFeePerGas = this.maxPriorityFeePerGas ?
						ethers.utils.parseUnits(this.maxPriorityFeePerGas, "gwei") :
						ethers.utils.parseUnits("2", "gwei"); // 默认 2 Gwei
					if (this.transToken.address) {
						// 代币转账逻辑
						const tokenAbi = [
							"function transfer(address to, uint amount) public returns (bool)",
							"function decimals() public view returns (uint8)",
							"function balanceOf(address account) public view returns (uint256)"
						];

						// 创建代币合约实例
						const tokenContract = new ethers.Contract(this.transToken.address, tokenAbi, walletNew);

						// 获取代币的小数位
						const decimals = await tokenContract.decimals();
						const amountInTokens = ethers.utils.parseUnits(this.amount, decimals);
						// 获取用户的代币余额
						const tokenBalanceBigNumber = await tokenContract.balanceOf(walletNew.address);

						// 检查代币余额是否足够
						if (tokenBalanceBigNumber.lt(amountInTokens)) {
							this.$bvToast.toast(this.$t('zhensend.insufficient_token_balance'), {
								title: this.$t('tip'),
								autoHideDelay: 5000,
								variant: 'danger',
								solid: true,
								toaster: 'b-toaster-top-right',
								appendToast: true
							});
							this.isLoading = false;
							return;
						}

						// 估算代币转账的 Gas Limit
						gasLimit = this.customGasLimit ?
							ethers.BigNumber.from(this.customGasLimit) :
							await tokenContract.estimateGas.transfer(this.transAddress, amountInTokens);
						// 计算交易费用
						const transactionFee = maxFeePerGas.mul(gasLimit);
						const transactionFeeInEth = ethers.utils.formatEther(transactionFee); // 转换为 ETH
						console.log(`${this.$t('zhensend.transaction_cost')}: ${transactionFeeInEth} ETH`);
						this.transactionFeeInEth = transactionFeeInEth;
						// 比较 ETH 余额和所需费用
						if (balanceBigNumber.lt(transactionFee)) {
							this.$bvToast.toast(this.$t('zhensend.insufficient_gas'), {
								title: this.$t('tip'),
								autoHideDelay: 5000,
								variant: 'danger',
								solid: true,
								toaster: 'b-toaster-top-right',
								appendToast: true
							});
							this.isLoading = false;
							return;
						}

						// 发送代币转账交易
						const transaction = await tokenContract.transfer(this.transAddress, amountInTokens, {
							gasLimit: gasLimit,
							maxFeePerGas: maxFeePerGas,
							maxPriorityFeePerGas: maxPriorityFeePerGas,
						});

						this.transactionHash = transaction.hash;
						console.log("Token Transaction sent:", transaction);
						this.isLoading = false;
						this.$bvToast.toast(this.$t('zhensend.transaction_success'), {
							title:this.$t('tip'),
							autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
							variant: 'success',
							solid: true,
							toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
							appendToast: true // 每次显示新的 Toast 而不是替换旧的
						});
						// 等待交易被确认
						await transaction.wait();
						//获取代币余额
						const extendedContract = new web3.eth.Contract(this.extendedTokenContractABI, this.transToken
							.address);
						try {
							const balance = await extendedContract.methods.balanceOf(this.wallet.address).call();
							const decimals = await extendedContract.methods.decimals().call();
							this.balance_yue = balance / (10 ** decimals);
						} catch (error) {
							console.error(`Error fetching balance for ${this.transToken.address}:`, error);
						}
						this.getZichan();
						return;
					} else {
						// 计算交易费用
						const transactionFee = maxFeePerGas.mul(gasLimit);
						const transactionFeeInEth = ethers.utils.formatEther(transactionFee); // 转换为 ETH
						console.log(`${this.$t('zhensend.transaction_cost')}: ${transactionFeeInEth} ETH`);
						this.transactionFeeInEth = transactionFeeInEth;
						const totalCost = transactionFee.add(value);
						console.log(totalCost)
						// 比较余额和所需费用
						if (balanceBigNumber.lt(totalCost)) {
							this.$bvToast.toast(this.$t('zhensend.insufficient_gas'), {
								title: this.$t('tip'),
								autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
								variant: 'danger',
								solid: true,
								toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
								appendToast: true // 每次显示新的 Toast 而不是替换旧的
							});
							this.isLoading = false;
							this.showGasBz = true;
							return;
						} else {
							this.showGasBz = false;
						}

						// 构建交易
						const tx = {
							to: this.transAddress,
							value: value,
							gasLimit: gasLimit,
							maxFeePerGas: maxFeePerGas,
							maxPriorityFeePerGas: maxPriorityFeePerGas,
						};
						// 发送交易
						const transaction = await walletNew.sendTransaction(tx);
						this.transactionHash = transaction.hash;
						console.log("Transaction sent:", transaction);
						this.isLoading = false;
						this.$bvToast.toast(this.$t('zhensend.transaction_success'), {
							title: this.$t('tip'),
							autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
							variant: 'success',
							solid: true,
							toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
							appendToast: true // 每次显示新的 Toast 而不是替换旧的
						});
						// 等待交易被确认
						await transaction.wait();
						// 获取交易成功后的余额
						const balanceBigNumber2 = await walletNew.getBalance();
						this.balance_yue = ethers.utils.formatEther(balanceBigNumber2);
						this.balance = ethers.utils.formatEther(balanceBigNumber2);
						this.getZichan();
					}
				} catch (error) {
					console.error("交易失败: ", error);
					alert(this.$t('zhensend.transaction_failed'));
				}
			},
			async estimateGas() {
				try {
					// 估算 Gas Limit
					const estimate = await this.provider.estimateGas({
						to: this.transAddress,
						value: ethers.utils.parseEther(this.amount),
					});
					return estimate;
				} catch (error) {
					console.error("Gas Limit 估算失败: ", error);
					alert(this.$t('zhensend.gas_limit_failed'));
					return ethers.BigNumber.from("21000"); // 返回一个默认值
				}
			},
		}
	};
</script>
<style lang="less">
	.package_record {
		.table-header th {
			color: #27183F;
		}

		.table-row td {
			line-height: 40px;
			color: #27183F;
			font-size: calc(12px + 0.2vw);
		}
	}

	.table-header2 th {
		color: #27183F !important;
		font-size: calc(8px + 0.3vw) !important;
	}

	.table-row2 td {
		/* border:none; */
	}
</style>
<style scoped lang="less">
	.popup_content_buttons {
		display: flex;
		justify-content: center;
		margin: calc(15px + 1vw) 0;

		.popup_content_buttons_left {
			width: calc(120px + 1vw);
			padding: calc(5px + 0.4vw) 0;
			border: 1px solid #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #0091FE;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.popup_content_buttons_right {
			width: calc(120px + 1vw);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: calc(5px + 0.4vw) 0;
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #FFFFFF;
			margin-left: calc(12px + 0.2vw);
			cursor: pointer;
		}

		.defaul {
			background-color: #E3E5ED;
		}
	}

	.table-image {
		width: 14px;
		height: 14px;
		object-fit: cover;
		border-radius: 50%;
	}

	.ml-2 {
		margin-left: 0.5rem;
	}

	.slider_row {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 0;
		margin: 0;
		z-index: 2;
		height: 100vh;
		overflow-y: scroll;
	}

	/* Slide transition for mobile view */
	.slide-enter-active,
	.slide-leave-active {
		transition: transform 0.3s ease, opacity 0.3s ease;
	}

	.slide-enter,
	.slide-leave-to {
		transform: translateX(-100%);
	}

	@media (max-width: 768px) {
		.sidebar {
			transform: translateX(-100%);
		}

		.slide-enter-to {
			transform: translateX(0);
		}

		.slide-leave {
			transform: translateX(-100%);
		}
	}

	.package_tokens_content_add {
		display: flex;
		align-items: center;
		margin-top: calc(10px + 0.6vw);
		cursor: pointer;

		img {
			width: calc(4px + 0.4vw) !important;
		}

		p {
			color: #0091FE;
			font-size: calc(4px + 0.6vw);
			margin-left: 5px;
		}
	}

	.phone_nav {
		height: 60px;
		background: rgba(255, 255, 255, 0.5);
		box-shadow: 0px 4px 4px 0px rgba(158, 188, 254, 0.2);
		border-radius: 0px 0px 24px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;

		.phone_nav_logo {
			width: 118px;
			height: 32px;
		}

		.phone_nav_menu {
			width: 56px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #1A183F;
			border-radius: 5px;

			img {
				width: 26px;
				height: 26px;
			}
		}
	}

	.add_height {
		min-height: 100vh;
		position: relative;
		height: 100%;
	}

	.package_left {
		width: 100%;
		background-color: #ffffff;

		.package_left_logo {
			padding: calc(10px + 1vw);

			.package_left_logo_logo {
				width: 118px;
				height: 32px;
			}

			.package_left_logoInfo {
				display: table;
				position: relative;
				border-radius: 10px;
				margin-top: calc(10px + 1vw);

				.package_left_logoInfo_bgimg {
					width: 100%;
				}

				.package_left_logoInfo_mid {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					padding: calc(10px + 1vw);
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.package_left_logoInfo_mid_tit {
						display: flex;
						align-items: center;
						cursor: pointer;

						p {
							font-size: calc(8px + 0.5vw);
							color: #ffffff;
							font-weight: 600;
						}

						img {
							width: calc(8px + 0.5vw);
							margin-left: 5px;
						}
					}

					.package_left_logoInfo_mid_address {
						font-size: calc(8px + 0.3vw);
						color: #ffffff;
					}

					.package_left_logoInfo_mid_zichan {
						font-size: calc(12px + 1vw);
						color: #ffffff;
						font-weight: 600;
					}

					.package_left_logoInfo_mid_bot {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							font-size: calc(8px + 0.4vw);
							color: #ffffff;
						}

						img {
							width: calc(12px + 1vw);
							margin-left: 4px;
							cursor: pointer;
						}
					}
				}
			}
		}

		.package_left_nav {
			width: 100%;
			padding-bottom: calc(5px + 0.2vw);
			border-bottom: 1px solid #E3E5ED;
			cursor: pointer;

			.package_left_nav_one {
				width: 100%;
				padding-left: calc(10px + 1vw);
				display: flex;
				align-items: center;
				height: calc(30px + 1vw);

				img {
					width: calc(12px + 0.5vw);
					height: calc(12px + 0.5vw);
				}

				p {
					color: #1A183F;
					font-size: calc(10px + 0.4vw);
					margin-left: calc(10px + 0.3vw);
				}
			}

			.package_left_nav_two {
				width: 100%;
				padding: 0 calc(10px + 1vw);
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: calc(30px + 1vw);

				img {
					width: calc(12px + 0.5vw);
					height: calc(12px + 0.5vw);
				}

				p {
					color: #1A183F;
					font-size: calc(10px + 0.4vw);
				}
			}
		}
	}

	.package_gg {
		width: 100%;
		padding: calc(6px + 0.5vw);
		display: flex;
		align-items: center;
		justify-content: space-between;

		.package_gg_left {
			display: flex;
			align-items: center;

			img {
				width: calc(20px + 1vw);
			}

			.package_gg_left_right {
				margin-left: calc(3px + 1vw);

				.package_gg_left_right_p1 {
					color: #1A183F;
					font-size: calc(8px + 0.4vw);
					font-weight: 600;
				}

				.package_gg_left_right_p2 {
					color: #757C8B;
					font-size: calc(8px + 0.4vw);

					span {
						color: #0091FE;
					}
				}
			}

			.package_gg_left_right_sm {
				.package_gg_left_right_p1 {
					color: #1A183F;
					font-size: 12px;
					font-weight: 600;
					margin-top: 10px;
				}

				.package_gg_left_right_p2 {
					color: #757C8B;
					font-size: 12px;
					margin-top: 5px;

					span {
						color: #0091FE;
					}
				}
			}
		}

		.package_gg_right {
			display: flex;
			align-items: center;

			div {
				background-color: #0091FE;
				font-size: calc(12px + 0.2vw);
				color: #ffffff;
				padding: calc(5px + 0.2vw) calc(6px + 1vw);
				display: table;
				border-radius: 8px;
			}

			img {
				width: calc(10px + 1vw);
				margin-left: calc(3px + 1vw);
			}
		}
	}

	.package_tokens {
		width: 100%;
		padding-right: calc(6px + 0.5vw);

		.package_tokens_mid {
			display: table;
			position: relative;
			width: 100%;

			img {
				width: 100%;
			}

			.package_tokens_content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: calc(6px + 1vw);

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(10px + 0.6vw);
					font-weight: 600;
				}


			}

			.package_tokens_mid_mid {
				width: 100%;
				padding: calc(6px + 1vw);
				background-color: #ffffff;
				border-radius: 10px;

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(10px + 0.6vw);
					font-weight: 600;
				}

				.daibi_name {
					img {
						width: calc(10px + 1vw);
					}

					p {
						color: #27183F;
						font-size: calc(8px + 0.4vw);
					}
				}

				.daibi_jiage {
					color: #27183F;
					font-size: calc(8px + 0.4vw);
					line-height: calc(10px + 1vw);
				}

				.daibi_button {
					p {
						font-size: calc(8px + 0.2vw);
						line-height: calc(10px + 1vw);
						cursor: pointer;
					}
				}
			}
		}
	}

	.package_tokens_sm {
		width: 100%;
		margin-top: 15px;

		.package_tokens_mid {
			display: table;
			position: relative;
			width: 100%;

			img {
				width: 100%;
			}

			.package_tokens_content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: calc(6px + 1vw);

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(12px + 0.6vw);
					font-weight: 600;
				}

				.package_tokens_content_add {
					display: flex;
					align-items: center;
					margin-top: calc(10px + 0.6vw);
					cursor: pointer;

					img {
						width: 10px;
					}

					p {
						color: #0091FE;
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}

			.package_tokens_mid_mid {
				width: 100%;
				padding: calc(6px + 1vw);
				background-color: #ffffff;
				border-radius: 10px;

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(10px + 0.6vw);
					font-weight: 600;
				}

				.daibi_name {
					img {
						width: calc(10px + 1vw);
					}

					p {
						color: #27183F;
						font-size: calc(8px + 0.4vw);
					}
				}

				.daibi_jiage {
					color: #27183F;
					font-size: calc(8px + 0.4vw);
					line-height: calc(10px + 1vw);
				}

				.daibi_button {
					p {
						font-size: calc(8px + 0.2vw);
						line-height: calc(10px + 1vw);
						cursor: pointer;
					}
				}
			}
		}
	}

	.package_net {
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(6px + 1vw);

		.package_net_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}

		.package_net_one {
			width: 100%;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F3F5FB;
			border-radius: 4px;
			cursor: pointer;

			img {
				width: calc(35px + 1vw);
			}

			.package_net_one_p1 {
				color: #1A183F;
				font-size: calc(5px + 0.6vw);
				font-weight: 600;
			}

			.package_net_one_p2 {
				color: #757C8B;
				font-size: calc(3px + 0.6vw);
				margin-top: calc(1px + 0.3vw);
			}
		}
	}

	.package_net_sm {
		width: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(6px + 1vw);

		.package_net_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}

		.package_net_one {
			width: 100%;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F3F5FB;
			border-radius: 4px;
			cursor: pointer;

			img {
				width: calc(35px + 1vw);
			}

			.package_net_one_p1 {
				color: #1A183F;
				font-size: 14px;
				font-weight: 600;
			}

			.package_net_one_p2 {
				color: #757C8B;
				font-size: 12px;
				margin-top: 4px;
			}
		}
	}

	.package_record {
		width: 100%;
		background-color: #ffffff;
		padding: calc(6px + 1vw) calc(6px + 1vw) 0;
		border-radius: 10px;

		.package_record_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}
	}

	.package_record_sm {
		width: 100%;
		background-color: #ffffff;
		padding: calc(6px + 1vw) calc(6px + 1vw) 0;
		border-radius: 10px;
		margin-bottom: 15px;

		.package_record_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}
	}

	.send_tokens {
		width: 100%;
		padding-right: calc(6px + 0.5vw);

		.send_tokens_mid {
			width: 100%;
			padding: calc(6px + 1vw);
			background-color: #ffffff;
			border-radius: 10px;

			.send_tokens_tit {
				color: #1A183F;
				font-size: calc(10px + 0.6vw);
				font-weight: 600;
			}

			.send_tokens_mid_yue {
				display: flex;
				justify-content: flex-end;
				font-size: calc(6px + 0.5vw);
				color: #0091FE;
			}
		}

		.send_tokens_chose {
			width: calc(100% - 7px);
			height: calc(40px + 1vw);
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 calc(10px + 0.4vw);
			border: 1px solid #E3E5ED;
			border-radius: 10px;
			cursor: pointer;

			.send_tokens_chose_left {
				display: flex;
				align-items: center;

				img {
					width: calc(10px + 1vw);
				}

				p {
					font-size: calc(4px + 0.8vw);
					color: #1A183F;
					margin-left: 5px;
				}
			}

			.send_tokens_chose_right {
				width: calc(2px + 0.8vw);
			}

			.send_tokens_chose_right2 {
				display: flex;
				align-items: center;

				.send_tokens_chose_right2_max {
					width: calc(40px + 1vw);
					height: calc(15px + 1vw);
					display: flex;
					align-items: center;
					justify-content: center;
					color: #757C8B;
					font-size: calc(4px + 0.4vw);
					background-color: #F3F5FB;
					border-radius: 5px;
					margin-left: 5px;
					cursor: pointer;
				}
			}
		}

		.send_tokens_chose_inp {
			flex: 1;
			border: none;
			outline: none;
			font-size: calc(4px + 0.8vw);
			color: #1A183F;
			margin-right: 5px;
		}

		.send_tokens_mid_tip {
			width: 100%;
			padding: calc(10px + 0.4vw);
			border-radius: 10px;
			background-color: #F3F5FB;
			margin-top: calc(10px + 0.4vw);

			.send_tokens_mid_tip_top {
				display: flex;
				align-items: center;

				img {
					width: calc(10px + 0.4vw);
				}

				p {
					font-size: calc(4px + 0.8vw);
					color: #1A183F;
					margin-left: 10px;
				}
			}

			.send_tokens_mid_tip_bot {
				font-size: calc(4px + 0.8vw);
				color: #757C8B;
				margin-top: 15px;
			}
		}

		.send_one {
			width: 100%;
			border: 1px solid #E3E5ED;
			border-radius: 10px;
			margin-top: 15px;
			padding: calc(5px + 1vw);

			.set_one_top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					font-size: calc(10px + 0.4vw);
					color: #1A183F;
				}

				img {
					width: calc(5px + 0.6vw);
				}
			}
		}

		.send_shouxufei {
			margin-top: 15px;

			.send_shouxufei_tit {
				color: #1A183F;
				font-size: calc(10px + 0.4vw);
				font-weight: 600;
			}

			.send_shouxufei_top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 15px;

				.send_shouxufei_top_left {
					display: flex;
					align-items: center;

					.send_shouxufei_top_one {
						background-color: #F3F5FB;
						height: calc(20px + 1vw);
						padding: 0 calc(10px + 0.5vw);
						display: flex;
						align-items: center;
						border-radius: 4px;
					}

					p {
						font-size: calc(4px + 0.8vw);
						color: #FF445B;
						margin-left: 5px;
					}

					img {
						width: calc(10px + 0.4vw);
						margin-left: 5px;
					}
				}

				.send_shouxufei_top_right {
					font-size: calc(4px + 0.8vw);
					color: #757C8B;
				}
			}
		}

		.youxianji_tip {
			font-size: calc(10px + 0.4vw);
			color: #1A183F;
			text-align: left;
			margin-top: calc(10px + 0.4vw);
		}

		.youxianji_one {
			width: 100%;
			height: calc(40px + 1vw);
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 calc(10px + 0.4vw);
			border: 1px solid #E3E5ED;
			background-color: #E3E5ED;
			border-radius: 10px;
			margin-top: calc(10px + 0.4vw);
			cursor: pointer;

			.youxianji_one_left {
				display: flex;
				align-items: center;

				img {
					width: calc(5px + 1vw);
				}

				p {
					font-size: calc(10px + 0.4vw);
					color: #1A183F;
					margin-left: 5px;
				}
			}

			.youxianji_one_right {
				display: flex;
				align-items: center;

				img {
					width: calc(3px + 1vw);
				}

				p {
					font-size: calc(8px + 0.4vw);
					color: #0091FE;
					margin-left: 5px;
				}
			}
		}

		.youxianji_one_active {
			border: 1px solid #0091FE;
		}
	}

	.token_list {
		width: 100%;

		.token_list_one {
			width: calc(100% - 7px);
			height: calc(40px + 1vw);
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 calc(10px + 0.4vw);
			border: 1px solid #E3E5ED;
			border-radius: 10px;
			cursor: pointer;
			margin-top: calc(10px + 0.4vw);

			.send_tokens_chose_left {
				display: flex;
				align-items: center;

				img {
					width: calc(10px + 1vw);
				}

				p {
					font-size: calc(4px + 0.8vw);
					color: #1A183F;
					margin-left: 5px;
				}
			}

			.token_list_one_right {
				width: calc(4px + 0.8vw);
			}
		}
	}
</style>