<template>
	<div>
		<div class="index_one" >
			<my-header style="visibility: hidden;" @findPacage="findMyPacage"></my-header>
			<img src="../../static/pic_sy_banner.png" class="img-fluid index_one_bgimg" alt="Responsive Image">
			<b-container class="bv-example-row mt-0">
				<b-row class="text-center" align-h="center">
					<b-col cols="11" sm="8" lg="6" class="header_view" style="padding: 0;">
						<!-- 创建钱包 -->
						<div class="create_commondiv" v-if="showCreate">
							<p class="create_commonp d-none d-md-block" style="margin-top: 0">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_all d-none d-md-block">
								<div class="create_all_one" @click="choseCreateType(1)">
									<div>
										<p class="create_all_one_p1">{{ $t('chdhl.keystore_file') }}</p>
										<p class="create_all_one_p2">{{ $t('chdhl.keystore_warning') }}</p>
									</div>
									<img src="../../static/pic_sywjdr_pt@2x.png" alt="">
								</div>
								<div class="create_all_one" @click="choseCreateType(2)">
									<div>
										<p class="create_all_one_p1">{{ $t('chdhl.mnemonic_phrase') }}</p>
										<p class="create_all_one_p2">{{ $t('chdhl.mnemonic_warning') }}</p>
									</div>
									<img src="../../static/pic_xzdrfs_zhujici@2x.png" alt="">
								</div>
							</div>
							<div class="create_all_sm d-block d-sm-none">
								<div class="create_all_one" @click="choseCreateType(1)">
									<div>
										<p class="create_all_one_p1">{{ $t('chdhl.keystore_file') }}</p>
										<p class="create_all_one_p2">{{ $t('chdhl.keystore_warning') }}</p>
									</div>
									<img src="../../static/pic_sywjdr_pt@2x.png" alt="">
								</div>
								<div class="create_all_one" @click="choseCreateType(2)">
									<div>
										<p class="create_all_one_p1">{{ $t('chdhl.mnemonic_phrase') }}</p>
										<p class="create_all_one_p2">{{ $t('chdhl.mnemonic_warning') }}</p>
									</div>
									<img src="../../static/pic_xzdrfs_zhujici@2x.png" alt="">
								</div>
							</div>
							<div class="create_content_buttons">
								<div class="popup_content_buttons_left" @click="goHome()">{{ $t('chdhl.return_to_home') }}</div>
								<!-- <div class="popup_content_buttons_right">访问钱包</div> -->
							</div>
							<div class="create_content_two">
								<img src="../../static/icon_jianyi@2x.png" alt="">
								<div>
									<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
									<p class="popup_content_two_tit2">
										{{ $t('chdhl.offline_storage_advice') }}</p>
								</div>
							</div>
						</div>
						<!-- 密钥库文件 创建钱包 -->
						<div class="create_commondiv" v-if="showCreate1">
							<p class="create_commonp d-none d-md-block">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_common_white">
								<div class="popup_content_stype">
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('step_1') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('input_password') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.download_file') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('chdhl.step_3') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.access_wallet') }}</p>
									</div>
								</div>
								<div class="popup_content_chosefile">
									<div class="popup_content_chosefile_left">
										<p class="popup_content_chosefile_p1">{{ $t('components.step_1') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('input_password') }}</p>
									</div>
								</div>
								<div class="popup_content_pass">
									<input :placeholder="$t('please_enter_password')" v-model="passwo" :type="!showPass?'password':'text'" />
									<img src="../../static/icon_xianshi@2x.png" alt="" v-if="!showPass"
										@click="showPass = true">
									<img src="../../static/icon_srmm_yincang@2x.png" alt="" v-if="showPass"
										@click="showPass = false">
								</div>
								<div class="popup_content_pass">
									<input :placeholder="$t('chdhl.reenter_password')" v-model="passwo1"
										:type="!showPass2?'password':'text'" />
									<img src="../../static/icon_xianshi@2x.png" alt="" v-if="!showPass2"
										@click="showPass2 = true">
									<img src="../../static/icon_srmm_yincang@2x.png" alt="" v-if="showPass2"
										@click="showPass2 = false">
								</div>

								<div class="create_content_buttons">
									<div class="popup_content_buttons_left" @click="backType(1)">{{ $t('back') }}</div>
									<div class="popup_content_buttons_right" @click="choseCreateType(3)">{{ $t('chdhl.create_wallet') }}</div>
								</div>
								<div class="create_content_two">
									<img src="../../static/icon_jianyi@2x.png" alt="">
									<div>
										<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
										<p class="popup_content_two_tit2">
											{{ $t('chdhl.offline_storage_advice') }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- 密钥库文件 创建钱包2 -->
						<div class="create_commondiv" v-if="showCreate2">
							<p class="create_commonp d-none d-md-block">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_common_white">
								<div class="popup_content_stype">
									<div class="popup_content_stype_right">
										<img src="../../static/icon_wancheng@2x.png" alt=""
											class="popup_content_stype_left_img">
										<p class="popup_content_stype_left_p">{{ $t('input_password') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.download_file') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('chdhl.step_3') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.access_wallet') }}</p>
									</div>
								</div>
								<div class="popup_content_chosefile">
									<div class="popup_content_chosefile_left">
										<p class="popup_content_chosefile_p1">{{ $t('step_2_label') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.download_keystore_file') }}</p>
										<p class="popup_content_chosefile_p3">{{ $t('chdhl.important_info_before_download') }}</p>
									</div>
								</div>
								<div class="create_common_white_mid">
									<div class="create_common_white_mid_one">
										<img src="../../static/pic_xzmykwj_one@2x.png" alt=""
											class="create_common_white_mid_img">
										<p class="create_common_white_mid_p1">{{ $t('chdhl.dont_lose_it') }}</p>
										<p class="create_common_white_mid_p2">{{ $t('chdhl.be_careful') }}</p>
									</div>
									<div class="create_common_white_mid_one">
										<img src="../../static/pic_xzmykwj_two@2x.png" alt=""
											class="create_common_white_mid_img">
										<p class="create_common_white_mid_p1">{{ $t('chdhl.do_not_share') }}</p>
										<p class="create_common_white_mid_p2">{{ $t('chdhl.phishing_warning') }}</p>
									</div>
									<div class="create_common_white_mid_one">
										<img src="../../static/pic_xzmykwj_three@2x.png" alt=""
											class="create_common_white_mid_img">
										<p class="create_common_white_mid_p1">{{ $t('chdhl.make_a_backup') }}</p>
										<p class="create_common_white_mid_p2">{{ $t('chdhl.protect_it') }}</p>
									</div>
								</div>
								<div class="create_content_buttons">
									<div class="popup_content_buttons_left" @click="backType(2)">{{ $t('back') }}</div>
									<div class="popup_content_buttons_right" @click="choseCreateType(4)">{{ $t('chdhl.confirm_download') }}</div>
								</div>
								<div class="create_content_two">
									<img src="../../static/icon_jianyi@2x.png" alt="">
									<div>
										<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
										<p class="popup_content_two_tit2">
											{{ $t('chdhl.offline_storage_advice') }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- 密钥库文件 创建钱包3 -->
						<div class="create_commondiv" v-if="showCreate3">
							<p class="create_commonp d-none d-md-block">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_common_white">
								<div class="popup_content_stype">
									<div class="popup_content_stype_right">
										<img src="../../static/icon_wancheng@2x.png" alt=""
											class="popup_content_stype_left_img">
										<p class="popup_content_stype_left_p">{{ $t('chdhl.enter_password') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_right">
										<img src="../../static/icon_wancheng@2x.png" alt=""
											class="popup_content_stype_left_img">
										<p class="popup_content_stype_left_p">{{ $t('chdhl.download_file') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('chdhl.step_3') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.access_wallet') }}</p>
									</div>
								</div>
								<div class="popup_content_chosefile">
									<div class="popup_content_chosefile_left d-block d-sm-none">
										<p class="popup_content_chosefile_p1">{{ $t('chdhl.step_3s') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.well_done') }}</p>
										<p class="popup_content_chosefile_p3">{{ $t('chdhl.ready_to_use') }}</p>
										<div class="create_content_buttons">
											<div class="popup_content_buttons_left" @click="keyStoreFind()">{{ $t('chdhl.ready_to_use') }}</div>
											<div class="popup_content_buttons_right" @click="createOther()">{{ $t('chdhl.create_another_wallet') }}
											</div>
										</div>
									</div>
									<div class="popup_content_chosefile_left d-none d-md-block" style="width: 65%;">
										<p class="popup_content_chosefile_p1">{{ $t('chdhl.step_3s') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.well_done') }}</p>
										<p class="popup_content_chosefile_p3">{{ $t('chdhl.ready_to_use') }}</p>
										<div class="create_content_buttons">
											<div class="popup_content_buttons_left" @click="keyStoreFind()">{{ $t('chdhl.access_wallet') }}</div>
											<div class="popup_content_buttons_right" @click="createOther()">{{ $t('chdhl.create_another_wallet') }}
											</div>
										</div>
									</div>
									<div class="create_common_white_right d-none d-md-block">
										<img src="../../static/pic_sy_banner_kpsf_icon@2x.png" alt="">
									</div>
								</div>
								<div class="create_content_two">
									<img src="../../static/icon_jianyi@2x.png" alt="">
									<div>
										<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
										<p class="popup_content_two_tit2">
											{{ $t('chdhl.offline_storage_advice') }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- 助记词 创建钱包1 -->
						<div class="create_commondiv" v-if="showCreate4">
							<p class="create_commonp d-none d-md-block">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_common_white">
								<div class="popup_content_stype">
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('step_1') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.write_down_phrase') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.verify_phrase') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('chdhl.step_3') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.access_wallet') }}</p>
									</div>
								</div>
								<div class="popup_content_chosefile">
									<div class="popup_content_chosefile_left">
										<p class="popup_content_chosefile_p1">{{ $t('components.step_1') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.write_down_phrase') }}</p>
									</div>
								</div>
								<div class="popup_content_select">
									<div class="popup_content_select_refush" @click="choseCreateType(2)">
										<img src="../../static/icon_gengxin@2x.png" alt="">
										<p>{{ $t('chdhl.update') }}</p>
									</div>
									<!-- <b-form-select v-model="selected" :options="options"
										class="popup_content_select_content"></b-form-select> -->
								</div>
								<div class="popup_content_zjc">
									<b-row style="margin:0;">
										<b-col cols="4" md="4" v-for="(value,index) in mnemonicArr" :key="index"
											style="margin-top: calc(10px + 1vw);">
											<div>
												<p style="text-align: left;">{{index + 1}}. {{value}}</p>
												<div style="width: 100%;height: 1px;background-color: #757C8B;">
												</div>
											</div>
										</b-col>
									</b-row>
								</div>
								<!-- <div class="popup_content_zjcmore">
									<div class="popup_content_zjcmore_show">
										<p class="popup_content_zjcmore_top">您还有多余的话吗？</p>
										<div class="popup_content_zjcmore_bot" v-b-toggle.collapseExample>
											<p>添加您的词</p>
											<img src="../../static/down.png" alt="">
										</div>
									</div>
									<b-collapse id="collapseExample">
										<div class="popup_content_zjcmore_Extra">
											<input placeholder="请输入其它短语" v-model="otherword"
												:type="!showPass3?'password':'text'" />
											<img src="../../static/icon_xianshi@2x.png" alt="" v-if="!showPass3"
												@click="showPass3 = true">
											<img src="../../static/icon_srmm_yincang@2x.png" alt="" v-if="showPass3"
												@click="showPass3 = false">
										</div>
									</b-collapse>
								</div> -->
								<div class="create_content_buttons">
									<div class="popup_content_buttons_left" @click="backType(3)">{{ $t('back') }}</div>
									<div class="popup_content_buttons_right" @click="choseCreateType(5)">{{ $t('chdhl.write_them_down') }}</div>
								</div>
								<div class="create_content_two">
									<img src="../../static/icon_jianyi@2x.png" alt="">
									<div>
										<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
										<p class="popup_content_two_tit2">
											{{ $t('chdhl.offline_storage_advice') }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- 助记词 创建钱包2 -->
						<div class="create_commondiv" v-if="showCreate5">
							<p class="create_commonp d-none d-md-block">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_common_white">
								<div class="popup_content_stype">
									<div class="popup_content_stype_right">
										<img src="../../static/icon_wancheng@2x.png" alt=""
											class="popup_content_stype_left_img">
										<p class="popup_content_stype_left_p">{{ $t('chdhl.write_down_phrase') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.verify_phrase') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('chdhl.step_3') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.access_wallet') }}</p>
									</div>
								</div>
								<div class="popup_content_chosefile">
									<div class="popup_content_chosefile_left">
										<p class="popup_content_chosefile_p1">{{ $t('step_2_label') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.verify_phrase') }}</p>
										<p class="popup_content_chosefile_p3">{{ $t('chdhl.select_correct_words') }}</p>
									</div>
								</div>
								<div class="create_common_white_yanzheng">
									<div class="create_common_white_yanzheng_one" v-for="(value,index) in yanzhengList"
										:key="index">
										<p class="create_common_white_yanzheng_tit">{{value.name + 1}}</p>
										<div class="create_common_white_yanzheng_list" v-for="(item,idx) in value.list"
											:key="idx" @click="choseDy(index,idx)">
											<img :src="item.ischose?require('../../static/icon_xuanzhong@2x.png'):require('../../static/icon_weixuanzhong@2x.png')"
												alt="">
											<p>{{item.name}}</p>
										</div>
									</div>
								</div>
								<div class="create_content_buttons">
									<div class="popup_content_buttons_left" @click="backType(4)">{{ $t('back') }}</div>
									<div class="popup_content_buttons_right" @click="choseCreateType(6)">{{ $t('chdhl.verify') }}</div>
								</div>
								<div class="create_content_two">
									<img src="../../static/icon_jianyi@2x.png" alt="">
									<div>
										<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
										<p class="popup_content_two_tit2">
											{{ $t('chdhl.offline_storage_advice') }}</p>
									</div>
								</div>
							</div>
						</div>
						<!-- 助记词 创建钱包3 -->
						<div class="create_commondiv" v-if="showCreate6">
							<p class="create_commonp d-none d-md-block">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp_sm d-block d-sm-none">{{ $t('chdhl.create_new_wallet') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.select_wallet_creation_method') }}</p>
							<p class="create_commonp2">{{ $t('chdhl.already_have_wallet') }}<span @click="findMyPacage()">{{ $t('chdhl.access_wallet') }}</span></p>
							<div class="create_common_white">
								<div class="popup_content_stype">
									<div class="popup_content_stype_right">
										<img src="../../static/icon_wancheng@2x.png" alt=""
											class="popup_content_stype_left_img">
										<p class="popup_content_stype_left_p">{{ $t('chdhl.write_down_phrase') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_right">
										<img src="../../static/icon_wancheng@2x.png" alt=""
											class="popup_content_stype_left_img">
										<p class="popup_content_stype_left_p">{{ $t('chdhl.verify_phrase') }}</p>
									</div>
									<div class="popup_content_stype_mid">
										<div class="popup_content_stype_left_div">
											<div></div>
										</div>
									</div>
									<div class="popup_content_stype_left">
										<div class="popup_content_stype_left_div">{{ $t('chdhl.step_3') }}</div>
										<p class="popup_content_stype_left_p">{{ $t('chdhl.access_wallet') }}</p>
									</div>
								</div>
								<div class="popup_content_chosefile">
									<div class="popup_content_chosefile_left d-block d-sm-none">
										<p class="popup_content_chosefile_p1">{{ $t('chdhl.step_3s') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.well_done') }}</p>
										<p class="popup_content_chosefile_p3">{{ $t('chdhl.ready_to_use') }}</p>
										<div class="create_content_buttons">
											<div class="popup_content_buttons_left" @click="keyStoreFind()">{{ $t('chdhl.access_wallet') }}</div>
											<div class="popup_content_buttons_right" @click="createOther()">{{ $t('chdhl.create_another_wallet') }}
											</div>
										</div>
									</div>
									<div class="popup_content_chosefile_left d-none d-md-block" style="width: 65%;">
										<p class="popup_content_chosefile_p1">{{ $t('chdhl.step_3s') }}</p>
										<p class="popup_content_chosefile_p2">{{ $t('chdhl.well_done') }}</p>
										<p class="popup_content_chosefile_p3">{{ $t('chdhl.ready_to_use') }}</p>
										<div class="create_content_buttons">
											<div class="popup_content_buttons_left" @click="keyStoreFind()">{{ $t('chdhl.access_wallet') }}</div>
											<div class="popup_content_buttons_right" @click="createOther()">{{ $t('chdhl.create_another_wallet') }}
											</div>
										</div>
									</div>
									<div class="create_common_white_right d-none d-md-block">
										<img src="../../static/pic_sy_banner_kpsf_icon@2x.png" alt="">
									</div>
								</div>
								<div class="create_content_two">
									<img src="../../static/icon_jianyi@2x.png" alt="">
									<div>
										<p class="popup_content_two_tit">{{ $t('chdhl.not_recommended') }}</p>
										<p class="popup_content_two_tit2">
											{{ $t('chdhl.offline_storage_advice') }}</p>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<my-footer></my-footer>
		<!-- 访问我的钱包弹窗 -->
		<my-import :showPage="showPage" @updateShowPage="updateShowPage"></my-import>
	</div>
</template>

<script>
	import MyHeader from '@/components/header.vue';
	import MyFooter from '@/components/footer.vue';
	import MyImport from '@/components/import.vue';
	// import Web3 from 'web3';
	import {
		ethers
	} from 'ethers';
	export default {
		name: 'MyCreate',
		components: {
			MyHeader,
			MyFooter,
			MyImport
		},
		data() {
			return {
				passwo: "",
				passwo1: "",
				showPass: false,
				showPass2: false,
				showPass3: false,
				showPage: false,
				showCreate: true,
				showCreate1: false,
				showCreate2: false,
				showCreate3: false,
				showCreate4: false,
				showCreate5: false,
				showCreate6: false,
				selected: 12,
				isCollapsed: true,
				options: [{
						value: 12,
						text: '12'
					},
					{
						value: 24,
						text: '24'
					}
				],
				yanzhengList: [],
				keystore: null,
				wallet: null,
				mnemonicArr: [],
				otherword: '',
				otherwordArr: [],
				shaixuanList:[],
				allWordSrr:[],
				keystoreFileName:''
			}
		},
		methods: {
			goHome(){
				this.$router.push({path:'/'})
			},
			choseCreateType(type) {
				this.showCreate = false;
				if (type == 1) {
					// 密钥库文件创建钱包1
					this.showCreate1 = true;
				}
				if (type == 3) {
					// 密钥库文件创建钱包 2
					if (!this.passwo || !this.passwo1) {
						this.$bvToast.toast(this.$t('please_enter_password'), {
							title: this.$t('tip'),
							autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
							variant: 'danger',
							solid: true,
							toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
							appendToast: true // 每次显示新的 Toast 而不是替换旧的
						});
						return;
					}
					if (this.passwo != this.passwo1) {
						this.$bvToast.toast(this.$t('chdhl.password_mismatch'), {
							title: this.$t('tip'),
							autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
							variant: 'danger',
							solid: true,
							toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
							appendToast: true // 每次显示新的 Toast 而不是替换旧的
						});
						return;
					}
					this.generateWallet();
					this.showCreate1 = false;
					this.showCreate2 = true;
				}
				if (type == 4) {
					// 密钥库文件创建钱包 3
					// 下载keystore文件
					let downloadElement = document.createElement("a");
					let href = this.keystore;
					downloadElement.href = href;
					downloadElement.download = this.keystoreFileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
					this.showCreate2 = false;
					this.showCreate3 = true;
				}
				if (type == 2) {
					this.mnemonicArr = [];
					this.otherword = '';
					this.otherwordArr = [];
					this.shaixuanList = [];
					this.allWordSrr = [];
					// 助记词创建钱包1
					this.showCreate4 = true;
					// 生成随机助记词
					const mnemonic = ethers.utils.entropyToMnemonic(ethers.utils.randomBytes(16));
					// 从助记词创建钱包
					const wallet = ethers.Wallet.fromMnemonic(mnemonic);

					this.wallet = {
						mnemonic: wallet.mnemonic.phrase,
						privateKey: wallet.privateKey,
						address: wallet.address,
					};
					this.mnemonicArr = mnemonic.split(" ");
					this.shaixuanList = mnemonic.split(" ");
				}
				if (type == 5) {
					// 助记词创建钱包 2
					this.showCreate4 = false;
					this.showCreate5 = true;
					if (this.otherword != '') {
						this.otherwordArr = this.otherword.split(" ");
						this.allWordSrr = this.mnemonicArr.concat(this.otherwordArr);
						this.shaixuanList = this.shaixuanList.concat(this.otherwordArr);
					}
					var listALL = this.allWordSrr.length?this.allWordSrr:this.mnemonicArr;
					for(var i=0;i<3;i++){
						const randomNums = this.getRandomFieldsAndIndex(3,listALL);
						const randomNumsNmae = randomNums[this.getRandomFieldsAndIndex2(randomNums)];
						this.yanzhengList[i] = {
							name:randomNumsNmae,
							list:[
								{
									ischose: true,
									name:listALL[randomNums[0]]
								},
								{
									ischose: false,
									name:listALL[randomNums[1]]
								},
								{
									ischose: false,
									name:listALL[randomNums[2]]
								},
							]
						}
					}
				}
				if (type == 6) {
					// 助记词创建钱包 3
					var arr = [];
					for(var j=0;j<this.yanzhengList.length;j++){
						for(var l=0;l<this.yanzhengList[j].list.length;l++){
							if(this.yanzhengList[j].list[l].ischose == true){
								arr.push(l)
							}
						}
					}
					var data = this.allWordSrr.length?this.allWordSrr:this.mnemonicArr;
					if(data[this.yanzhengList[0].name] == this.yanzhengList[0].list[arr[0]].name && data[this.yanzhengList[1].name] == this.yanzhengList[1].list[arr[1]].name && data[this.yanzhengList[2].name] == this.yanzhengList[2].list[arr[2]].name){
						this.showCreate5 = false;
						this.showCreate6 = true;
					}else {
						this.$bvToast.toast(this.$t('chdhl.verification_failed'), {
							title: this.$t('tip'),
							autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
							variant: 'danger',
							solid: true,
							toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
							appendToast: true // 每次显示新的 Toast 而不是替换旧的
						});
						return;
					}
				}
			},
			backType(type) {
				if (type == 1) {
					this.showCreate = true;
					this.showCreate1 = false;
				}
				if (type == 2) {
					this.showCreate2 = false;
					this.showCreate1 = true;
				}
				if (type == 3) {
					this.showCreate = true;
					this.showCreate4 = false;
				}
				if (type == 4) {
					this.shaixuanList = this.wallet.mnemonic.split(" ");
					this.showCreate4 = true;
					this.showCreate5 = false;
				}
			},
			// 再次创建钱包
			createOther() {
				this.showCreate = true;
				this.showCreate1 = false;
				this.showCreate2 = false;
				this.showCreate3 = false;
				this.showCreate4 = false;
				this.showCreate5 = false;
				this.showCreate6 = false;
			},
			// 访问我的钱包
			findMyPacage() {
				this.showPage = true;
			},
			updateShowPage(newValue) {
				this.showPage = newValue;
			},
			choseDy(index, idx) {
				for (var j = 0; j < this.yanzhengList[index].list.length; j++) {
					this.yanzhengList[index].list[j].ischose = false;
				}
				this.yanzhengList[index].list[idx].ischose = true;
				this.$forceUpdate();
			},
			generateWallet() {
				// 生成随机助记词
				const mnemonic = ethers.utils.entropyToMnemonic(ethers.utils.randomBytes(16));
				// 从助记词创建钱包
				const wallet = ethers.Wallet.fromMnemonic(mnemonic);

				this.wallet = {
					mnemonic: wallet.mnemonic.phrase,
					privateKey: wallet.privateKey,
					address: wallet.address,
				};
				this.generateKeystore();
			},
			async generateKeystore() {
				try {
					// // 从私钥创建钱包对象
					// const wallet = new ethers.Wallet(this.wallet.privateKey);
					// // 生成加密的JSON Keystore
					// const encryptedJson = await wallet.encrypt(this.passwo);

					// // 创建一个 Blob 对象来下载
					// const blob = new Blob([encryptedJson], {
					// 	type: 'application/json'
					// });
					// this.keystore = URL.createObjectURL(blob);
					
					
					// 从私钥创建钱包对象
					const wallet = new ethers.Wallet(this.wallet.privateKey);
					// 生成加密的JSON Keystore
					const encryptedJson = await wallet.encrypt(this.passwo);
					
					// 获取当前时间并格式化为 UTC 时间戳
					const now = new Date();
					const timestamp = now.toISOString().replace(/[:]/g, '-');
					
					// 生成 Keystore 文件名，不带 .json
					this.keystoreFileName = `UTC--${timestamp}--${this.wallet.address}`;
					
					// 创建一个 Blob 对象来下载
					const blob = new Blob([encryptedJson], {
						type: 'application/json'
					});
					this.keystore = URL.createObjectURL(blob);
				} catch (error) {
					console.error('生成Keystore文件失败: ', error);
					this.$bvToast.toast(this.$t('chdhl.keystore_generation_failed'), {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
				}
			},
			keyStoreFind() {
				var wallet = JSON.stringify(this.wallet);
				localStorage.setItem("wallet", wallet);
				this.$router.push('/Package');
			},
			getRandomFieldsAndIndex(len) {
				var randomNums = [];
				for (var i = 0; i < len; i++) {
					var num;
					do {
						num = Math.floor(Math.random() * this.shaixuanList.length);
					}while (num === randomNums[randomNums.indexOf(num)]);
					this.shaixuanList.splice(num, 1);
					randomNums.push(num);
				}
				return randomNums;
			},
			getRandomFieldsAndIndex2(arr) {
				var num = Math.floor(Math.random() * arr.length);
				return num;
			}
		},
	}
</script>
<style scoped lang="less">
	.index_one {
		position: relative;
		width: 100%;

		.index_one_bgimg {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	}

	.create_commondiv {
		position: relative;
		z-index: 1;
		padding-bottom: calc(30px + 1vw);

		.create_common_white_yanzheng {
			padding: 0 calc(5px + 1vw);

			.create_common_white_yanzheng_one {
				width: 100%;
				height: calc(30px + 1vw);
				background-color: #F3F5FB;
				border-radius: 8px;
				display: flex;
				align-items: center;
				margin-top: calc(10px + 1vw);

				.create_common_white_yanzheng_tit {
					font-size: calc(10px + 0.4vw);
					color: #1A183F;
					font-weight: 600;
					margin-left: calc(10px + 0.5vw);
				}

				.create_common_white_yanzheng_list {
					display: flex;
					align-items: center;
					margin-left: calc(10px + 1vw);
					cursor: pointer;

					img {
						width: calc(1px + 1vw);
					}

					p {
						font-size: calc(10px + 0.4vw);
						color: #1A183F;
						margin-left: calc(1px + 0.5vw);
					}
				}
			}
		}

		.create_commonp {
			font-size: calc(12px + 0.8vw);
			color: #1A183F;
			font-weight: 600;
			margin-top: calc(30px + 1vw);
			margin-bottom: calc(1px + 1vw);
		}

		.create_commonp_sm {
			font-size: calc(12px + 0.8vw);
			color: #1A183F;
			font-weight: 600;
			margin-top: 0px;
			margin-bottom: calc(1px + 1vw);
		}

		.create_commonp2 {
			font-size: calc(12px + 0.2vw);
			color: #1A183F;
			margin-top: calc(1px + 0.2vw);

			span {
				color: #0091FE;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.create_all {
			margin-top: calc(30px + 1vw);

			.create_all_one {
				border: 1px solid #1A183F;
				border-radius: 10px;
				padding: calc(5px + 1vw);
				margin-bottom: calc(1px + 1vw);
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;

				img {
					width: calc(70px + 1vw);
					margin-left: 10px;
				}

				.create_all_one_p1 {
					font-size: calc(12px + 0.4vw);
					color: #1A183F;
					font-weight: 600;
					text-align: left;
				}

				.create_all_one_p2 {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: left;
					margin-top: calc(1px + 0.2vw);
				}
			}
		}

		.create_all_sm {
			margin-top: 15px;

			.create_all_one {
				border: 1px solid #1A183F;
				border-radius: 10px;
				padding: 15px;
				margin-bottom: 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				img {
					width: calc(70px + 1vw);
					margin-left: 10px;
				}

				.create_all_one_p1 {
					font-size: calc(12px + 0.4vw);
					color: #1A183F;
					font-weight: 600;
					text-align: left;
				}

				.create_all_one_p2 {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: left;
					margin-top: calc(1px + 0.2vw);
				}
			}
		}
	}

	.create_content_two {
		display: flex;
		cursor: pointer;
		padding: calc(10px + 0.2vw);
		justify-content: space-between;
		border-radius: 10px;
		margin-top: calc(10px + 0.2vw);
		background-color: #FFF7E8;

		img {
			width: 16px;
			height: 16px;
		}

		.popup_content_two_tit {
			font-size: calc(10px + 0.3vw);
			color: #1A183F;
			font-weight: 600;
			text-align: left;
			margin-left: calc(10px + 0.3vw);
		}

		.popup_content_two_tit2 {
			font-size: calc(10px + 0.3vw);
			color: #1A183F;
			text-align: left;
			margin-left: calc(10px + 0.3vw);
		}
	}

	.create_content_buttons {
		display: flex;
		justify-content: center;
		margin: calc(15px + 1vw) 0;

		.popup_content_buttons_left {
			width: calc(120px + 1vw);
			padding: calc(5px + 0.4vw) 0;
			border: 1px solid #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #0091FE;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.popup_content_buttons_right {
			width: calc(120px + 1vw);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: calc(5px + 0.4vw) 0;
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #FFFFFF;
			margin-left: calc(12px + 0.2vw);
			cursor: pointer;
		}

		.defaul {
			background-color: #E3E5ED;
		}
	}

	.create_common_white {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);
		margin-top: calc(20px + 1vw);

		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}

		.create_common_white_right {
			display: table;
			width: 30%;
			margin-left: calc(12px + 0.6vw);

			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}

		.create_common_white_mid {
			padding: calc(15px + 1vw) 0 0 0;
			display: flex;
			justify-content: space-between;

			.create_common_white_mid_one {
				border: 1px solid #0091FE;
				padding: calc(5px + 1vw);
				width: calc(33.33% - 12px);
				border-radius: 10px;

				.create_common_white_mid_img {
					width: 100%;
				}

				.create_common_white_mid_p1 {
					font-size: calc(12px + 0.4vw);
					color: #1A183F;
					font-weight: 600;
					text-align: left;
					margin-top: calc(10px + 0.2vw);
				}

				.create_common_white_mid_p2 {
					font-size: calc(10px + 0.2vw);
					color: #1A183F;
					font-weight: 600;
					text-align: left;
					margin-top: calc(10px + 0.2vw);
				}
			}
		}

		.popup_content_stype {
			width: 70%;
			margin: calc(15px + 1vw) auto;
			display: flex;
			justify-content: space-between;

			.popup_content_stype_left {
				.popup_content_stype_left_div {
					width: calc(12px + 1vw);
					height: calc(12px + 1vw);
					background-color: #0091FE;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					font-size: calc(10px + 0.3vw);
					margin: 0 auto;
				}

				.popup_content_stype_left_p {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: center;
					margin-top: calc(6px + 0.2vw);
				}
			}

			.popup_content_stype_mid {
				flex: 1;

				.popup_content_stype_left_div {
					height: calc(12px + 1vw);
					display: flex;
					align-items: center;

					div {
						width: 100%;
						height: 2px;
						background-color: #E3E5ED;
					}
				}
			}

			.popup_content_stype_right {
				opacity: 0.5;

				.popup_content_stype_left_div {
					width: calc(12px + 1vw);
					height: calc(12px + 1vw);
					background-color: #0091FE;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					font-size: calc(10px + 0.3vw);
					margin: 0 auto;
				}

				.popup_content_stype_left_img {
					width: calc(12px + 1vw);
					margin: 0 auto;
				}

				.popup_content_stype_left_p {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: center;
					margin-top: calc(6px + 0.2vw);
				}
			}

		}

		.popup_content_suanfa {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			div {
				width: calc(180px + 1vw);
				height: calc(25px + 1vw);
				background-color: #F3F5FB;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.popup_content_address {
			width: 100%;
			border: 1px solid #E3E5ED;
			border-radius: 8px;
			padding: calc(1px + 1vw) 0;
			margin-top: calc(5px + 1vw);

			.popup_content_address_top {
				width: 100%;
				padding: 0 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;

				p {
					font-size: calc(10px + 0.3vw);
					color: #1A183F;
				}

				img {
					width: calc(10px + 0.2vw);
				}
			}

			.popup_content_address_list {
				margin-bottom: calc(5px + 0.5vw);

				.popup_content_address_list_top {
					display: flex;
					align-items: center;
					padding: 0 15px;

					.popup_content_address_list_left {
						width: 20%;
						color: #757C8B;
						font-size: calc(10px + 0.3vw);
					}

					.popup_content_address_list_mid {
						width: 60%;
						color: #757C8B;
						font-size: calc(10px + 0.3vw);
						text-align: left;
					}
				}
			}

			.popup_content_address_one {
				width: 100%;
				padding: 0 15px;
				display: flex;
				height: calc(30px + 1vw);
				align-items: center;

				.popup_content_address_one_left {
					display: flex;
					align-items: center;
					width: 20%;

					img {
						width: calc(10px + 0.6vw);
						margin-right: calc(5px + 0.3vw);
					}

					p {
						color: #1A183F;
						font-size: calc(10px + 0.3vw);
					}
				}

				.popup_content_address_one_mid {
					width: 60%;
					display: flex;
					align-items: center;

					img:nth-child(1) {
						width: calc(10px + 0.8vw);
						height: calc(10px + 0.8vw);
					}

					p {
						font-size: calc(10px + 0.1vw);
						margin-right: calc(5px + 0.1vw);
					}

					img {
						width: calc(10px + 0.6vw);
						height: calc(10px + 0.6vw);
						margin-right: calc(5px + 0.1vw);
					}
				}
			}

			.popup_content_address_one:nth-child(even) {
				background-color: #ffffff;
			}

			.popup_content_address_one:nth-child(odd) {
				background-color: #F3F5FB;
			}
		}

		.popup_content_chosefile {
			display: flex;
			justify-content: space-between;

			.popup_content_chosefile_left {
				.popup_content_chosefile_p1 {
					font-size: calc(12px + 0.2vw);
					color: #757C8B;
					text-align: left;
				}

				.popup_content_chosefile_p2 {
					font-size: calc(10px + 0.6vw);
					color: #1A183F;
					font-weight: 600;
					text-align: left;
					margin: calc(6px + 0.2vw) 0;
				}

				.popup_content_chosefile_p3 {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: left;
				}

				.popup_content_chosefile_button {
					border: 1px solid #0091FE;
					font-size: calc(12px + 0.2vw);
					color: #0091FE;
					padding: calc(8px + 0.2vw) calc(55px + 1vw);
					display: table;
					border-radius: 8px;
					margin-top: calc(10px + 0.8vw);
				}
			}

			.popup_content_chosefile_right {
				width: calc(100px + 1vw);
			}
		}

		.popup_content_select {
			display: flex;
			justify-content: flex-end;
			margin: 0 0 calc(5px + 1vw) 0;

			.popup_content_select_refush {
				display: flex;
				align-items: center;
				margin-right: calc(1px + 1vw);
				cursor: pointer;

				img {
					width: calc(10px + 0.4vw);
				}

				p {
					color: #0091FE;
					font-size: calc(10px + 0.2vw);
					margin-left: 3px;
				}
			}

			.popup_content_select_content {
				width: calc(150px + 1vw);
				outline: none;
				height: calc(30px + 1vw);
				border: 1px solid #E3E5ED;
				border-radius: 4px;
				/* padding-left: calc(5px + 0.5vw); */
			}
		}

		.popup_content_zjc {
			width: 100%;
			border: 1px solid #8BD1FF;
			border-radius: 8px;
			padding-bottom: calc(10px + 1vw);
			/* max-height: calc(282px + 1vw); */
			/* overflow-y: scroll; */
		}

		.popup_content_buttonNext {
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			color: #ffffff;
			padding: calc(8px + 0.2vw) calc(55px + 1vw);
			display: table;
			border-radius: 8px;
			margin: calc(10px + 0.8vw) auto;
			cursor: pointer;
		}

		.popup_content_zjcmore {
			margin-top: calc(5px + 1vw);
			width: 100%;
			border: 1px solid #E3E5ED;
			border-radius: 8px;
			padding: 0 calc(1px + 1vw);

			.popup_content_zjcmore_Extra {
				width: 100%;
				border: 1px solid #E3E5ED;
				margin: calc(1px + 0.5vw) 0;
				padding: 0 calc(1px + 1vw);
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: calc(30px + 1vw);
				border-radius: 8px;

				input {
					flex: 1;
					font-size: calc(10px + 0.3vw);
					color: #1A183F;
					border: none;
					outline: none;
					margin-right: 10px;
				}

				img {
					width: calc(13px + 1vw);
				}
			}

			.popup_content_zjcmore_show {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: calc(30px + 1vw);
			}

			.popup_content_zjcmore_top {
				font-size: calc(10px + 0.3vw);
				color: #1A183F;
				font-weight: 600;
				text-align: left;
			}

			.popup_content_zjcmore_bot {
				display: flex;
				align-items: center;
				cursor: pointer;

				p {
					font-size: calc(10px + 0.2vw);
					color: #757C8B;
				}

				img {
					width: calc(10px + 0.2vw);
					margin-left: 5px;
				}
			}
		}

		.popup_content_pass {
			display: flex;
			align-items: center;
			border: 1px solid #E3E5ED;
			border-radius: 8px;
			margin-top: calc(10px + 1vw);
			padding: 0 calc(5px + 1vw);
			height: calc(40px + 1vw);

			input {
				flex: 1;
				margin-right: 10px;
				font-size: calc(12px + 0.2vw);
				color: #1A183F;
				border: none;
				outline: none;
			}
			// input ::-ms-reveal {
			//   display: none;
			// }
			input::-ms-clear,input::-ms-reveal{
			    display: none;
			}
			img {
				width: calc(13px + 1vw);
			}
		}

		.popup_content_buttons {
			display: flex;
			justify-content: center;
			margin: calc(15px + 1vw) 0;

			.popup_content_buttons_left {
				width: calc(120px + 1vw);
				padding: calc(5px + 0.4vw) 0;
				border: 1px solid #0091FE;
				font-size: calc(12px + 0.2vw);
				border-radius: 8px;
				color: #0091FE;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}

			.popup_content_buttons_right {
				width: calc(120px + 1vw);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: calc(5px + 0.4vw) 0;
				background-color: #0091FE;
				font-size: calc(12px + 0.2vw);
				border-radius: 8px;
				color: #FFFFFF;
				margin-left: calc(12px + 0.2vw);
				cursor: pointer;
			}

			.defaul {
				background-color: #E3E5ED;
			}
		}

		.popup_content_one {
			display: flex;
			cursor: pointer;
			padding: calc(10px + 0.2vw);
			align-items: center;
			justify-content: space-between;
			border: 1px solid #E3E5ED;
			border-radius: 10px;
			margin-top: calc(10px + 0.2vw);

			p {
				font-size: calc(10px + 0.6vw);
				color: #1A183F;
				font-weight: 600;
			}

			img {
				width: calc(60px + 1vw);
			}
		}

		.popup_content_two {
			display: flex;
			cursor: pointer;
			padding: calc(10px + 0.2vw);
			justify-content: space-between;
			border-radius: 10px;
			margin-top: calc(10px + 0.2vw);
			background-color: #FFF7E8;

			img {
				width: 16px;
				height: 16px;
			}

			.popup_content_two_tit {
				font-size: calc(10px + 0.3vw);
				color: #1A183F;
				font-weight: 600;
				text-align: left;
				margin-left: calc(10px + 0.3vw);
			}

			.popup_content_two_tit2 {
				font-size: calc(10px + 0.3vw);
				color: #1A183F;
				text-align: left;
				margin-left: calc(10px + 0.3vw);
			}
		}
	}
</style>