<template>
	<div>
		<div class="help_top">
			<help-header></help-header>
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<p class="help_tit d-none d-md-block">{{ $t('index.mew_team_advice') }}</p>
						<p class="help_tit_sm d-block d-sm-none">{{ $t('index.mew_team_advice') }}</p>
					</b-col>
				</b-row>
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="9" class="header_view" style="padding: 0;">
						<div class="d-none d-md-block">
							<div class="help_search">
								<img src="../../static/icon_bzzx_sousuo@2x.png" alt="">
								<b-form-input :placeholder="$t('help_detail.search_articles')" class="search_input"></b-form-input>
							</div>
						</div>
						<div class="d-block d-sm-none">
							<div class="help_search_sm">
								<img src="../../static/icon_bzzx_sousuo@2x.png" alt="">
								<b-form-input :placeholder="$t('help_detail.search_articles')" class="search_input"></b-form-input>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="help_bottom">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="9" style="padding: 0;">
						<div class="help_bottom_all">
							<div class="help_bottom_one d-none d-md-block" v-for="(value,index) in list" :key="index" @click="goList()">
								<img src="../../static/icon_bzzx_wzic@2x.png" alt="" class="help_bottom_one_img">
								<p class="help_bottom_one_tit1">{{value.tit1}}</p>
								<p class="help_bottom_one_tit2">{{value.tit2}}</p>
								<div class="help_bottom_one_auth">
									<img src="../../static/pic_wz_touxiang@2x.png" alt="">
									<img src="../../static/pic_wz_touxiang@2x.png" alt="">
									<p>{{ $t('help_detail.author') }}：Brittany {{ $t('help_list.and') }} 1 {{ $t('help_list.other') }} · 29{{ $t('help_list.articles') }}</p>
								</div>
							</div>
							<div class="help_bottom_one_sm d-block d-sm-none" v-for="(value,index) in list" :key="index + 100" @click="goList()">
								<img src="../../static/icon_bzzx_wzic@2x.png" alt="" class="help_bottom_one_img">
								<p class="help_bottom_one_tit1">{{value.tit1}}</p>
								<p class="help_bottom_one_tit2">{{value.tit2}}</p>
								<div class="help_bottom_one_auth">
									<img src="../../static/pic_wz_touxiang@2x.png" alt="">
									<img src="../../static/pic_wz_touxiang@2x.png" alt="">
									<p>{{ $t('help_detail.author') }}：Brittany {{ $t('help_list.and') }} 1 {{ $t('help_list.other') }} · 29{{ $t('help_list.articles') }}</p>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
	import HelpHeader from '@/components/helpHeader.vue';
	import MyFooter from '@/components/footer.vue';
	export default {
		name: 'MyIndexPage',
		components: {
			HelpHeader,
			MyFooter
		},
		data() {
			return {
				list: [{
						tit1: "MyEtherWallet" + this.$t('help_detail.introduction'),
						tit2: this.$t('help_detail.about_mew')
					},
					{
						tit1: "MEW " + this.$t('help_detail.wallet_app'),
						tit2: this.$t('help_detail.mobile_wallet_guide')
					},
					{
						tit1: this.$t('help_detail.encryption'),
						tit2: this.$t('help_detail.enkrypt_resources')
					},
					{
						tit1: this.$t('help_detail.security_privacy'),
						tit2: this.$t('help_detail.wallet_security')
					},
					{
						tit1: this.$t('help_detail.access_wallet'),
						tit2: this.$t('help_detail.access_methods')
					},
					{
						tit1: this.$t('help_detail.send_transaction'),
						tit2: this.$t('help_detail.send_transaction_guide')
					},
					{
						tit1: this.$t('help_detail.exchange'),
						tit2: this.$t('help_detail.exchange_guide')
					},
					{
						tit1: this.$t('help_detail.tokens'),
						tit2: this.$t('help_detail.token_interaction')
					},
					{
						tit1: this.$t('help_detail.decentralized_apps'),
						tit2: this.$t('help_detail.dapp_interaction')
					},
					{
						tit1: this.$t('help_detail.mew_offline'),
						tit2: this.$t('help_detail.offline_usage')
					},
					{
						tit1: this.$t('help_detail.networks_nodes'),
						tit2: this.$t('help_detail.connect_networks')
					}
				]
			}
		},
		created() {},
		methods: {
			goList(){
				this.$router.push({
					path: '/HelpList'
				})
			}
		},
	}
</script>
<style scoped lang="less">
	.help_top {
		background-color: #ffffff;
		padding-bottom: calc(15px + 1vw);
	}

	.help_tit {
		font-size: calc(28px + 1vw);
		color: #1A183F;
		font-weight: 600;
		text-align: left;
	}

	.help_tit_sm {
		font-size: 18px;
		color: #1A183F;
		font-weight: 600;
	}

	.help_search {
		width: 100%;
		display: flex;
		align-items: center;
		height: calc(50px + 1vw);
		background-color: #F3F5FB;
		border-radius: 10px;
		padding: 0 20px;
		margin-top: calc(15px + 1vw);

		img {
			width: calc(10px + 1vw);
		}
	}

	.help_search_sm {
		width: 90%;
		display: flex;
		align-items: center;
		height: 40px;
		background-color: #F3F5FB;
		border-radius: 10px;
		padding: 0 15px;
		margin: 15px auto 0;

		img {
			width: 15px;
		}
	}

	/* 去除 b-form-input 的默认样式 */
	.search_input {
		background-color: transparent;
		border: none;
		border-radius: 0;
		box-shadow: none;
		padding: 0;
		font-size: inherit;
		color: inherit;
		height: calc(50px + 1vw);
		margin-left: 12px;
		font-size: calc(12px + 0.7vw);
		;
		color: #1A183F;
		font-weight: 600;
	}

	.search_input:focus {
		outline: none;
		box-shadow: none;
		background: none;
	}

	/* 去除 b-form-input 的默认样式 */
	.search_input_sm {
		background-color: transparent;
		border: none;
		border-radius: 0;
		box-shadow: none;
		padding: 0;
		font-size: inherit;
		color: inherit;
		height: calc(50px + 1vw);
		margin-left: 12px;
	}

	.search_input_sm:focus {
		outline: none;
		box-shadow: none;
		background: none;
	}

	.help_bottom_all {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 0 18px;
		flex-wrap: wrap;

		.help_bottom_one {
			width: calc(50% - 7px);
			border: 1px solid #CDDDFF;
			background-color: #ffffff;
			padding: calc(10px + 1vw);
			border-radius: 8px;
			margin-top: 14px;
			cursor: pointer;
			.help_bottom_one_img {
				width: calc(20px + 1vw);
				display: block;
			}

			.help_bottom_one_tit1 {
				font-size: calc(14px + 1vw);
				color: #1A183F;
				font-weight: 600;
				text-align: left;
				margin-top: calc(10px + 1vw);
				overflow: hidden;
				/* 确保超出容器的文本被裁剪 */
				white-space: nowrap;
				/* 确保文本在一行内显示 */
				text-overflow: ellipsis;
				/* 超出部分显示为省略号 */
			}

			.help_bottom_one_tit2 {
				font-size: calc(12px + 0.5vw);
				color: #1A183F;
				text-align: left;
				overflow: hidden;
				/* 确保超出容器的文本被裁剪 */
				white-space: nowrap;
				/* 确保文本在一行内显示 */
				text-overflow: ellipsis;
				/* 超出部分显示为省略号 */
			}

			.help_bottom_one_auth {
				display: flex;
				align-items: center;
				margin-top: calc(20px + 1vw);

				img {
					width: 24px;
				}

				img:nth-child(1) {
					position: relative;
					z-index: 1;
				}

				img:nth-child(2) {
					margin-left: -12px;
				}

				p {
					font-size: calc(12px + 0.3vw);
					color: #757C8B;
					margin-left: calc(5px + 1vw);
					overflow: hidden;
					/* 确保超出容器的文本被裁剪 */
					white-space: nowrap;
					/* 确保文本在一行内显示 */
					text-overflow: ellipsis;
					/* 超出部分显示为省略号 */
				}
			}
		}
		.help_bottom_one_sm {
			width: 100%;
			border: 1px solid #CDDDFF;
			background-color: #ffffff;
			padding:15px;
			border-radius: 8px;
			margin: 14px auto 0;
			cursor: pointer;
			.help_bottom_one_img {
				width:16px;
				display: block;
			}
			
			.help_bottom_one_tit1 {
				font-size:16;
				color: #1A183F;
				font-weight: 600;
				text-align: left;
				margin-top: 10px;
				overflow: hidden;
				/* 确保超出容器的文本被裁剪 */
				white-space: nowrap;
				/* 确保文本在一行内显示 */
				text-overflow: ellipsis;
				/* 超出部分显示为省略号 */
			}
			
			.help_bottom_one_tit2 {
				font-size: 12px;
				color: #1A183F;
				text-align: left;
				overflow: hidden;
				/* 确保超出容器的文本被裁剪 */
				white-space: nowrap;
				/* 确保文本在一行内显示 */
				text-overflow: ellipsis;
				/* 超出部分显示为省略号 */
			}
			
			.help_bottom_one_auth {
				display: flex;
				align-items: center;
				margin-top: 15px;
			
				img {
					width: 24px;
				}
			
				img:nth-child(1) {
					position: relative;
					z-index: 1;
				}
			
				img:nth-child(2) {
					margin-left: -12px;
				}
			
				p {
					font-size: 12px;
					color: #757C8B;
					margin-left: 5px;
					overflow: hidden;
					/* 确保超出容器的文本被裁剪 */
					white-space: nowrap;
					/* 确保文本在一行内显示 */
					text-overflow: ellipsis;
					/* 超出部分显示为省略号 */
				}
			}
		}
	}
</style>