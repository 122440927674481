<template>
	<div style="position: relative;z-index: 1;margin: calc(30px + 1vw) 0;">
		<b-container class="bv-example-row">
			<b-row class="text-center" align-h="center">
				<b-col cols="12" sm="12" md="10" class="header_view">
					<b-row>
						<b-col sm="12" md="8">
							<b-row>
								<b-col sm="6">
									<p class="footer_p d-none d-md-block">{{ $t('components.about_us') }}</p>
									<p class="footer_p d-none d-md-block">{{ $t('components.advertise_with_us') }}</p>
									<p class="footer_p d-none d-md-block">{{ $t('components.privacy') }}</p>
									<p class="footer_p d-none d-md-block">{{ $t('components.terms') }}</p>
									<p class="footer_p_sm d-block d-sm-none">{{ $t('components.about_us') }}</p>
									<p class="footer_p_sm d-block d-sm-none">{{ $t('components.advertise_with_us') }}</p>
									<p class="footer_p_sm d-block d-sm-none">{{ $t('components.privacy') }}</p>
									<p class="footer_p_sm d-block d-sm-none">{{ $t('components.terms') }}</p>
								</b-col>
								<b-col sm="6">
									<p class="footer_p d-none d-md-block">{{ $t('components.help_center') }}</p>
									<p class="footer_p d-none d-md-block">{{ $t('components.frequently_asked_questions') }}</p>
									<p class="footer_p_sm d-block d-sm-none">{{ $t('components.help_center') }}</p>
									<p class="footer_p_sm d-block d-sm-none">{{ $t('components.frequently_asked_questions') }}</p>
								</b-col>
							</b-row>
						</b-col>
						<b-col sm="12" md="4">
							<div class="d-none d-md-block">
								<div class="footer_right">
									<p>{{ $t('components.join_community') }}</p>
									<div>
										<img src="../../static/icon_jrsq_one@2x.png" alt="">
										<img src="../../static/icon_jrsq_two@2x.png" alt="">
										<img src="../../static/icon_jrsq_three@2x.png" alt="">
										<img src="../../static/icon_jrsq_four@2x.png" alt="">
										<img src="../../static/icon_jrsq_five@2x.png" alt="">
										<img src="../../static/icon_jrsq_six@2x.png" alt="">
										<img src="../../static/icon_jrsq_seven@2x.png" alt="">
										<img src="../../static/icon_jrsq_eight@2x.png" alt="">
									</div>
								</div>
							</div>
							<div class="d-block d-sm-none">
								<div class="footer_right_sm">
									<p>{{ $t('components.join_community') }}</p>
									<div>
										<img src="../../static/icon_jrsq_one@2x.png" alt="">
										<img src="../../static/icon_jrsq_two@2x.png" alt="">
										<img src="../../static/icon_jrsq_three@2x.png" alt="">
										<img src="../../static/icon_jrsq_four@2x.png" alt="">
										<img src="../../static/icon_jrsq_five@2x.png" alt="">
										<img src="../../static/icon_jrsq_six@2x.png" alt="">
										<img src="../../static/icon_jrsq_seven@2x.png" alt="">
										<img src="../../static/icon_jrsq_eight@2x.png" alt="">
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'MyHeader',
		methods: {
			goUrl() {
				this.$router.push({
					path: '/test'
				})
			}
		},
	}
</script>
<style scoped lang="less">
	.footer_p {
		text-align: left;
		font-size: 16px;
		color: #1A183F;
		margin-top: 15px;
	}

	.footer_p_sm {
		font-size: 16px;
		color: #1A183F;
		margin-top: 15px;
	}
	.footer_right {
		p {
			text-align: left;
			font-size: 16px;
			color: #1A183F;
			margin-top: 15px;
			font-weight: 600;
		}
		div {
			display: flex;
			margin-top: 15px;
			flex-wrap: wrap;
			img {
				width: 48px;
				height: 48px;
				margin-right: 12px;;
				margin-bottom: 12px;
			}
		}
	}
	.footer_right_sm {
		p {
			text-align: center;
			font-size: 16px;
			color: #1A183F;
			margin-top: 15px;
			font-weight: 600;
		}
		div {
			display: flex;
			margin-top: 15px;
			flex-wrap: wrap;
			justify-content: center;
			img {
				width: 48px;
				height: 48px;
				margin-right: 12px;;
				margin-bottom: 12px;
			}
		}
	}
</style>