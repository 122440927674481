<template>
	<div>
		<transition name="slide-up">
			<div v-if="pageSet" class="popup">
				<!-- 密钥库  步骤一 -->
				<div class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('mps.settings') }}</p>
									<!-- <div class="set_one">
										<div class="set_one_top" v-b-toggle.youxianji>
											<p>交易优先级</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="youxianji">
											<p class="youxianji_tip">该费用由以太网络收取，并根据网络流量波动。MEW 不会从该费用中获利。</p>
											<div v-for="(value,index) in list" :key="index"
												:class="value.active?'youxianji_one_active youxianji_one':'youxianji_one'"
												@click="choseing(index)">
												<div class="youxianji_one_left">
													<img :src="value.img" alt="">
													<p>{{value.name}}</p>
												</div>
												<div class="youxianji_one_right">
													<img src="../../static/icon_sz_sz@2x.png" alt="">
													<p>{{value.times}}</p>
												</div>
											</div>
										</b-collapse>
									</div> -->
									<!-- <div class="set_one">
										<div class="set_one_top" v-b-toggle.daoru>
											<p>导入配置</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="daoru">
											<p class="youxianji_tip">请上传文件并单击按钮从本地计算机打开并导入配置文件。</p>
											<div style="display: flex;justify-content: space-between;">
												<div class="youxianji_one">
													<div class="youxianji_one_left">
														<img src="../../static/icon_shangchuanwenjian@2x.png" alt="">
														<p>上传文件…</p>
													</div>
												</div>
												<div class="set_content_buttons" style="margin: 0;">
													<div class="popup_content_buttons_right defaul"
														style="height: calc(40px + 1vw);margin-top: calc(10px + 0.4vw);">
														确认并导入</div>
												</div>
											</div>
										</b-collapse>
									</div>
									<div class="set_one">
										<div class="set_one_top" v-b-toggle.daochu>
											<p>导出配置</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="daochu">
											<p class="youxianji_tip">导出您的配置文件将其保存在本地计算机上以供日后使用。</p>
											<div class="set_content_buttons">
												<div class="popup_content_buttons_right">出口</div>
											</div>
										</b-collapse>
									</div> -->
									<div class="set_one">
										<div class="set_one_top" v-b-toggle.addrss>
											<p>{{ $t('mps.wallet_address') }}</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="addrss">
											<p class="youxianji_tip">{{ $t('mps.max_wallets') }}</p>
											<div class="youxianji_one" v-for="(value,index) in addressList"
												:key="index">
												<div class="youxianji_one_left">
													<p style="font-size: calc(8px + 0.4vw);margin-left: 0;">
														{{value}}
													</p>
												</div>
												<div class="youxianji_one_right" @click="delAddress(index)">
													<img src="../../static/del.png" alt="">
												</div>
											</div>
											<div class="youxianji_one">
												<div class="youxianji_one_left" style="flex: 1;">
													<input type="text"
														style="font-size: calc(8px + 0.4vw);margin-left: 0;border:none;outline: none;width: 100%;background: none;"
														:placeholder="$t('mps.enter_wallet_address')" v-model="addressone" />
												</div>
												<!-- 												<div class="youxianji_one_right">
													<img src="../../static/del.png" alt="">
												</div> -->
											</div>
											<div class="set_content_buttons">
												<!-- <div class="popup_content_buttons_left">确认添加</div> -->
												<div class="popup_content_buttons_right" @click="sureAdd()">{{ $t('mps.confirm_add') }}</div>
											</div>
										</b-collapse>
									</div>
									<div class="set_one">
										<div class="set_one_top" v-b-toggle.huobi>
											<p>{{ $t('mps.currency_setting') }}</p>
											<img src="../../static/down.png" alt="">
										</div>
										<b-collapse id="huobi">
											<b-form-select v-model="selected" class="set_huobi">
												<b-form-select-option value="a">{{ $t('mps.usd') }}</b-form-select-option>
												<b-form-select-option value="b">{{ $t('mps.jpy') }}</b-form-select-option>
											</b-form-select>
										</b-collapse>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'MyImport',
		props: {
			pageSet: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		data() {
			return {
				list: [{
						name: this.$t('mps.normal_priority'),
						active: true,
						img: require('../../static/right.png'),
						times: this.$t('mps.fifteen_minutes')
					},
					{
						name: this.$t('mps.higher_priority'),
						active: false,
						img: require('../../static/icon_ggyxj@2x.png'),
						times: this.$t('mps.fifteen_minutes')
					},
					{
						name: this.$t('mps.highest_priority'),
						active: false,
						img: require('../../static/icon_zgyxj@2x.png'),
						times: this.$t('mps.fifteen_minutes')
					}
				],
				selected: 'a',
				addressList: [],
				addressone: '',
			}
		},
		watch: {
			pageSet(newVal) {
				if (newVal) {
					if (localStorage.getItem("addressList") && localStorage.getItem("addressList") != null) {
						this.addressList = JSON.parse(localStorage.getItem("addressList"));
					}
				}
			},
		},
		created() {},
		methods: {
			togglePopup() {
				this.$emit('updatePageSet', !this.pageSet);
			},
			choseing(index) {
				for (var i = 0; i < this.list.length; i++) {
					this.list[i].active = false;
				}
				this.list[index].active = true;
			},
			sureAdd() {
				if (this.addressone == '') {
					this.$bvToast.toast(this.$t('mps.enter_wallet_address'), {
						title: this.$t('tip'),
						variant: "success",
						solid: true,
					});
					return;
				}
				if(this.addressList.indexOf(this.addressone) != -1){
					this.$bvToast.toast(this.$t('mps.wallet_address_exists'), {
						title: this.$t('tip'),
						variant: "success",
						solid: true,
					});
					return;
				}
				this.addressList.push(this.addressone);
				localStorage.setItem("addressList", JSON.stringify(this.addressList));
				this.addressone = '';
			},
			delAddress(index){
				this.addressList.splice(index,1);
				localStorage.setItem("addressList", JSON.stringify(this.addressList));
			}
		},
	}
</script>
<style scoped lang="less">
	/* 弹出框的样式 */
	.popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		width: 100%;
		height: 100%;
		background-color: #F3F5FB;
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.popup_common {
			width: 100%;
			height: 1005;
		}
	}

	/* 过渡效果 */
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	}

	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(100%);
		opacity: 0;
	}

	.popup_close {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		right: 30px;
	}

	.popup_content {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);

		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}
	}

	.set_one {
		width: 100%;
		border: 1px solid #E3E5ED;
		border-radius: 10px;
		margin-top: 15px;
		padding: calc(5px + 1vw);

		.set_one_top {
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				font-size: calc(10px + 0.4vw);
				color: #1A183F;
			}

			img {
				width: calc(5px + 0.6vw);
			}
		}
	}

	.youxianji_tip {
		font-size: calc(10px + 0.4vw);
		color: #1A183F;
		text-align: left;
		margin-top: calc(10px + 0.4vw);
	}

	.youxianji_one {
		width: 100%;
		height: calc(40px + 1vw);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 calc(10px + 0.4vw);
		border: 1px solid #E3E5ED;
		background-color: #E3E5ED;
		border-radius: 10px;
		margin-top: calc(10px + 0.4vw);
		cursor: pointer;

		.youxianji_one_left {
			display: flex;
			align-items: center;

			img {
				width: calc(5px + 1vw);
			}

			p {
				font-size: calc(10px + 0.4vw);
				color: #1A183F;
				margin-left: 5px;
			}
		}

		.youxianji_one_right {
			display: flex;
			align-items: center;

			img {
				width: calc(3px + 1vw);
			}

			p {
				font-size: calc(8px + 0.4vw);
				color: #0091FE;
				margin-left: 5px;
			}
		}
	}

	.youxianji_one_active {
		border: 1px solid #0091FE;
	}

	.set_content_buttons {
		display: flex;
		justify-content: center;
		margin: calc(15px + 1vw) 0 0 0;

		.popup_content_buttons_left {
			width: calc(120px + 1vw);
			padding: calc(5px + 0.4vw) 0;
			border: 1px solid #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #0091FE;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.popup_content_buttons_right {
			width: calc(120px + 1vw);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: calc(5px + 0.4vw) 0;
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #FFFFFF;
			margin-left: calc(12px + 0.2vw);
			cursor: pointer;
		}

		.defaul {
			background-color: #E3E5ED;
		}
	}

	.set_huobi {
		width: 100%;
		height: calc(40px + 1vw);
		display: flex;
		align-items: center;
		padding: 0 calc(10px + 0.4vw);
		border: 1px solid #E3E5ED;
		background-color: #E3E5ED;
		border-radius: 10px;
		margin-top: calc(10px + 0.4vw);
		cursor: pointer;
		border: none;
		outline: none;
	}
</style>