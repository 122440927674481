import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	// state: 存放变量
	state: {
		web3: null
	},
	// mutation: 存放方法
	mutations: {
		setWeb(state,web3) {
			state.web3 = null;
			state.web3 = web3;
		}
	}
})