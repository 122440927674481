<template>
	<div>
		<div class="index_one">
			<my-header @findPacage="findMyPacage"></my-header>
			<img src="../../static/pic_sy_banner.png" class="img-fluid index_one_bgimg" alt="Responsive Image">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<!-- 仅在超小屏幕上显示 -->
						<div class="index_one_mid index_one_mid_sm d-block d-sm-none">
							<div class="index_one_mid_top">{{ $t('ethPackage') }}</div>
							<div class="index_one_mid_mid">{{ $t('index.most_reputable_wallet') }}</div>
							<div class="index_one_mid_bot">
								<div class="index_one_mid_bot_button" @click="CreateMyPacage()">{{ $t('index.create_new_wallet') }}</div>
								<p class="index_one_mid_bot_or">{{ $t('index.or') }}</p>
								<p class="index_one_mid_bot_right" @click="findMyPacage()">{{ $t('index.access_my_wallet') }}</p>
							</div>
						</div>
						<!-- 仅在中等及以上屏幕上显示 -->
						<div class="index_one_mid d-none d-md-block">
							<div class="index_one_mid_top">{{ $t('ethPackage') }}</div>
							<div class="index_one_mid_mid">{{ $t('index.most_reputable_wallet') }}</div>
							<div class="index_one_mid_bot">
								<div class="index_one_mid_bot_button" @click="CreateMyPacage()">{{ $t('index.create_new_wallet') }}</div>
								<p class="index_one_mid_bot_or">{{ $t('index.or') }}</p>
								<p class="index_one_mid_bot_right" @click="findMyPacage()">{{ $t('index.access_my_wallet') }}</p>
							</div>
							<img src="../../static/pic_sy_banner_kpsf_icon@2x.png" alt=""
								class="index_one_mid_rightimg">
							<img src="../../static/pic_sy_banner_zuo@2x.png" alt="" class="index_one_mid_leftimg">
							<img src="../../static/pic_sy_banner_you@2x.png" alt="" class="index_one_mid_rightimg2">
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_two">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<p class="index_two_title d-none d-md-block">{{ $t('index.our_products_make_crypto_easier') }}</p>
						<p class="index_two_title2 d-none d-md-block">{{ $t('index.available_on_mobile_and_desktop') }}</p>
						<p class="index_two_title-sm d-block d-sm-none">{{ $t('index.our_products_make_crypto_easier') }}</p>
						<p class="index_two_title2-sm d-block d-sm-none">{{ $t('index.available_on_mobile_and_desktop') }}</p>
						<b-row style="margin:0;">
							<b-col sm="12" md="6" style="padding: 0;">
								<div class="index_two_left d-none d-md-block">
									<div class="index_two_left_top">
										<img src="../../static/btn_sy_wmdcprjmgjd_one_xz@2x.png" alt="" />
										<img src="../../static/btn_sy_wmdcprjmgjd_two_wxz@2x.png" alt="" />
										<img src="../../static/btn_sy_wmdcprjmgjd_three_wxz@2x.png" alt="" />
									</div>
									<div class="index_two_left_mid">
										<img src="../../static/pic_sy_wmdcprjmgjd_btzs@2x.png" alt="">
										<p>{{ $t('index.mew_mobile_app') }}</p>
									</div>
									<p class="index_two_left_tit">{{ $t('index.stake_swap_manage_crypto') }}</p>
									<p class="index_two_left_tit2">{{ $t('index.security_like_hardware_wallet') }}</p>
									<p class="index_two_left_tit2">{{ $t('index.powerful_nft_management') }}</p>
									<img src="../../static/pic_sy_wmdcprjmgjd_ct@2x.png" alt=""
										class="index_two_left_bot">
								</div>
								<div class="index_two_left_sm d-block d-sm-none">
									<div class="index_two_left_top">
										<img src="../../static/btn_sy_wmdcprjmgjd_one_xz@2x.png" alt="" />
										<img src="../../static/btn_sy_wmdcprjmgjd_two_wxz@2x.png" alt="" />
										<img src="../../static/btn_sy_wmdcprjmgjd_three_wxz@2x.png" alt="" />
									</div>
									<div class="index_two_left_mid">
										<img src="../../static/pic_sy_wmdcprjmgjd_btzs@2x.png" alt="">
										<p>{{ $t('index.mew_mobile_app') }}</p>
									</div>
									<p class="index_two_left_tit">{{ $t('index.stake_swap_manage_crypto') }}</p>
									<p class="index_two_left_tit2">{{ $t('index.security_like_hardware_wallet') }}</p>
									<p class="index_two_left_tit2">{{ $t('index.powerful_nft_management') }}</p>
									<img src="../../static/pic_sy_wmdcprjmgjd_ct@2x.png" alt=""
										class="index_two_left_bot">
								</div>
							</b-col>
							<b-col sm="12" md="6" style="padding: 0;">
								<img src="../../static/pic_sy_wmdcprjmgjd_sjyj@2x.png" alt=""
									class="index_two_right d-none d-md-block">
								<img src="../../static/pic_sy_wmdcprjmgjd_sjyj@2x.png" alt=""
									class="index_two_right_sm d-block d-sm-none">
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_three">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<p class="index_three_title d-none d-md-block">{{ $t('index.want_to_enter_crypto') }} </p>
								<p class="index_three_title2 d-none d-md-block">{{ $t('index.mew_is_best_choice') }}</p>
								<p class="index_three_title_sm d-block d-sm-none">{{ $t('index.want_to_enter_crypto') }} </p>
								<p class="index_three_title2_sm d-block d-sm-none">{{ $t('index.mew_is_best_choice') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="9" style="padding: 0;">
								<div class="index_three_mid_one d-none d-md-block">
									<div>
										<div></div>
										<p>{{ $t('index.ethereum_ecosystem') }}</p>
									</div>
								</div>
								<div class="index_three_mid_one d-none d-md-block">
									<div>
										<div></div>
										<p>{{ $t('index.trusted_ethereum_wallet') }}</p>
									</div>
								</div>
								<div class="index_three_mid_one_sm d-block d-sm-none">
									<div>
										<div></div>
										<p>{{ $t('index.ethereum_ecosystem') }}</p>
									</div>
								</div>
								<div class="index_three_mid_one_sm d-block d-sm-none">
									<div>
										<div></div>
										<p>{{ $t('index.trusted_ethereum_wallet') }}</p>
									</div>
								</div>
							</b-col>
							<b-col sm="12" md="3" style="padding: 0;">
								<div class="index_three_mid_two d-none d-md-block">
									<div class="index_three_mid_two_top">
										<img src="../../static/icon_xyjrjmbly_yitaifang@2x.png" alt="">
										<p>{{ $t('package.ethereum') }} 3428.20 {{ $t('index.usd') }}</p>
									</div>
									<p class="index_three_mid_two_bot">{{ $t('index.not_investment_advice') }}</p>
								</div>
								<div class="index_three_mid_two_sm d-block d-sm-none">
									<div class="index_three_mid_two_top">
										<img src="../../static/icon_xyjrjmbly_yitaifang@2x.png" alt="">
										<p>{{ $t('package.ethereum') }} 3428.20 {{ $t('index.usd') }}</p>
									</div>
									<p class="index_three_mid_two_bot">{{ $t('index.not_investment_advice') }}</p>
								</div>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="4" style="padding: 0;">
								<p class="index_three_title index_three_addmar d-none d-md-block">{{ $t('index.truly_own_your_crypto') }} </p>
								<p class="index_three_title_sm d-block d-sm-none" style="margin-top:5px;">{{ $t('index.truly_own_your_crypto') }}
								</p>
							</b-col>
							<b-col sm="12" md="8" style="padding: 0;">
								<img src="../../static/pic_syjrjmbly_ct@2x.png" alt=""
									class="index_three_mid_img d-none d-md-block">
								<img src="../../static/pic_syjrjmbly_ct@2x.png" alt=""
									class="index_three_mid_img_sm d-block d-sm-none">
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="8" style="padding: 0;">
								<div class="index_three_mid_one d-none d-md-block"
									style="text-align: left;margin-top: 20px;">
									<p>{{ $t('index.self_custody_wallet') }}</p>
								</div>
								<div class="index_three_mid_one_sm d-block d-sm-none">
									<p>{{ $t('index.self_custody_wallet') }}</p>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_fore">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<p class="index_fore_title d-none d-md-block">{{ $t('package.buy_store_send') }}</p>
								<p class="index_fore_title2 d-none d-md-block">{{ $t('package.swap_tokens') }}</p>
								<p class="index_fore_title_sm d-block d-sm-none">{{ $t('package.buy_store_send') }} </p>
								<p class="index_fore_title2_sm d-block d-sm-none">{{ $t('package.swap_tokens') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<div class="d-none d-md-block">
									<div class="index_fore_tab">
										<div class="index_fore_tab_one active">{{ $t('index.store_tokens') }}</div>
										<div class="index_fore_tab_one nomal">{{ $t('index.buy_sell') }}</div>
										<div class="index_fore_tab_one nomal">{{ $t('index.swap') }}</div>
									</div>
								</div>
								<div class="d-block d-sm-none">
									<div class="index_fore_tab_sm">
										<div class="index_fore_tab_one active">{{ $t('index.store_tokens') }}</div>
										<div class="index_fore_tab_one nomal">{{ $t('index.buy_sell') }}</div>
										<div class="index_fore_tab_one nomal">{{ $t('index.swap') }}</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<p class="index_fore_tip">{{ $t('package.mew_supports_eth_and_erc20') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<img src="../../static/pic_gmccfsjhdb@2x.png" alt=""
									class="index_fore_img d-none d-md-block">
								<img src="../../static/pic_gmccfsjhdb@2x.png" alt=""
									class="index_fore_img_sm d-block d-sm-none">
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_five">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<p class="index_three_title d-none d-md-block">{{ $t('package.mew_supports_eth_and_erc20') }} </p>
								<p class="index_three_title2 d-none d-md-block">{{ $t('package.tokens') }}</p>
								<p class="index_three_title_sm d-block d-sm-none">{{ $t('package.mew_supports_eth_and_erc20') }} </p>
								<p class="index_three_title2_sm d-block d-sm-none">{{ $t('package.tokens') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<div class="index_five_one" v-for="(value,index) in coinList" :key="index">
									<img :src='value.icon' alt="">
									<p>{{value.name}}</p>
								</div>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<div class="d-none d-md-block">
									<div class="index_five_button">
										<p>{{ $t('index.get_tokens') }}</p>
										<img src='../../static/icon_mewzceth_gengduo@2x.png' alt="">
									</div>
								</div>
								<div class="d-block d-sm-none">
									<div class="index_five_button_sm">
										<p>{{ $t('index.get_tokens') }}</p>
										<img src='../../static/icon_mewzceth_gengduo@2x.png' alt="">
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_three">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<p class="index_three_title d-none d-md-block">{{ $t('package.make_your_crypto_yours') }}</p>
								<p class="index_three_title2 d-none d-md-block">{{ $t('package.cryptocurrency') }}</p>
								<p class="index_three_title_sm d-block d-sm-none">{{ $t('package.make_your_crypto_yours') }}</p>
								<p class="index_three_title2_sm d-block d-sm-none">{{ $t('package.cryptocurrency') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="8" style="padding: 0;">
								<div class="index_three_mid_one d-none d-md-block"
									style="text-align: left;margin-top: 20px;">
									<p>{{ $t('index.stake_your_eth') }}</p>
								</div>
								<div class="index_three_mid_one_sm d-block d-sm-none">
									<p>{{ $t('index.stake_your_eth') }}</p>
								</div>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/pic_jmhb_one@2x.png" alt="">
									<p class="index_six_one_p1">{{ $t('index.be_your_own_bank') }}</p>
									<p class="index_six_one_p2">{{ $t('index.crypto_as_banking') }}
									</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/pic_jmhb_one@2x.png" alt="">
									<p class="index_six_one_p1">{{ $t('index.be_your_own_bank') }}</p>
									<p class="index_six_one_p2">{{ $t('index.crypto_as_banking') }}
									</p>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<p class="index_six_one_p1" style="margin-top: 0;">{{ $t('index.staking_made_easy') }}</p>
									<img src="../../static/pic_jmhb_two@2x.png" alt="" class="index_six_one_img">
									<p class="index_six_one_p2">{{ $t('index.easy_staking_with_mew') }}</p>
									<div class="index_six_one_button">{{ $t('index.start_staking_now') }}</div>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<p class="index_six_one_p1" style="margin-top: 0;">{{ $t('index.staking_made_easy') }}</p>
									<img src="../../static/pic_jmhb_two@2x.png" alt="" class="index_six_one_img">
									<p class="index_six_one_p2">{{ $t('index.easy_staking_with_mew') }}</p>
									<div class="index_six_one_button">{{ $t('index.start_staking_now') }}</div>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/pic_jmhb_three@2x.png" alt="" class="index_six_one_img2">
									<p class="index_six_one_p1" style="margin-bottom: 0;">32 ETH {{ $t('index.staking') }}</p>
									<p class="index_six_one_p3">{{ $t('index.annual_rate_up_to') }} 4.0 %</p>
									<p class="index_six_one_p1" style="margin-bottom: 0;">{{ $t('index.no_minimum_liquidity') }}</p>
									<p class="index_six_one_p3">{{ $t('index.annual_rate_up_to') }} 3.6 %</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/pic_jmhb_three@2x.png" alt="" class="index_six_one_img2">
									<p class="index_six_one_p1" style="margin-bottom: 0;">32 ETH {{ $t('index.staking') }}</p>
									<p class="index_six_one_p3">{{ $t('index.annual_rate_up_to') }} 4.0 %</p>
									<p class="index_six_one_p1" style="margin-bottom: 0;">{{ $t('index.no_minimum_liquidity') }}</p>
									<p class="index_six_one_p3">{{ $t('index.annual_rate_up_to') }} 3.6 %</p>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_three">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<p class="index_three_title d-none d-md-block">{{ $t('index.powerful_wallet') }} </p>
								<p class="index_three_title2 d-none d-md-block">{{ $t('package.meet_all_your_crypto_needs') }}</p>
								<p class="index_three_title_sm d-block d-sm-none">{{ $t('index.powerful_wallet') }} </p>
								<p class="index_three_title2_sm d-block d-sm-none">{{ $t('package.meet_all_your_crypto_needs') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_seven_one index_seven_one_active d-none d-md-block">{{ $t('index.store_all_your_nfts') }} </div>
								<div class="index_seven_one_sm index_seven_one_active d-block d-sm-none">{{ $t('index.store_all_your_nfts') }}</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_seven_one d-none d-md-block">{{ $t('index.access_web3_apps') }}</div>
								<div class="index_seven_one_sm d-block d-sm-none">{{ $t('index.access_web3_apps') }}</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_seven_one d-none d-md-block">{{ $t('index.multi_chain_access') }}</div>
								<div class="index_seven_one_sm d-block d-sm-none">{{ $t('index.multi_chain_access') }}</div>
							</b-col>
						</b-row>
						<div style="width: 100%;height: 15px;" class="d-none d-md-block"></div>
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<img src="../../static/pic_ykgnqddqb@2x.png" alt=""
									class="index_fore_img d-none d-md-block">
								<img src="../../static/pic_ykgnqddqb@2x.png" alt=""
									class="index_fore_img_sm d-block d-sm-none">
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_three">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" md="8" style="padding: 0;">
								<p class="index_three_title d-none d-md-block">{{ $t('send.line1') }} </p>
								<p class="index_three_title2 d-none d-md-block">{{ $t('send.line2') }}</p>
								<p class="index_three_title_sm d-block d-sm-none">{{ $t('send.line1') }} </p>
								<p class="index_three_title2_sm d-block d-sm-none">{{ $t('send.line2') }}</p>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<img src="../../static/icon_wsmsmew_you_icon@2x.png" class="d-none d-md-block" alt=""
									style="width: 65%;margin-top: calc(20px + 0.5vw);">
								<img src="../../static/icon_wsmsmew_you_icon@2x.png" class="d-block d-sm-none" alt=""
									style="width: 65%;margin:15px auto 0;">
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/icon_wsmsmew_one@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.crypto_expert_since_2015') }}</p>
									<p class="index_six_one_p2">{{ $t('index.first_trusted_wallet') }}</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/icon_wsmsmew_one@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.crypto_expert_since_2015') }}</p>
									<p class="index_six_one_p2">{{ $t('index.first_trusted_wallet') }}</p>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/icon_wsmsmew_two@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.private') }}</p>
									<p class="index_six_one_p2">{{ $t('index.no_tracking') }}</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/icon_wsmsmew_two@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.private') }}</p>
									<p class="index_six_one_p2">{{ $t('index.no_tracking') }}</p>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/icon_wsmsmew_three@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.hardware_wallet_support') }}</p>
									<p class="index_six_one_p2">{{ $t('index.support_major_hardware_wallets') }}</p>
									<div class="index_six_one_button2">{{ $t('package.buy_hardware_wallet') }}</div>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/icon_wsmsmew_three@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.hardware_wallet_support') }}</p>
									<p class="index_six_one_p2">{{ $t('index.support_major_hardware_wallets') }}</p>
									<div>{{ $t('package.buy_hardware_wallet') }}</div>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/icon_wsmsmew_four@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.secure_transparent') }}</p>
									<p class="index_six_one_p2">{{ $t('index.independent_audits') }}</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/icon_wsmsmew_four@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.secure_transparent') }}</p>
									<p class="index_six_one_p2">{{ $t('index.independent_audits') }}</p>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/icon_wsmsmew_five@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.self_custody') }}</p>
									<p class="index_six_one_p2">{{ $t('index.you_control_your_assets') }}</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/icon_wsmsmew_five@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.self_custody') }}</p>
									<p class="index_six_one_p2">{{ $t('index.you_control_your_assets') }}</p>
								</div>
							</b-col>
							<b-col sm="12" md="4" style="padding: 0;">
								<div class="index_six_one d-none d-md-block">
									<img src="../../static/icon_wsmsmew_six@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.easy_to_use') }}</p>
									<p class="index_six_one_p2">{{ $t('index.start_in_minutes') }}</p>
								</div>
								<div class="index_six_one_sm d-block d-sm-none">
									<img src="../../static/icon_wsmsmew_six@2x.png" alt="" class="index_six_one_img3">
									<p class="index_six_one_p1">{{ $t('index.easy_to_use') }}</p>
									<p class="index_six_one_p2">{{ $t('index.start_in_minutes') }}</p>
								</div>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="12" style="padding: 0;">
								<div class="d-none d-md-block">
									<div class="index_eight_all">
										<img src="../../static/pic_hz_one@2x.png" alt="">
										<img src="../../static/pic_hz_two@2x.png" alt="">
										<img src="../../static/pic_hz_three@2x.png" alt="">
										<img src="../../static/pic_hz_four@2x.png" alt="">
										<img src="../../static/pic_hz_five@2x.png" alt="">
										<img src="../../static/pic_hz_six@2x.png" alt="">
										<img src="../../static/pic_hz_seven@2x.png" alt="">
										<img src="../../static/pic_hz_eight@2x.png" alt="">
										<img src="../../static/pic_hz_nine@2x.png" alt="">
									</div>
								</div>
								<div class="d-block d-sm-none">
									<div class="index_eight_all_sm">
										<img src="../../static/pic_hz_one@2x.png" alt="">
										<img src="../../static/pic_hz_two@2x.png" alt="">
										<img src="../../static/pic_hz_three@2x.png" alt="">
										<img src="../../static/pic_hz_four@2x.png" alt="">
										<img src="../../static/pic_hz_five@2x.png" alt="">
										<img src="../../static/pic_hz_six@2x.png" alt="">
										<img src="../../static/pic_hz_seven@2x.png" alt="">
										<img src="../../static/pic_hz_eight@2x.png" alt="">
										<img src="../../static/pic_hz_nine@2x.png" alt="">
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_three">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" md="8" style="padding: 0;">
								<p class="index_three_title d-none d-md-block">{{ $t('index.arm_yourself_with_knowledge') }}</p>
								<p class="index_three_title_sm d-block d-sm-none">{{ $t('index.arm_yourself_with_knowledge') }}</p>
							</b-col>
						</b-row>
						<b-row style="margin:0;">
							<b-col sm="12" md="3" style="padding: 0;">
								<div class="index_nine_one d-none d-md-block" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.new_to_crypto') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.free_resources') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_lan@2x.png"
										alt="">
								</div>
								<div class="index_nine_one_sm d-block d-sm-none" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.new_to_crypto') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.free_resources') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_lan@2x.png"
										alt="">
								</div>
							</b-col>
							<b-col sm="12" md="3" style="padding: 0;">
								<div class="index_nine_one index_nine_two d-none d-md-block" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.help_center') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.free_resources') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_hei@2x.png"
										alt="">
								</div>
								<div class="index_nine_one_sm index_nine_two d-block d-sm-none" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.help_center') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.free_resources') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_hei@2x.png"
										alt="">
								</div>
							</b-col>
							<b-col sm="12" md="3" style="padding: 0;">
								<div class="index_nine_one d-none d-md-block" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.faq') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.common_crypto_questions') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_lan@2x.png"
										alt="">
								</div>
								<div class="index_nine_one_sm d-block d-sm-none" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.faq') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.common_crypto_questions') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_lan@2x.png"
										alt="">
								</div>
							</b-col>
							<b-col sm="12" md="3" style="padding: 0;">
								<div class="index_nine_one index_nine_two d-none d-md-block" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.chat_with_support') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.get_human_help') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_hei@2x.png"
										alt="">
								</div>
								<div class="index_nine_one_sm index_nine_two d-block d-sm-none" @click="goUrl('/Help')">
									<p class="index_nine_one_p1">{{ $t('index.chat_with_support') }}</p>
									<p class="index_nine_one_p2">{{ $t('index.get_human_help') }}</p>
									<img class="index_nine_one_img" src="../../static/icon_yzswzzj_xq_hei@2x.png"
										alt="">
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="index_three">
			<b-container class="bv-example-row mt-4">
				<b-row class="text-center" align-h="center">
					<b-col sm="12" md="10" class="header_view" style="padding: 0;">
						<b-row style="margin:0;">
							<b-col sm="12" style="padding: 0;">
								<img src="../../static/pic_qb_db@2x.png" alt="" class="index_ten_img d-none d-md-block">
								<img src="../../static/pic_qb_db@2x.png" alt=""
									class="index_ten_img_sm d-block d-sm-none">
							</b-col>
							<b-col sm="12" style="padding: 0;">
								<div class="index_ten_button" @click="CreateMyPacage()">{{ $t('index.create_new_wallet') }}</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<my-footer></my-footer>
		<!-- 访问我的钱包弹窗 -->
		<my-import :showPage="showPage" @updateShowPage="updateShowPage"></my-import>
	</div>
</template>

<script>
	import MyHeader from '@/components/header.vue';
	import MyFooter from '@/components/footer.vue';
	import MyImport from '@/components/import.vue';
	export default {
		name: 'MyIndexPage',
		components: {
			MyHeader,
			MyFooter,
			MyImport
		},
		data() {
			return {
				buttonName: 'Primary Button',
				showPage: false,
				coinList: [{
						icon: require("../../static/icon_mewzceth_ytf@2x.png"),
						name: this.$t('index.ethereum_eth')
					},
					{
						icon: require("../../static/icon_mewzceth_wd@2x.png"),
						name: this.$t('index.ondo')
					},
					{
						icon: require("../../static/icon_mewzceth_ljjqr@2x.png"),
						name: this.$t('index.unibot')
					},
					{
						icon: require("../../static/icon_mewzceth_nuco@2x.png"),
						name: 'Nuco.clould(NCDT)'
					},
					{
						icon: require("../../static/icon_mewzceth_dece@2x.png"),
						name: 'Decentraland(MANA)'
					},
					{
						icon: require("../../static/icon_mewzceth_bamy@2x.png"),
						name: this.$t('index.busd')
					},
					{
						icon: require("../../static/icon_mewzceth_game@2x.png"),
						name: 'GameSwift(GSWIFT)'
					},
					{
						icon: require("../../static/icon_mewzceth_tb@2x.png"),
						name: this.$t('index.icx')
					},
					{
						icon: require("../../static/icon_mewzceth_tb@2x (1).png"),
						name: 'Nuco.clould(NCDT)'
					},
					{
						icon: require("../../static/icon_mewzceth_jg@2x.png"),
						name: this.$t('index.grt')
					},
					{
						icon: require("../../static/icon_mewzceth_okb@2x.png"),
						name: this.$t('index.aurora')
					},
					{
						icon: require("../../static/icon_mewzceth_okb@2x.png"),
						name: 'OKB(OKB)'
					},
					{
						icon: require("../../static/icon_mewzceth_dai@2x.png"),
						name: 'DAI(DAI)'
					},
					{
						icon: require("../../static/icon_mewzceth_gomining@2x.png"),
						name: 'GoMining(GOMINING)'
					},
					{
						icon: require("../../static/icon_mewzceth_vertex@2x.png"),
						name: 'Vertex(VRTX)'
					},
					{
						icon: require("../../static/icon_mewzceth_genwangluo@2x.png"),
						name: this.$t('index.root')
					},
					{
						icon: require("../../static/icon_mewzceth_ime@2x.png"),
						name: this.$t('index.lime')
					},
				]
			}
		},
		created() {
			
		},
		methods: {
			goUrl(url) {
				this.$router.push({
					path: url
				})
			},
			CreateMyPacage(){
				this.$router.push({
					path: '/Create'
				})
			},
			// 访问我的钱包
			findMyPacage() {
				this.showPage = true;
			},
			updateShowPage(newValue) {
				this.showPage = newValue;
			}
		},
	}
</script>
<style scoped lang="less">
	.index_one {
		position: relative;
		width: 100%;

		.index_one_bgimg {
			position: absolute;
			top: 0;
			left: 0;
			height: 120%;
			width: 100%;
		}

		.index_one_mid {
			background: linear-gradient(133deg, #FFFFFF 56%, #BCE2FF 100%);
			border-radius: 24px;
			padding: calc(20px + 1vw);
			position: relative;

			.index_one_mid_rightimg {
				width: 30%;
				height: auto;
				position: absolute;
				right: -5%;
				bottom: -12%;
			}

			.index_one_mid_rightimg2 {
				width: 16%;
				height: auto;
				position: absolute;
				right: -13%;
				top: -12px;
			}

			.index_one_mid_leftimg {
				width: 13%;
				height: auto;
				position: absolute;
				left: -11%;
				top: 5%;
			}

			.index_one_mid_top {
				font-size: calc(12px + 1vw);
				color: #0091FE;
				font-weight: 600;
				text-align: left;
			}

			.index_one_mid_mid {
				font-size: calc(34px + 1vw);
				color: #1A183F;
				font-weight: 600;
				text-align: left;
				margin: 30px 0 44px 0;
			}

			.index_one_mid_bot {
				display: flex;
				align-items: center;

				.index_one_mid_bot_button {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					background-color: #0091FE;
					color: #ffffff;
					border-radius: 8px;
					font-size: calc(12px + 0.7vw);
					padding: 8px 20px;
					letter-spacing: 1px;
					cursor: pointer;
				}

				.index_one_mid_bot_or {
					font-size: calc(12px + 0.7vw);
					color: #1A183F;
					margin: 0 12px;
				}

				.index_one_mid_bot_right {
					font-size: calc(12px + 0.7vw);
					color: #1A183F;
					text-decoration-line: underline;
					margin: 0;
					cursor: pointer;
				}
			}
		}

		.index_one_mid_sm {
			padding: 30px 15px;
			background: none;
			margin-top: -30px;

			.index_one_mid_top {
				font-size: 14px;
				text-align: center;
			}

			.index_one_mid_mid {
				font-size: 18px;
				margin: 15px 0;
				text-align: center;
			}

			.index_one_mid_bot {
				display: flex;
				align-items: center;
				justify-content: center;

				.index_one_mid_bot_button {
					font-size: 14px;
				}

				.index_one_mid_bot_or {
					font-size: 14px;
				}

				.index_one_mid_bot_right {
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.index_two_title {
		font-size: calc(34px + 1vw);
		color: #1A183F;
		font-weight: 600;
		position: relative;
		z-index: 1;
		margin-top: calc(100px + 1vw);
	}

	.index_two_title2 {
		font-size: calc(12px + 0.8vw);
		color: #757C8B;
		position: relative;
		z-index: 1;
	}

	.index_two_title-sm {
		font-size: 18px;
		margin-top: -20px;
		color: #1A183F;
		font-weight: 600;
		position: relative;
		z-index: 1;
	}

	.index_two_title2-sm {
		font-size: 14px;
		color: #757C8B;
		position: relative;
		z-index: 1;
	}

	.index_two_left {
		width: 100%;
		margin-top: calc(20px + 1vw);
		display: flex;
		flex-direction: column;

		.index_two_left_top {
			width: 100%;
			display: flex;
			justify-content: flex-start;

			img {
				width: calc(50px + 1vw);
				/* float: left; */
				margin-right: calc(30px + 1vw);
			}
		}

		.index_two_left_mid {
			display: flex;
			margin-top: calc(10px + 1vw);
			align-items: center;

			img {
				width: 47px;
				height: 9px;
			}

			p {
				font-size: calc(16px + 1vw);
				color: #1A183F;
				font-weight: 600;
				margin: 0;
				margin-left: 10px;
			}
		}

		.index_two_left_tit {
			font-size: calc(12px + 0.5vw);
			color: #1A183F;
			margin-left: 57px;
			text-align: left;
			margin-top: 10px;
		}

		.index_two_left_tit2 {
			font-size: calc(12px + 0.5vw);
			color: #757C8B;
			margin-left: 57px;
			text-align: left;
			margin-top: 10px;
		}

		.index_two_left_bot {
			width: 99%;
			margin-left: 1%;
			margin-top: calc(10px + 1vw);
		}
	}

	.index_two_left_sm {
		width: 100%;
		margin-top: calc(20px + 1vw);
		display: flex;
		flex-direction: column;

		.index_two_left_top {
			width: 100%;

			img {
				width: calc(50px + 1vw);
			}

			img:nth-child(2) {
				margin: 0 30px;
			}
		}

		.index_two_left_mid {
			display: flex;
			margin-top: calc(10px + 1vw);
			align-items: center;
			justify-content: center;

			img {
				width: 47px;
				height: 9px;
			}

			p {
				font-size: calc(16px + 1vw);
				color: #1A183F;
				font-weight: 600;
				margin: 0;
				margin-left: 10px;
			}
		}

		.index_two_left_tit {
			font-size: calc(12px + 0.5vw);
			color: #1A183F;
			text-align: center;
			margin-top: 10px;
		}

		.index_two_left_tit2 {
			font-size: calc(12px + 0.5vw);
			color: #757C8B;
			text-align: center;
			margin-top: 10px;
		}

		.index_two_left_bot {
			width: 99%;
			margin: 0 auto;

		}
	}

	.index_two_right {
		width: 70%;
		margin-top: calc(20px + 1vw);
		float: right;
	}

	.index_two_right_sm {
		width: 90%;
		margin: 0 auto;
	}

	.index_three {
		margin-top: calc(30px + 1vw);
	}

	.index_three_title {
		text-align: left;
		font-size: calc(34px + 1vw);
		color: #1A183F;
		font-weight: 600;
		margin-top: calc(50px + 1vw);
	}

	.index_three_title_sm {
		font-size: 18px;
		margin-top: -10px;
		color: #1A183F;
		font-weight: 600;
		position: relative;
		z-index: 1;
	}

	.index_three_title2 {
		text-align: left;
		font-size: calc(34px + 1vw);
		color: #1A183F;
		font-weight: 600;
	}

	.index_three_title2_sm {
		font-size: 18px;
		margin-top: 5px;
		color: #1A183F;
		font-weight: 600;
		position: relative;
		z-index: 1;
	}

	.index_three_mid_one {
		display: flex;
		align-items: center;
		margin-top: 10px;

		div {
			display: flex;
			align-items: center;

			div {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #757C8B;
			}
		}

		p {
			font-size: calc(12px + 0.8vw);
			color: #1A183F;
			font-weight: 400;
			margin-left: 10px;
			text-align: left;
		}
	}

	.index_three_mid_one_sm {
		margin: 10px auto 0;

		div {
			display: flex;
			align-items: center;
			justify-content: center;

			div {
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #757C8B;
			}
		}

		p {
			font-size: 14px;
			color: #1A183F;
			font-weight: 400;
			margin-left: 10px;
		}
	}

	.index_three_mid_two {
		width: 100%;
		background-color: #ffffff;
		padding: 15px;
		border-radius: 10px;
		height: 100px;

		.index_three_mid_two_top {
			display: flex;
			align-items: center;

			img {
				width: 11px;
				height: 16px;
			}

			p {
				font-size: 14px;
				color: #1A183F;
				margin-left: 15px;
			}
		}

		.index_three_mid_two_bot {
			font-size: 12px;
			color: #757C8B;
			text-align: left;
			margin-top: 10px;
		}
	}

	.index_three_mid_two_sm {
		width: 90%;
		background-color: #ffffff;
		padding: 15px;
		border-radius: 10px;
		margin: 15px auto;

		.index_three_mid_two_top {
			display: flex;
			align-items: center;

			img {
				width: 11px;
				height: 16px;
			}

			p {
				font-size: 14px;
				color: #1A183F;
				margin-left: 15px;
			}
		}

		.index_three_mid_two_bot {
			font-size: 12px;
			color: #757C8B;
			text-align: left;
			margin-top: 10px;
		}
	}

	.index_three_addmar {
		margin-top: calc(280px + 1vw);
	}

	.index_three_mid_img {
		width: 100%;
		margin-top: calc(50px + 1vw);
	}

	.index_three_mid_img_sm {
		width: 90%;
		margin: 15px auto;
	}

	.index_fore_title {
		font-size: calc(34px + 1vw);
		color: #1A183F;
		font-weight: 600;
		margin-top: calc(50px + 1vw);
	}

	.index_fore_title_sm {
		font-size: 18px;
		margin-top: -10px;
		color: #1A183F;
		font-weight: 600;
		position: relative;
		z-index: 1;
	}

	.index_fore_title2 {
		font-size: calc(34px + 1vw);
		color: #1A183F;
		font-weight: 600;
	}

	.index_fore_title2_sm {
		font-size: 18px;
		margin-top: 5px;
		color: #1A183F;
		font-weight: 600;
		position: relative;
		z-index: 1;
	}

	.index_fore_tab {
		width: 50%;
		display: flex;
		height: 64px;
		background-color: #ffffff;
		border-radius: 32px;
		margin: 0 auto;
		margin-top: calc(20px + 1vw);
		padding: 8px;

		.index_fore_tab_one {
			width: 33.33%;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: calc(12px + 0.5vw);
			color: #FFFFFF;
			border-radius: 24px;
		}

		.active {
			background-color: #1A183F;
		}

		.nomal {
			background-color: #ffffff;
			color: #757C8B;
		}
	}

	.index_fore_tab_sm {
		width: 90%;
		display: flex;
		height: 64px;
		background-color: #ffffff;
		border-radius: 32px;
		margin: 0 auto;
		margin-top: 15px;
		padding: 8px;

		.index_fore_tab_one {
			width: 33.33%;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			border-radius: 24px;
		}

		.active {
			background-color: #1A183F;
		}

		.nomal {
			background-color: #ffffff;
			color: #757C8B;
		}
	}

	.index_fore_tip {
		font-size: calc(12px + 0.2vw);
		color: #757C8B;
		margin-top: calc(8px + 0.5vw);
	}

	.index_fore_img {
		width: 100%;
		margin-top: calc(8px + 0.5vw);
	}

	.index_fore_img_sm {
		width: 90%;
		margin: 10px auto 0;
	}

	.index_five_one {
		display: inline-flex;
		align-items: center;
		float: left;
		background-color: #ffffff;
		border-radius: 36px;
		padding: 10px 20px;
		margin-top: calc(12px + 0.8vw);
		margin-left: calc(12px + 0.8vw);

		img {
			width: 26px;
			height: 26px;
		}

		p {
			color: #1A183F;
			font-size: calc(12px + 0.5vw);
			margin-left: 10px;
		}
	}

	.index_five_button {
		width: 184px;
		height: 58px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #0091FE;
		border-radius: 29px;
		margin-top: calc(12px + 0.8vw);
		margin-left: calc(12px + 0.8vw);

		p {
			font-size: 24px;
			color: #FFFFFF;
		}

		img {
			width: 28px;
			height: 28px;
			margin-left: 10px;
		}
	}

	.index_five_button_sm {
		width: 120px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #0091FE;
		border-radius: 29px;
		margin: calc(12px + 0.8vw) auto 0;

		p {
			font-size: 14px;
			color: #FFFFFF;
		}

		img {
			width: 14px;
			height: 14px;
			margin-left: 10px;
		}
	}

	.index_six_one {
		width: 95%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(12px + 0.8vw);
		margin-top: calc(12px + 0.8vw);
		height: calc(255px + 1vw);

		img {
			width: 25%;
			display: flex;
			justify-content: flex-start;
		}

		.index_six_one_p1 {
			color: #1A183F;
			font-size: calc(12px + 0.8vw);
			margin: calc(12px + 0.5vw) 0;
			font-weight: 600;
			text-align: left;
		}

		.index_six_one_p2 {
			color: #1A183F;
			font-size: calc(12px + 0.2vw);
			text-align: left;
		}

		.index_six_one_img {
			width: 62%;
			margin: calc(12px + 0.5vw) 0;
		}

		.index_six_one_button {
			padding: calc(5px + 0.1vw) calc(12px + 0.1vw);
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 34px;
			color: #ffffff;
			display: table;
			margin-top: calc(12px + 0.2vw);
		}

		.index_six_one_button2 {
			padding: calc(5px + 0.1vw) calc(12px + 0.1vw);
			font-size: calc(12px + 0.2vw);
			border-radius: 34px;
			color: #1A183F;
			display: table;
			margin-top: calc(12px + 0.2vw);
			border: 1px solid #1A183F;
		}

		.index_six_one_img2 {
			width: 32%;
		}

		.index_six_one_p3 {
			color: #0091FE;
			font-size: calc(12px + 0.2vw);
			text-align: left;
		}

		.index_six_one_img3 {
			width: 12%;
		}
	}

	.index_six_one_sm {
		width: 90%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(12px + 0.8vw);
		margin: calc(12px + 0.8vw) auto 0;

		img {
			width: 25%;
			display: flex;
			justify-content: flex-start;
		}

		.index_six_one_p1 {
			color: #1A183F;
			font-size: calc(12px + 0.8vw);
			margin: calc(12px + 0.5vw) 0;
			font-weight: 600;
			text-align: left;
		}

		.index_six_one_p2 {
			color: #1A183F;
			font-size: calc(12px + 0.2vw);
			text-align: left;
		}

		.index_six_one_img {
			width: 62%;
			margin: calc(12px + 0.5vw) 0;
		}

		.index_six_one_button {
			padding: calc(5px + 0.1vw) calc(12px + 0.1vw);
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 34px;
			color: #ffffff;
			display: table;
			margin-top: calc(12px + 0.2vw);
		}

		.index_six_one_img2 {
			width: 32%;
		}

		.index_six_one_p3 {
			color: #0091FE;
			font-size: calc(12px + 0.2vw);
			text-align: left;
		}

		.index_six_one_img3 {
			width: 12%;
		}
	}

	.index_seven_one {
		width: 90%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100px + 1vw);
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(5px + 0.6vw);
		margin-top: calc(15px + 1vw);
		color: #757C8B;
	}

	.index_seven_one_sm {
		width: 90%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		border-radius: 10px;
		padding: 15px;
		margin: 15px auto 0;
		color: #757C8B;
		font-size: 14px;
	}

	.index_seven_one_active {
		background-color: #0091FE;
		color: #ffffff;
	}

	.index_eight_all {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		img {
			width: 12%;
			margin-top: calc(15px + 0.1vw);
			margin-left: calc(15px + 0.1vw);
		}
	}

	.index_eight_all_sm {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		img {
			width: 30%;
			margin-top: calc(15px + 0.1vw);
			margin-left: calc(15px + 0.1vw);
		}
	}

	.index_nine_one {
		width: 90%;
		height: calc(230px + 1vw);
		background-color: #ffffff;
		border: 1px solid #0091FE;
		border-radius: 10px;
		margin-top: calc(12px + 1vw);
		padding: calc(15px + 0.2vw);
		position: relative;
		cursor: pointer;

		.index_nine_one_p1 {
			color: #1A183F;
			font-size: calc(12px + 0.8vw);
			font-weight: 600;
			text-align: left;
		}

		.index_nine_one_p2 {
			color: #1A183F;
			font-size: calc(12px + 0.1vw);
			text-align: left;
			margin-top: calc(10px + 0.1vw);
		}

		.index_nine_one_img {
			width: 10%;
			position: absolute;
			bottom: calc(12px + 0.1vw);
			right: calc(12px + 0.1vw);
		}
	}

	.index_nine_two {
		border: 1px solid #1A183F !important;
	}

	.index_nine_one_sm {
		width: 90%;
		background-color: #ffffff;
		border: 1px solid #0091FE;
		border-radius: 10px;
		margin: calc(12px + 1vw) auto 0;
		padding: 15px 15px 50px 15px;
		position: relative;

		.index_nine_one_p1 {
			color: #1A183F;
			font-size: 16px;
			font-weight: 600;
			text-align: left;
		}

		.index_nine_one_p2 {
			color: #1A183F;
			font-size: 14px;
			text-align: left;
			margin-top: 15px;
		}

		.index_nine_one_img {
			width: 24px;
			position: absolute;
			bottom: 15px;
			right: 15px;
		}
	}

	.index_ten_img {
		width: 30%;
		margin: calc(30px + 1vw) auto 0;
	}

	.index_ten_img_sm {
		width: 115px;
		margin: 0 auto;
	}

	.index_ten_button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: #0091FE;
		color: #ffffff;
		border-radius: 8px;
		font-size: calc(12px + 0.7vw);
		padding: 8px 20px;
		letter-spacing: 1px;
		margin-top: calc(10px + 1vw);
	}
</style>