export default {
  ethPackage: "Мой ледяной кошелек",
  cancel: "Отмена",
  add: "Добавить",
  tip: "Совет",
  input_password: "Введите пароль",
  please_enter_password: "Пожалуйста, введите пароль",
  back: "Назад",
  copy_success: "Копирование успешно",
  upload_keystore: "Пожалуйста, загрузите файл Keystore и введите пароль",
  no_mnemonic: "Нет мнемоники",
  file_parse_failed: "Не удалось разобрать файл, пожалуйста, проверьте пароль",
  wallet_address_parse_failed: "Не удалось разобрать адрес кошелька, пожалуйста, подтвердите подлинность приватного ключа!",
  wallet_generation_failed: "Не удалось создать кошелек",
  step_1: "1",
  step_2: "2",
  step_2_label: "Шаг 2",
  input_password_to_unlock: "Введите пароль для разблокировки кошелька",
  next_step: "Следующий шаг",
  Accessmywallet:'Посетить мой кошелек',
  'Trusted by millions': 'Полностью децентрализованный кошелек',    
    'worldwide since 2015': 'Поддержка создания кошелька в оффлайн-режиме',    
    'Store': 'Оффлайн-режим',    
    'Ethereum': 'Экосистема Ethereum',    
    'safely': 'Сообщество',    
    'Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.': 'Мой ледяной кошелек поддерживает создание кошелька в оффлайн-режиме, чтобы гарантировать, что ваш кошелек находится в самом безопасном состоянии.',    
    'Stay in control': 'Безопасность кошелька',    
    'We secure your': 'Мы не можем управлять вашим кошельком, единственным владельцем кошелька являетесь только вы. Бережно храните ваш кошелек, и только отключив интернет и управляя кошельком в оффлайн-режиме, вы обеспечите наибольшую безопасность.',    
    'Buy, sell, and': 'Кросс-цепочные валюты',    
    'swap safely': 'Безопасный обмен',    
    'Convert USD, EUR': 'Мы интегрировали децентрализованных партнеров для обмена криптовалютами, предлагая сотни криптовалют и фиатных валют для обмена, без необходимости полагаться на централизованные биржи.',    
    'Easy asset': 'Экосистема Ethereum',    
    'management': 'Сообщество Web3',    
    'Compare and secure the': 'Экосистема Ethereum имеет крупнейшее сообщество разработчиков во всей Web3.',    
    'How to secure your Ethereum wallet': 'Как защитить свой Эфириум-кошелёк',    
    'Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.': 'Создание кошелька в оффлайн-режиме и хранение кошелька вне интернета — это самый безопасный способ. Никогда не нажимайте на подозрительные ссылки, так как они могут быть попыткой фишинга для кражи ваших активов. Обратите внимание, что мой ледяной кошелек никогда не будет запрашивать у вас информацию о кошельке. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с нашей службой поддержки.',    
    'Frequently asked questions': 'Часто задаваемые вопросы',    
    'Supporting all ERC-20 tokens': 'Поддерживает все токены ERC-20',    
    'If it is on Ethereum': 'Мой ледяной кошелек поддерживает все токены на блокчейне Ethereum, без необходимости вручную добавлять пользовательские токены.',    
    'Grab control of your future': 'Поверни контроль над своим будучем',    
    'Create a new wallet': 'Создать новый кошелёк',    
    'Get better at crypto': 'Мы принимаем ваше предложение.',    
    'Level up your skills with security tips, industry insights, new and more!': 'Мы с благодарностью принимаем ваши предложения и стремимся стать лучше!',    
    'Enter your email': 'Введите свой электронный адрес',    
    'Sign me up!': 'Свяжитесь с нами',    
    'Warning: Trading cryptocurrencies involves significant high risks.': 'Предупреждение: Не раскрывайте информацию о приватном ключе в интернете!',    
    'BTC Direct was rated a 4.5 out of 5 based on 10475 reviews': 'MyIceWallet получил оценку 4.7/5 на основе 15,175 отзывов.',    
    '© 2013 - 2024 - BTC Direct Europe B.V.': '© 2024 MyIceWallet. All rights reserved',    
    'Sitemap': 'Карта сайта',    
    'Disclaimer': 'Отказ от ответственности',    
    'Terms of Service': 'Условия обслуговивания',    
    'Privacy Policy': 'Политика конфиденциальности',    
    'Fiat onramp': 'Технические вопросы и ответы',  
    'Back up your wallet': 'Создать резервную копию вашего кошелёка',    
    'Never share your private key': 'Никогда не делитесь с кем-либо своим приватным ключ',    
    'Keep your wallet and device software up to date': 'Не используйте свой кошелек на незнакомых компьютерах или смартфонах.',    
    'Be cautious of phishing scams': 'Будьте остороги в отношении фишинговых атак',    
    'What is a Ethereum wallet?': 'Что такое Эфириум-кошелёк?',    
    'Are Ethereum wallets free?': 'Бесплатны ли Эфириум-кошельки?',    
    'What is the safest Ethereum Wallet?': 'Какой Эфириум-кошелёк самый безопасный?',    
    'Which app can I use to create my Ethereum wallet?': 'Каким приложением я могу использовать, чтобы создать свой Эфириум-кошелёк?',
    'boots1': "Защитите свои активы, создавая несколько безопасных резервных копий. Мой ледяной кошелек не контролирует и не хранит копии ваших приватных ключей, поэтому сохраняйте свои резервные копии в безопасном месте. Лучше записать приватные ключи или фразы для восстановления ручкой на бумаге или распечатать их. Не сохраняйте их в электронной почте или облачном хранилище!",  
    'boots2': "Ваш приватный ключ (также называемый мнемоническим фразой) является главным приватным ключом вашего кошелька. Его разделение будет равнозначно передаче контроля над активами кому-то еще. Обеспечьте конфиденциальность вашего приватного ключа, никогда не сообщайте его онлайн или в текстовых сообщениях, чтобы сохранить безопасность и целостность вашего кошелька.",  
    'boots3': "Не используйте свой кошелек на незнакомых компьютерах или смартфонах. Будьте осторожны с вирусами в незнакомых средах, чтобы защитить свои активы.",  
    'boots4': "Всегда будьте настороже к несанкционированным сообщениям или электронным письмам, требующим от вас предоставления информации о кошельке. Проверяйте источник и никогда не нажимайте на подозрительные ссылки, так как это может быть попытка фишинга для кражи ваших активов. Обратите внимание, что мой ледяной кошелек никогда не будет запрашивать у вас приватные ключи или фразы для восстановления. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с нашей службой поддержки.",  
    'boots5': "Эфириум-кошелек — это цифровой инструмент, который позволяет вам безопасно покупать, хранить и управлять эфириумом. Он позволяет вам отправлять и получать эфириум, а также получать доступ к функциям покупки, продажи и обмена. Мой ледяной кошелек — это полностью децентрализованный кошелек, и файлы кошелька, приватные ключи и фразы для восстановления могут быть импортированы в официальный клиент Ethereum для использования.",  
    'boots6': "Да, мой ледяной кошелек бесплатен. Однако за такие транзакции, как покупка или перевод эфириума, могут взиматься сетевые сборы. Мой ледяной кошелек предлагает безопасную платформу для управления эфириумом и другими криптовалютами как для новичков, так и для опытных энтузиастов.",  
    'boots7': "Безопасный эфириум-кошелек — это кошелек, который позволяет вам полностью контролировать свои приватные ключи и предоставляет функции, помогающие защитить ваши активы. Мой ледяной кошелек нацелен на удовлетворение этих стандартов, предоставляя безопасное хранилище для эфириума. Вы можете управлять своими приватными ключами и фразами для восстановления, обеспечивая доступ к вашим активам только вам. Мой ледяной кошелек всегда с вами.",  
    'boots8': "Мой ледяной кошелек — это полностью децентрализованный кошелек. Файлы кошелька, приватные ключи и фразы для восстановления могут быть импортированы в официальный клиент Ethereum для взаимодействия. Независимо от того, какой эфириум-кошелек вы используете, обязательно выбирайте децентрализованный кошелек!",
  components: {
    add_custom_token: "Добавить пользовательский токен",
    enter_correct_address: "Пожалуйста, введите правильный адрес контракта",
    select_network: "Выберите сеть",
    enter_contract_addressm: "Пожалуйста, введите адрес контракта",
    about_us: "О нас",
    advertise_with_us: "Рекламируйте с нами",
    privacy: "Конфиденциальность",
    terms: "Условия",
    help_center: "Центр помощи",
    frequently_asked_questions: "Часто задаваемые вопросы",
    join_community: "Присоединяйтесь к сообществу",
    buy_crypto: "Купить криптовалюту",
    swap_tokens: "Обменять токены",
    more_features: "Больше функций",
    resources: "Ресурсы",
    products: "Продукты",
    access_my_wallet: "Доступ к моему кошельку",
    go_to: "Перейти к",
    mew_mobile: "MEW Мобильный",
    encrypt: "Шифрование",
    ethereum_virtual_machine: "Эфириум виртуальная машина",
    select_software_wallet: "Выберите программный кошелек",
    keystore: "Keystore",
    mnemonic: "Мнемоника",
    private_key: "Приватный ключ",
    save_wallet_warning: "Пожалуйста, сохраняйте ваш кошелек оффлайн, создайте резервную копию на USB-накопителе и запишите свой приватный ключ и мнемонику на бумаге. Не храните их в электронной почте или облачном хранилище.",
    access_wallet_with_keystore: "Получите доступ к кошельку с помощью файла Keystore",
    select_file: "Выбрать файл",
    input_password: "Введите пароль",
    step_1: "Шаг 1",
    select_keystore_file: "Выберите файл Keystore",
    select_keystore_file_warning: "Пожалуйста, выберите файл Keystore для разблокировки кошелька",
    not_recommended: "Не рекомендуется",
    access_wallet: "Получить доступ к кошельку",
    access_wallet_with_mnemonic: "Получите доступ к кошельку с помощью мнемоники",
    input_phrase: "Введите фразу",
    address_and_network: "Адрес и сеть",
    input_mnemonic: "Введите вашу мнемонику",
    input_mnemonic_order: "Пожалуйста, введите вашу мнемонику в правильном порядке.",
    select_address_and_network: "Выберите адрес и сеть",
    address: "Адрес",
    amount: "Сумма",
    network: "Сеть",
    access_wallet_with_private_key: "Получите доступ к кошельку с помощью приватного ключа",
    input_private_key: "Введите ваш приватный ключ",
    please_enter_private_key: "Пожалуйста, введите приватный ключ",
  },
  mps: {
    my_public_address: "Мой публичный адрес для получения средств",
    receive_token_instruction: "Чтобы получить TOKEN с другого счета, отправьте TOKEN с этого счета на этот адрес.",
    copy: "Копировать",
    my_paper_wallet: "Мой бумажный кошелек",
    paper_wallet: "Бумажный кошелек",
    my_wallet_address: "Мой адрес кошелька",
    my_private_key: "Мой приватный ключ",
    private_key_warning: "Вы можете потерять свои средства, если поделитесь этим приватным ключом с кем-либо! Пожалуйста, храните свой приватный ключ в безопасном месте!",
    print: "Печать",
    settings: "Настройки",
    wallet_address: "Адрес кошелька",
    max_wallets: "Вы можете добавить до 10 кошельков.",
    enter_wallet_address: "Пожалуйста, введите адрес кошелька",
    confirm_add: "Подтвердить добавление",
    currency_setting: "Настройка валюты",
    usd: "Доллар США",
    jpy: "Японская иена",
    normal_priority: "Обычный приоритет",
    higher_priority: "Высокий приоритет",
    highest_priority: "Наивысший приоритет",
    fifteen_minutes: "15 минут",
    wallet_address_exists: "Адрес кошелька уже существует",
  },
  chdhl: {
    create_new_wallet: "Создать новый кошелек",
    select_wallet_creation_method: "Пожалуйста, выберите способ создания нового кошелька",
    already_have_wallet: "У вас уже есть кошелек?",
    access_wallet: "Получить доступ к кошельку",
    keystore_file: "Файл Keystore",
    keystore_warning: "Использование файла Keystore онлайн увеличивает риск потери ваших средств. Мы не рекомендуем использовать этот метод для создания кошелька.",
    mnemonic_phrase: "Мнемоническая фраза",
    mnemonic_warning: "Использование мнемоники онлайн увеличивает риск потери ваших средств. Мы не рекомендуем использовать этот метод для создания кошелька.",
    return_to_home: "Вернуться на главную",
    not_recommended: "Не рекомендуется",
    offline_storage_advice: "Пожалуйста, сохраняйте ваш кошелек оффлайн, создайте резервную копию на USB-накопителе и запишите свой приватный ключ и мнемонику на бумаге. Не храните их в электронной почте или облачном хранилище!",
    enter_password: "Введите пароль",
    download_file: "Скачать файл",
    step_3: "3",
    reenter_password: "Пожалуйста, повторно введите пароль",
    create_wallet: "Создать кошелек",
    download_keystore_file: "Скачать файл Keystore",
    important_info_before_download: "Важная информация, которую нужно знать перед загрузкой файла Keystore",
    dont_lose_it: "Не потеряйте его",
    be_careful: "Будьте осторожны, если потеряете, его нельзя будет восстановить.",
    do_not_share: "Не делитесь",
    phishing_warning: "Если вы используете этот файл на вредоносном фишинговом сайте, ваши средства будут украдены.",
    make_a_backup: "Создайте резервную копию",
    protect_it: "Защитите его, как будто он может стоить миллионы долларов.",
    confirm_download: "Подтвердить загрузку",
    step_3s: "Шаг 3",
    well_done: "Хорошая работа!",
    ready_to_use: "Теперь вы готовы воспользоваться всем, что предлагает Эфириум, используя файл Keystore только в оффлайн-режиме.",
    create_another_wallet: "Создать другой кошелек",
    write_down_phrase: "Запишите фразу",
    verify_phrase: "Проверить фразу",
    update: "Обновить",
    write_them_down: "Запишите их",
    select_correct_words: "Пожалуйста, выберите правильные слова в соответствии с номерами и введите лишние слова.",
    verify: "Проверить",
    password_mismatch: "Пароли не совпадают",
    verification_failed: "Проверка не удалась",
    keystore_generation_failed: "Не удалось создать файл Keystore",
  },
  help_detail: {
    search_articles: "Поиск статей…",
    all_series: "Все серии",
    introduction: "Введение",
    overview: "Обзор",
    about_mew: "Введение во все, что касается MEW.",
    author: "Автор",
    updated_week_ago: "Обновлено неделю назад",
    description: "Это неуправляемый открытый клиентский интерфейс, который позволяет пользователям напрямую взаимодействовать с блокчейном Эфириума, полностью контролируя свои криптоактивы. MEW может использоваться как веб-кошелек, мобильное приложение для iOS и Android, а также браузерное расширение для кошелька.",
    wallet_app: "Приложение для кошелька",
    mobile_wallet_guide: "Руководство по использованию нашего мобильного кошелька MEW",
    encryption: "Шифрование",
    enkrypt_resources: "Ресурсы и руководства для расширения веб-браузера Enkrypt.",
    security_privacy: "Безопасность и конфиденциальность",
    wallet_security: "Как обеспечить безопасность вашего кошелька на MEW.",
    access_wallet: "Доступ к кошельку",
    access_methods: "Как получить доступ к кошелькам на MEW с помощью поддерживаемых методов.",
    send_transaction: "Отправить транзакцию",
    send_transaction_guide: "Как отправить транзакции на MEW",
    exchange: "Обмен",
    exchange_guide: "Как обменивать на MEW, предоставляемом нашими партнерами по обмену.",
    tokens: "Токены",
    token_interaction: "Как взаимодействовать с токенами на MEW.",
    decentralized_apps: "Децентрализованные приложения",
    dapp_interaction: "Как подключаться и взаимодействовать с DApps с помощью MEW.",
    mew_offline: "MEW в оффлайне",
    offline_usage: "Как использовать MEW в оффлайне на версии 5. Версия MEW 6 пока не поддерживает оффлайн-функциональность.",
    networks_nodes: "Сети и узлы",
    connect_networks: "Как подключаться к различным сетям и узлам.",
  },
  help_list: {
    and: "и",
    other: "другие",
    articles: "статьи",
    return_to_basics: "Вернуться к основам:",
    mew_tips: "Советы от MEW",
    exchange_difference: "В чем разница между биржами (например, Coinbase, Kraken, Gemini, Binance) и MyEtherWallet?",
    get_started: "Начать",
    create_wallet: "Как создать кошелек Эфириум с помощью MEW",
    transfer_crypto: "Как перевести вашу криптовалюту с Binance на Enkrypt или MEW кошелек",
    create_cold_wallet: "Как создать холодный кошелек (также известный как бумажный кошелек)",
    buy_sell_crypto: "Как покупать и продавать криптовалюту с помощью портфолио MEW",
    what_is_non_custodial_wallet: "Что такое неуправляемый кошелек?",
    check_balance: "Как проверить свой баланс на блокчейне Эфириума",
    explore_deeply: "Глубокое изучение",
    crypto_terms: "Глоссарий общепринятых терминов в сообществе Эфириума/криптовалюты",
    what_if_mew_fails: "Что произойдет, если MEW потерпит неудачу?",
    does_mew_support_btc: "Поддерживает ли MEW биткойн (BTC) или другие валюты?",
    what_is_eth_smart_contract: "Что такое смарт-контракт Эфириума?",
    error_window_blocked: "Ошибка: Окно заблокировано",
    ensure_unique_address: "Убедитесь, что MEW не предоставляет один и тот же публичный адрес разным людям",
    submit_pull_request: "Как отправить Pull Request на GitHub MEW",
    does_mew_have_api: "Есть ли у MyEtherWallet API?",
    mew_api_request_limit: "Существует ли ограничение на количество запросов, которые я могу отправить в MEW API?",
    use_mew_as_pwa: "Как использовать MEW как прогрессивное веб-приложение (PWA) или Chrome-приложение",
  },
  index: {
    mew_team_advice: "Советы и ответы от команды MyEtherWallet",
    most_reputable_wallet: "Мой ледяной кошелек поддерживает хранение и отправку всех токенов ERC20 в экосистеме Ethereum.",
    create_new_wallet: "Создать новый кошелек",
    or: "или",
    access_my_wallet: "Получить доступ к моему кошельку",
    our_products_make_crypto_easier: "Вы полностью контролируете свои ключи, фразы для восстановления и приватные ключи.",
    available_on_mobile_and_desktop: "Пожалуйста, храните свой кошелек в оффлайн-режиме и не сохраняйте его в электронной почте или облачном хранилище!",
    mew_mobile_app: "MEW Мобильное приложение",
    stake_swap_manage_crypto: "Ставьте, меняйте и управляйте своей криптовалютой в любое время и в любом месте.",
    security_like_hardware_wallet: "Защитите свою мобильную криптовалюту с помощью безопасности, сопоставимой с аппаратными кошельками.",
    powerful_nft_management: "Мощное управление NFT, браузер на блокчейне и многое другое.",
    want_to_enter_crypto: "Хотите войти в мир криптовалют?",
    mew_is_best_choice: "MEW - ваш лучший выбор.",
    ethereum_ecosystem: "Экосистема Эфириума имеет самое большое сообщество разработчиков в web3.",
    trusted_ethereum_wallet: "MEW - один из самых доверенных и проверенных кошельков Эфириума.",
    not_investment_advice: "Это не инвестиционный совет. Все решения принимаются на ваш собственный риск.",
    truly_own_your_crypto: "По-настоящему владейте своими криптоактивами.",
    self_custody_wallet: "MEW - это кошелек с самоуправлением, что означает, что вы держите свои ключи, и никто (даже команда MEW) не может получить доступ к вашей криптовалюте.",
    buy_store_send_swap_tokens: "Покупайте, храните, отправляйте и меняйте токены",
    store_tokens: "Храните токены",
    buy_sell: "Покупка и продажа",
    swap: "Обмен",
    mew_supports_eth_and_erc20: "MEW поддерживает ETH и все токены ERC-20!",
    get_tokens: "Получите токены",
    make_crypto_yours: "Сделайте свои криптовалюты по-настоящему вашими.",
    stake_your_eth: "Ставьте свои ETH и получайте вознаграждения на блокчейне Эфириума.",
    be_your_own_bank: "Станьте своим собственным банком",
    crypto_as_banking: "В отличие от крупных финансовых банков, которые одалживают ваши средства, в криптовалютном мире вы - банк. Получайте вознаграждения, помогая защищать Эфириум.",
    staking_made_easy: "Ставки стали проще",
    easy_staking_with_mew: "С MEW мы делаем так, чтобы вы могли легко ставить свою криптовалюту из вашего кошелька и сразу же начинать получать вознаграждения.",
    start_staking_now: "Начните ставить сейчас",
    staking: "Ставки",
    no_minimum_liquidity: "Нет минимальных требований к ликвидности для ставок",
    annual_rate_up_to: "Годовая ставка до",
    powerful_wallet: "Мощный кошелек, чтобы удовлетворить все ваши крипто-нужды.",
    store_all_your_nfts: "Безопасно храните все свои NFT в одном месте с разных цепей, на мобильных или настольных устройствах.",
    access_web3_apps: "Получите доступ к приложениям web3. Используйте Enkrypt на настольном ПК, чтобы использовать свои любимые приложения web3, или используйте мобильное приложение MEW, чтобы получить доступ к своим любимым приложениям web3.",
    multi_chain_access: "Мультицепной доступ. Подключайтесь к децентрализованным сетям и мостите активы между популярными сетями.",
    why_mew_is_good_choice: "Почему MEW - хороший выбор?",
    crypto_expert_since_2015: "Эксперт по криптовалютам с 2015 года",
    first_trusted_wallet: "MEW - один из первых и самых доверенных кошельков Эфириума и L2.",
    private: "Частный",
    no_tracking: "Мы не отслеживаем никакую личную информацию, адреса аккаунтов или балансы активов.",
    hardware_wallet_support: "Поддержка аппаратных кошельков",
    support_major_hardware_wallets: "Мы поддерживаем все основные аппаратные кошельки, включая Ledger и Trezor в продуктовой линейке MEW и Enkrypt.",
    secure_transparent: "Безопасно и прозрачно",
    independent_audits: "Проходите независимые аудиты в HackenProof и предыдущих программах Bug Bounty от HackerOne.",
    self_custody: "Самостоятельное хранение",
    you_control_your_assets: "Только вы контролируете свои активы. Ваши ключи, ваша криптовалюта.",
    easy_to_use: "Простой в использовании",
    start_in_minutes: "Начните за несколько минут без предварительных знаний.",
    arm_yourself_with_knowledge: "Вооружитесь знаниями.",
    new_to_crypto: "Впервые сталкиваетесь с криптовалютой? Исследуйте MEWtopia!",
    free_resources: "Используйте нашу бесплатную библиотеку ресурсов, чтобы узнать, как максимально эффективно использовать Эфириум!",
    help_center: "Центр помощи",
    faq: "Часто задаваемые вопросы",
    common_crypto_questions: "Общие вопросы о криптовалюте",
    chat_with_support: "Чат с поддержкой",
    get_human_help: "Получите помощь от реальных людей",
    create_new_wallet: "Создать новый кошелек",
    ethereum_eth: "Эфириум (ETH)",
    ondo: "Ондо (ONDO)",
    unibot: "Юнибот (UNIBOT)",
    busd: "Бинанс USD (BUSD)",
    icx: "ICON (ICX)",
    grt: "Граф (GRT)",
    aurora: "Аврора (AURORA)",
    root: "Корневая сеть (ROOT)",
    lime: "IME Лаборатория (LIME)",
  },
  package: {
    ethereum: "Эфириум",
    buy_store_send: "Купить, сохранить, отправить",
    usd: "Доллар США",
    swap_tokens: "и обменять токены",
    mew_supports_eth_and_erc20: "MEW поддерживает ETH и все ERC-20",
    make_your_crypto_yours: "Сделайте свои криптовалюты по-настоящему вашими.",
    tokens: "Токены!",
    cryptocurrency: "Криптовалюта.",
    meet_all_your_crypto_needs: "Удовлетворите все свои крипто-нужды.",
    why_mew_is_good_choice: "Почему MEW - хороший выбор?",
    buy_hardware_wallet: "Купить аппаратный кошелек",
    portfolio_value: "Посмотреть информацию о кошельке",
    wallet: "Кошелек",
    send: "Отправить",
    settings: "Настройки",
    logout: "Выйти",
    other_apps: "Другие приложения",
    apps: "Приложения",
    nft_manager: "Менеджер NFT",
    exchange: "Обмен",
    stake: "Ставка",
    received: "Получено",
    buy_sell: "Купить и продать",
    contract: "Контракт",
    info: "Информация",
    new_feature_stake_any_amount_of_eth: "Остерегайтесь незнакомых ссылок, незнакомой среды и авторизованных QR-кодов.",
    stake_eth_no_minimum: "Не раскрывайте ваш кошелек, хранилище ключей, мнемонические фразы и приватные ключи. Не давайте разрешения легко.",
    stake_now: "Ставьте сейчас",
    advertise_with_us: "Рекламируйте с нами",
    network: "Сеть",
    block_height: "Высота блока:",
    total_assets: "Общие активы:",
    add_custom_token: "Добавить пользовательский токен",
    send: "Отправить",
    delete: "Удалить",
    transaction_history: "История транзакций",
    transfer_out: "OUT",
    transfer_in: "IN",
    no_transactions: "У вас пока нет транзакций",
    are_you_sure_logout: "Вы уверены, что хотите выйти?",
    are_you_sure_delete_token: "Вы уверены, что хотите удалить текущий токен?",
    tx_hash: "TX Хэш",
    transaction_time: "Время транзакции",
    from: "От",
    to: "К",
    amount: "Сумма",
    fee: "Комиссия",
    token: "Токен",
    hold: "Держать",
    price: "Цена",
    years_ago: "лет назад",
    months_ago: "месяцев назад",
    days_ago: "дней назад",
    hours_ago: "часов назад",
    minutes_ago: "минут назад",
    seconds_ago: "секунд назад",
    failed_to_get_height: "Не удалось получить высоту",
  },
  send: {
    line1: "Почему MEW -",
    line2: "хороший выбор?",
    line3: "Советы по операциям",
    line4: "Подтвердить",
  },
  zhensend: {
    balance: "Баланс:",
    max_limit: "всё",
    your: "Ваш",
    low_balance: "Слишком низкий баланс",
    transaction_fee: "Каждая транзакция требует небольшую сумму",
    cannot_send: "для выполнения. Даже если у вас есть токены для обмена, когда ваш",
    near_zero_balance: "баланс близок к нулю, вы не сможете отправить ничего, пока не пополните свой счет.",
    enter_address: "Пожалуйста, введите адрес для перевода",
    fee: "Комиссия за транзакцию",
    total: "Итого:",
    insufficient_fee: "Недостаточно средств для оплаты сетевой комиссии.",
    custom: "Пользовательский",
    max_fee: "Максимальная комиссия",
    max_tip: "Максимальный совет",
    send_transaction: "Отправить",
    zero_balance: "Баланс токенов равен 0~",
    enter_amount: "Пожалуйста, введите сумму перевода",
    insufficient_token_balance: "Недостаточно токенов на балансе",
    transaction_cost: "Комиссия за транзакцию составляет",
    transaction_success: "Транзакция успешна",
    insufficient_gas: "Недостаточно GAS",
    transaction_failed: "Транзакция не удалась, пожалуйста, проверьте вводимые данные",
    gas_limit_failed: "Не удалось оценить лимит газа",
    token_amount_prompt: "Введите количество"
  }
};
