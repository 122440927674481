<template>
	<div id="app">
		<router-view></router-view>
		<!-- 仅在超小屏幕上显示 -->
		<!-- <b-col class="d-block d-sm-none">
			超小屏幕显示的内容
		</b-col> -->

		<!-- 仅在中等及以上屏幕上显示 -->
		<!-- <b-col class="d-none d-md-block">
			中等及以上屏幕显示的内容
		</b-col> -->

		<!-- 仅在大屏幕及以上显示 -->
		<!-- <b-col class="d-none d-lg-block">
			大屏幕及以上显示的内容
		</b-col> -->

		<!-- 仅在超大屏幕上显示 -->
		<!-- <b-col class="d-none d-xl-block">
			超大屏幕显示的内容
		</b-col> -->
	</div>
</template>

<script>
	import Web3 from 'web3';
	import jsonData from '../static/rpc.json';
	export default {
		name: 'App',
		created(){
			var web3;
			if(localStorage.getItem("rpc") == null){
				localStorage.setItem("rpc", JSON.stringify(jsonData.rpcList[0]));
				web3 = new Web3(jsonData.rpcList[0].rpcUrl);
			}else{
				var rpcData = JSON.parse(localStorage.getItem("rpc"));
				web3 = new Web3(rpcData.rpcUrl);
			}
			this.$store.commit('setWeb', web3);
		},
	}
</script>

<style>

</style>