<template>
	<div>
		<b-row style="margin: 0;width: 100%;">
			<!-- 手机端顶部导航栏 -->
			<b-col cols="12" class="d-block d-sm-none" style="padding: 0;">
				<div class="phone_nav">
					<img src="../../static/logo.png" alt="Kitten" class="phone_nav_logo">
					<div class="phone_nav_menu" @click="toggleNav()">
						<img src="../../static/menu.png" alt="">
					</div>
				</div>
			</b-col>
			<transition name="slide">
				<b-row class="d-block d-sm-none slider_row" v-if="showSlider" @click="showSlider = false">
					<b-col cols="9" style="padding: 0;">
						<div style="height: 100vh;background-color: #ffffff;">
							<div class="package_left">
								<div class="package_left_logo">
									<img src="../../static/logo.png" alt="Kitten" class="package_left_logo_logo">
									<div class="package_left_logoInfo">
										<img src="../../static/pic_qb_touzi@2x.png" alt=""
											class="package_left_logoInfo_bgimg">
										<div class="package_left_logoInfo_mid">
											<div>
												<div class="package_left_logoInfo_mid_tit" @click="pageShowFun()">
													<p>{{ $t('package.portfolio_value') }}</p>
													<img src="../../static/whitedown.png" alt="">
												</div>
												<p class="package_left_logoInfo_mid_address">
													{{maskString(wallet.address)}}
												</p>
											</div>
											<p class="package_left_logoInfo_mid_zichan">$ {{ethPrice}}</p>
											<div class="package_left_logoInfo_mid_bot">
												<p v-if="items2.length">{{items2[0].balance}} {{rpcListDefaul.symbol}}</p>
												<p v-else>0 {{rpcListDefaul.symbol}}</p>
												<div style="display: flex;">
													<img src="../../static/icon_qb_tz_erweima@2x.png" alt=""
														@click="showErweimaFun()">
													<img src="../../static/icon_qb_tz_fuzhi@2x.png" alt=""
														v-clipboard:copy="wallet.address" v-clipboard:success="onCopy"
														v-clipboard:error="onError">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_one" style="background-color:#E5F4FE;">
										<img src="../../static/icon_qb_wenjianjia@2x.png" alt="">
										<p>{{ $t('package.wallet') }}</p>
									</div>
									<div class="package_left_nav_one" @click="goUrl()">
										<img src="../../static/icon_qb_fasong@2x.png" alt="">
										<p>{{ $t('package.send') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_one" @click="pageSteShow()">
										<img src="../../static/icon_qb_shezhi@2x.png" alt="">
										<p>{{ $t('package.settings') }}</p>
									</div>
									<div class="package_left_nav_one" @click="logout()">
										<img src="../../static/icon_qb_dengchu@2x.png" alt="">
										<p>{{ $t('package.logout') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_two" @click.stop="xiasi()">
										<p>{{ $t('package.other_apps') }}</p>
										<img :src="!showall?require('../../static/icon_qb_lb_zhankai@2x.png'):require('../../static/icon_qb_lb_yzk@2x.png')"
											alt="">
									</div>
									<b-collapse id="collapseExample" v-model="showall">
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_yingyong@2x.png" alt="">
											<p>{{ $t('package.apps') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_nft@2x.png" alt="">
											<p>{{ $t('package.nft_manager') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_jiaohuan@2x.png" alt="">
											<p>{{ $t('package.exchange') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_duzhu@2x.png" alt="">
											<p>{{ $t('package.stake') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_shoudao@2x.png" alt="">
											<p>{{ $t('package.received') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_maimai@2x.png" alt="">
											<p>{{ $t('package.buy_sell') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_hetong@2x.png" alt="">
											<p>{{ $t('package.contract') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_xinxi@2x.png" alt="">
											<p>{{ $t('package.info') }}</p>
										</div>
									</b-collapse>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</transition>
			<!-- pc端显示 -->
			<b-col md="3" class="d-none d-md-block" style="padding: 0;">
				<div class="package_left add_height">
					<div class="package_left_logo">
						<img src="../../static/logo.png" alt="Kitten" class="package_left_logo_logo">
						<div class="package_left_logoInfo">
							<img src="../../static/pic_qb_touzi@2x.png" alt="" class="package_left_logoInfo_bgimg">
							<div class="package_left_logoInfo_mid">
								<div>
									<div class="package_left_logoInfo_mid_tit" @click="pageShowFun()">
										<p>{{ $t('package.portfolio_value') }}</p>
										<img src="../../static/whitedown.png" alt="">
									</div>
									<p class="package_left_logoInfo_mid_address">{{maskString(wallet.address)}}</p>
								</div>
								<p class="package_left_logoInfo_mid_zichan">$ {{ethPrice}}</p>
								<div class="package_left_logoInfo_mid_bot">
									<p v-if="items2.length">{{items2[0].balance}} {{rpcListDefaul.symbol}}</p>
									<p v-else>0 {{rpcListDefaul.symbol}}</p>
									<div style="display: flex;">
										<img src="../../static/icon_qb_tz_erweima@2x.png" alt=""
											@click="showErweimaFun()">
										<img src="../../static/icon_qb_tz_fuzhi@2x.png" alt=""
											v-clipboard:copy="wallet.address" v-clipboard:success="onCopy"
											v-clipboard:error="onError">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="package_left_nav">
						<div class="package_left_nav_one" style="background-color:#E5F4FE;">
										<img src="../../static/icon_qb_wenjianjia@2x.png" alt="">
										<p>{{ $t('package.wallet') }}</p>
									</div>
									<div class="package_left_nav_one" @click="goUrl()">
										<img src="../../static/icon_qb_fasong@2x.png" alt="">
										<p>{{ $t('package.send') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_one" @click="pageSteShow()">
										<img src="../../static/icon_qb_shezhi@2x.png" alt="">
										<p>{{ $t('package.settings') }}</p>
									</div>
									<div class="package_left_nav_one" @click="logout()">
										<img src="../../static/icon_qb_dengchu@2x.png" alt="">
										<p>{{ $t('package.logout') }}</p>
									</div>
								</div>
								<div class="package_left_nav">
									<div class="package_left_nav_two" @click.stop="xiasi()">
										<p>{{ $t('package.other_apps') }}</p>
										<img :src="!showall?require('../../static/icon_qb_lb_zhankai@2x.png'):require('../../static/icon_qb_lb_yzk@2x.png')"
											alt="">
									</div>
									<b-collapse id="collapseExample" v-model="showall">
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_yingyong@2x.png" alt="">
											<p>{{ $t('package.apps') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_nft@2x.png" alt="">
											<p>{{ $t('package.nft_manager') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_jiaohuan@2x.png" alt="">
											<p>{{ $t('package.exchange') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_duzhu@2x.png" alt="">
											<p>{{ $t('package.stake') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_shoudao@2x.png" alt="">
											<p>{{ $t('package.received') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_maimai@2x.png" alt="">
											<p>{{ $t('package.buy_sell') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_hetong@2x.png" alt="">
											<p>{{ $t('package.contract') }}</p>
										</div>
										<div class="package_left_nav_one">
											<img src="../../static/icon_qb_lb_xinxi@2x.png" alt="">
											<p>{{ $t('package.info') }}</p>
										</div>
						</b-collapse>
					</div>
				</div>
			</b-col>
			<b-col md="9">
				<div class="package_gg">
					<div class="package_gg_left">
						<img src="../../static/pic_xgn_logo@2x.png" alt="" class="d-none d-md-block">
						<div class="package_gg_left_right d-none d-md-block">
							<p class="package_gg_left_right_p1">{{ $t('package.new_feature_stake_any_amount_of_eth') }}</p>
							<p class="package_gg_left_right_p2">{{ $t('package.stake_eth_no_minimum') }}<span>
								<!-- {{ $t('package.stake_now') }} -->
							</span>
							</p>
						</div>
						<div class="package_gg_left_right_sm d-block d-sm-none">
							<p class="package_gg_left_right_p1">{{ $t('package.new_feature_stake_any_amount_of_eth') }}</p>
							<p class="package_gg_left_right_p2">{{ $t('package.stake_eth_no_minimum') }}<span>
								<!-- {{ $t('package.stake_now') }} -->
							</span>
							</p>
						</div>
					</div>
					<div class="d-none d-md-block">
						<!-- <div class="package_gg_right">
							<div>{{ $t('package.advertise_with_us') }}</div>
							<img src="../../static/icon_xiaoxi@2x.png" alt="">
						</div> -->
					</div>
				</div>
				<div style="width: 100%;display: table;">
					<b-row class="mb-4" style="margin: 0;width: 100%;">
						<b-col md="4" style="padding: 0;" class="d-block d-sm-none">
							<div class="package_net_sm">
								<p class="package_net_tit d-none d-md-block">{{ $t('package.network') }}</p>
								<div class="package_net_one" @click="choseneting()">
									<div>
										<p class="package_net_one_p1">{{rpcListDefaul.name}}</p>
										<p class="package_net_one_p2">{{ $t('package.block_height') }}{{blockNumber}}</p>
									</div>
									<img :src="rpcListDefaul.img" alt="">
								</div>
							</div>
						</b-col>
						<b-col md="8" style="padding: 0;">
							<div class="package_tokens d-none d-md-block">
								<!-- 无代币  显示 -->
								<!-- <div class="package_tokens_mid" v-if="tokenList.length == 0">
									<img src="../../static/pic_wddblb_kong@2x.png" alt="">
									<div class="package_tokens_content">
										<p class="package_tokens_content_tit">我的代币列表是空的</p>
										<div class="package_tokens_content_add">
											<img src="../../static/icon_tianjia@2x.png" alt="">
											<p>购买 ERC20 代币</p>
										</div>
										<div class="package_tokens_content_add" @click="pageShowAdd()">
											<img src="../../static/icon_tianjia@2x.png" alt="">
											<p>添加自定义令牌</p>
										</div>
									</div>
								</div> -->
								<!-- 有代币  显示 -->
								<div class="package_tokens_mid">
									<div class="package_tokens_mid_mid">
										<div style="display: flex;justify-content: space-between;align-items: center;">
											<p class="package_tokens_content_tit">{{ $t('package.total_assets') }}$ {{allPrice}}</p>
											<div class="package_tokens_content_add" style="margin:0;"
												@click="pageShowAdd()">
												<img src="../../static/icon_tianjia@2x.png" alt="">
												<p>{{ $t('package.add_custom_token') }}</p>
											</div>
										</div>
										<b-table striped hover :items="items2" :fields="fields2" responsive="sm"
											thead-class="table-header2" tbody-tr-class="table-row2">
											<template #cell(symbol)="row">
												<div class="d-flex align-items-center daibi_name">
													<img :src="row.item.img" alt="Image">
													<p class="ml-2">{{ row.item.symbol }}</p>
												</div>
											</template>
											<template #cell(price)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p>$ {{ row.item.price }}</p>
												</div>
											</template>
											<!-- <template #cell(shizhi)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p>{{ row.item.shizhi }}</p>
												</div>
											</template>
											<template #cell(time)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p style="color: #F6386E;" v-if="row.item.isZd">-{{ row.item.time }}
													</p>
													<p style="color: #2DC48A;" v-else>+{{ row.item.time }}</p>
												</div>
											</template> -->
											<template #cell(balance)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p>{{ row.item.balance }}</p>
												</div>
											</template>
											<template #cell(actions)="row">
												<div class="d-flex align-items-center daibi_button">
													<p style="color: #2DC48A" @click="sendToken(row.item)">{{ $t('package.send') }}</p>
													<p style="color: #F6386E;margin-left:10px;"
														v-if="row.item.address != ''" @click="delToken(row.item)">{{ $t('package.delete') }}</p>
												</div>
											</template>
										</b-table>
									</div>
								</div>
							</div>
							<div class="package_tokens_sm d-block d-sm-none">
								<!-- 无代币  显示 -->
								<!-- <div class="package_tokens_mid" v-if="tokenList.length == 0">
									<img src="../../static/pic_wddblb_kong@2x.png" alt="">
									<div class="package_tokens_content">
										<p class="package_tokens_content_tit">我的代币列表是空的</p>
										<div class="package_tokens_content_add">
											<img src="../../static/icon_tianjia@2x.png" alt="">
											<p>购买 ERC20 代币</p>
										</div>
										<div class="package_tokens_content_add" @click="pageShowAdd()">
											<img src="../../static/icon_tianjia@2x.png" alt="">
											<p>添加自定义令牌</p>
										</div>
									</div>
								</div> -->
								<!-- 有代币  显示 -->
								<div class="package_tokens_mid">
									<div class="package_tokens_mid_mid">
										<div style="display: flex;justify-content: space-between;align-items: center;">
											<p class="package_tokens_content_tit">{{ $t('package.total_assets') }}$ {{allPrice}}</p>
											<div class="package_tokens_content_add" style="margin:0;"
												@click="pageShowAdd()">
												<img src="../../static/icon_tianjia@2x.png" alt="">
												<p>{{ $t('package.add_custom_token') }}</p>
											</div>
										</div>
										<b-table striped hover :items="items2" :fields="fields2" responsive="sm"
											thead-class="table-header2" tbody-tr-class="table-row2">
											<template #cell(daibi)="row">
												<div class="d-flex align-items-center daibi_name">
													<img src="../../static/icon_mewzceth_ytf@2x.png" alt="Image">
													<p class="ml-2">{{ row.item.daibi }}</p>
												</div>
											</template>
											<template #cell(jiage)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p>{{ row.item.jiage }}</p>
												</div>
											</template>
											<template #cell(shizhi)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p>{{ row.item.shizhi }}</p>
												</div>
											</template>
											<template #cell(time)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p style="color: #F6386E;" v-if="row.item.isZd">-{{ row.item.time }}
													</p>
													<p style="color: #2DC48A;" v-else>+{{ row.item.time }}</p>
												</div>
											</template>
											<template #cell(chiyou)="row">
												<div class="d-flex align-items-center daibi_jiage">
													<p>{{ row.item.chiyou }}</p>
												</div>
											</template>
											<template #cell(actions)="row">
												<div class="d-flex align-items-center daibi_button">
													<p style="color: #2DC48A" @click="sendToken(row.item)">{{ $t('package.send') }}</p>
													<p style="color: #F6386E;margin-left:10px;"
														v-if="row.item.address != ''" @click="delToken(row.item)">{{ $t('package.delete') }}</p>
												</div>
											</template>
										</b-table>
									</div>
								</div>
							</div>
						</b-col>
						<b-col md="4" style="padding: 0;" class="d-none d-md-block">
							<div class="package_net">
								<p class="package_net_tit">{{ $t('package.network') }}</p>
								<div class="package_net_one" @click="choseneting()">
									<div>
										<p class="package_net_one_p1">{{rpcListDefaul.name}}</p>
										<p class="package_net_one_p2">{{ $t('package.block_height') }}{{blockNumber}}</p>
									</div>
									<img :src="rpcListDefaul.img" alt="">
								</div>
							</div>
						</b-col>
					</b-row>
					<b-row style="margin: 0;width: 100%;">
						<b-col md="12" style="padding: 0;">
							<div class="package_record d-none d-md-block">
								<p class="package_record_tit">{{ $t('package.transaction_history') }}</p>
								<div style="display: table;width: 100%;" v-if="items.length">
									<b-table striped hover :items="paginatedItems" :fields="fields" responsive="sm"
										thead-class="table-header" tbody-tr-class="table-row">
										<template #cell(hash)="row">
											<div class="d-flex align-items-center" style="cursor: pointer;"
												@click="onRowClicked(row.item)">
												<img src="../../static/icon_tongguo@2x.png" alt="Image"
													class="table-image">
												<span class="ml-2">{{maskString(row.item.hash)}}</span>
											</div>
										</template>
										<template #cell(timeStamp)="row">
											<div class="d-flex align-items-center">
												<span class="ml-2">{{timeAgo(row.item.timeStamp*1000)}}</span>
											</div>
										</template>
										<template #cell(from)="row">
											<div class="d-flex align-items-center" style="cursor: pointer;"
												@click="onRowClicked2(row.item.from)">
												<span class="ml-2">{{maskString(row.item.from)}}</span>
											</div>
										</template>
										<template #cell(to)="row">
											<div class="d-flex align-items-center" style="cursor: pointer;"
												@click="onRowClicked2(row.item.to)">
												<div v-if="row.item.to != wallet.address" class="zhuanchuStyle">{{ $t('package.transfer_out') }}</div>
												<div v-if="row.item.to == wallet.address" class="zhuanruStyle">{{ $t('package.transfer_in') }}</div>
												<span class="ml-2">{{maskString(row.item.to)}}</span>
											</div>
										</template>
										<template #cell(value)="row">
											<div class="d-flex align-items-center">
												<span class="ml-2">{{getTranfsNumber(row.item.value)}}
													{{rpcListDefaul.symbol}}</span>
											</div>
										</template>
										<template #cell(gasPrice)="row">
											<div class="d-flex align-items-center">
												<span class="ml-2">{{getTranfsGas(row.item.gasUsed,row.item.gasPrice)}}
													{{rpcListDefaul.symbol}}</span>
											</div>
										</template>
									</b-table>
									<div style="display: flex;justify-content: flex-end;">
										<b-pagination v-model="currentPage" :total-rows="items.length"
											:per-page="perPage" aria-controls="my-table"></b-pagination>
									</div>
								</div>
								<div class="nolist" v-if="items.length == 0">
									<img src="../../static/nolist.png" alt="" />
									<p>{{ $t('package.no_transactions') }}~</p>
								</div>
							</div>
							<div class="package_record_sm d-block d-sm-none">
								<p class="package_record_tit">{{ $t('package.transaction_history') }}</p>
								<div style="width:calc(100vw - 40px);overflow-x: scroll;" v-if="items.length">
									<div style="width: 1000px;">
										<b-table striped hover :items="paginatedItems" :fields="fields"
											thead-class="table-header" tbody-tr-class="table-row">
											<template #cell(hash)="row">
												<div class="d-flex align-items-center" @click="onRowClicked(row.item)">
													<img src="../../static/icon_tongguo@2x.png" alt="Image"
														class="table-image">
													<span class="ml-2">{{maskString(row.item.hash)}}</span>
												</div>
											</template>
											<template #cell(timeStamp)="row">
												<div class="d-flex align-items-center">
													<span class="ml-2">{{timeAgo(row.item.timeStamp*1000)}}</span>
												</div>
											</template>
											<template #cell(from)="row">
												<div class="d-flex align-items-center" style="cursor: pointer;"
													@click="onRowClicked2(row.item.from)">
													<span class="ml-2">{{maskString(row.item.from)}}</span>
												</div>
											</template>
											<template #cell(to)="row">
												<div class="d-flex align-items-center" style="cursor: pointer;"
													@click="onRowClicked2(row.item.to)">
													<div v-if="row.item.to != wallet.address" class="zhuanchuStyle">{{ $t('package.transfer_out') }}
													</div>
													<div v-if="row.item.to == wallet.address" class="zhuanruStyle">{{ $t('package.transfer_in') }}
													</div>
													<span class="ml-2">{{maskString(row.item.to)}}</span>
												</div>
											</template>
											<template #cell(value)="row">
												<div class="d-flex align-items-center">
													<span class="ml-2">{{getTranfsNumber(row.item.value)}}
														{{rpcListDefaul.symbol}}</span>
												</div>
											</template>
											<template #cell(gasPrice)="row">
												<div class="d-flex align-items-center">
													<span
														class="ml-2">{{getTranfsGas(row.item.gasUsed,row.item.gasPrice)}}
														{{rpcListDefaul.symbol}}</span>
												</div>
											</template>
										</b-table>
									</div>
								</div>
								<div style="display: flex;justify-content:center;" v-if="items.length">
									<b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage"
										aria-controls="my-table"></b-pagination>
								</div>
								<div class="nolist" v-if="items.length == 0">
									<img src="../../static/nolist.png" alt="" />
									<p>{{ $t('package.no_transactions') }}~</p>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</b-col>
		</b-row>
		<!-- 选择网络 -->
		<chose-net :showPage="showPage" @updateShowPage="updateShowPage" @updateNet="updateNet"></chose-net>
		<!-- 我的钱包二维码 -->
		<main-erweima :showErweiam="showErweiam" @updateErweima="updateShowPage"></main-erweima>
		<!-- 我的纸钱包 -->
		<page-package :pageShow="pageShow" @updatePageshow="updateShowPage"></page-package>
		<!-- 设置 -->
		<page-set :pageSet="pageSet" @updatePageSet="updateShowPage"></page-set>
		<!-- 添加代币 -->
		<add-token :showAdd="showAdd" @updateShowAdd="updateShowPage" @updateToken="updateToken"></add-token>
		<div style="position: fixed;width: 100%;height:100%;background-color: rgba(0, 0, 0, 0.2);top:0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 999999999999;"
			v-if="isLoading">
			<b-spinner v-if="isLoading" label="Loading..." style="width: 3rem; height: 3rem;"
				variant="primary"></b-spinner>
		</div>
		<b-modal id="modal-center" centered :title="$t('send.line3')" :ok-title="$t('send.line4')" :cancel-title="$t('cancel')" @ok="logOutSure()">
			<p class="my-4">{{ $t('package.are_you_sure_logout') }}</p>
		</b-modal>
		<b-modal id="modal-del" centered :title="$t('send.line3')" :ok-title="$t('send.line4')" :cancel-title="$t('cancel')" @ok="sureDel()">
			<p class="my-4">{{ $t('package.are_you_sure_delete_token') }}</p>
		</b-modal>
	</div>
</template>

<script>
	import axios from 'axios';
	import ChoseNet from '@/components/choseNet.vue';
	import MainErweima from '@/components/main.vue';
	import PagePackage from '@/components/pagePackage.vue';
	import PageSet from '@/components/set.vue';
	import AddToken from '@/components/addToken.vue';
	import jsonData from '../../static/rpc.json';
	import abiData from '../../static/abi.json';
	import {
		ethers
	} from 'ethers';
	var web3 = null;
	export default {
		name: "MyPackage",
		components: {
			ChoseNet,
			MainErweima,
			PagePackage,
			PageSet,
			AddToken
		},
		data() {
			return {
				showall: false,
				showSlider: false,
				items: [],
				fields: [{
						key: 'hash',
						label: this.$t('package.tx_hash')
					},
					{
						key: 'timeStamp',
						label: this.$t('package.transaction_time')
					},
					{
						key: 'from',
						label: this.$t('package.from')
					},
					{
						key: 'to',
						label: this.$t('package.to')
					},
					{
						key: 'value',
						label: this.$t('package.amount')
					},
					{
						key: 'gasPrice',
						label: this.$t('package.fee')
					},
				],
				items2: [],
				fields2: [{
						key: 'symbol',
						label: this.$t('package.token')
					},
					// {
					// 	key: 'shizhi',
					// 	label: '市值'
					// },
					// {
					// 	key: 'time',
					// 	label: '24小时'
					// },
					{
						key: 'balance',
						label: this.$t('package.hold')
					},
					{
						key: 'price',
						label: this.$t('package.price')
					},
					{
						key: 'actions',
						label: ''
					}
				],
				currentPage: 1,
				perPage: 4,
				showPage: false,
				showErweiam: false,
				pageShow: false,
				pageSet: false,
				showAdd: false,
				isLoading: false,
				wallet: null,
				rpcListDefaul: null,
				rpcList: null,
				blockNumber: 0,
				balance: 0,
				tokenList: [],
				extendedTokenContractABI: null,
				tokenIconList: [{
						name: 'USDT',
						url: require('../../static/icon/usdt.png')
					},
					{
						name: 'DAI',
						url: require('../../static/icon/dai.png')
					},
					{
						name: 'UNI',
						url: require('../../static/icon/uni.png')
					},
					{
						name: 'BNB',
						url: require('../../static/icon/bnb.png')
					},
					{
						name: 'SHIB',
						url: require('../../static/icon/shi.png')
					},
					{
						name: 'USDC',
						url: require('../../static/icon/usdc.png')
					},
					{
						name: 'XRP',
						url: require('../../static/icon/xrp.png')
					},
				],
				allPrice:0,
				ethPrice:0,
				delItem:null
			};
		},
		computed: {
			paginatedItems() {
				const start = (this.currentPage - 1) * this.perPage;
				const end = start + this.perPage;
				return this.items.slice(start, end);
			}
		},
		created() {
			this.wallet = JSON.parse(localStorage.getItem("wallet"));
			// console.log(this.wallet)
			this.rpcListDefaul = JSON.parse(localStorage.getItem("rpc"));
			// console.log(this.rpcListDefaul)
			this.rpcList = jsonData.rpcList;
			// console.log('aa' + localStorage.getItem("tokens"))
			// if(localStorage.getItem("tokens") && localStorage.getItem("tokens") != null){}

			// console.log(this.$store.state.web3)
			this.extendedTokenContractABI = abiData.tokenContractABI;
			web3 = this.$store.state.web3;
			this.initAll();
			// var ethRequestUrl = 'https://api.ethplorer.io/getTokenInfo/0xb5c26476200d5665ee6f2ee155d72327043495a4?apiKey=EK-3wfck-EXTpbdU-umU1Y'
			// axios.post(ethRequestUrl)
			// 	.then(response => {
			// 		console.log(response)
			// 	})
			// 	.catch(error => {
			// 		console.error('There was an error!', error);
			// 	});
		},
		methods: {

checkImage(url,addr) {
      const img = new Image();
      img.onload = () => {
        // 图片加载成功，地址有效
		console.log(this.imageValidss);  
		this.imageValidss = true;
for (var j = 0; j < this.items2.length; j++) {
if (this.items2[j].address == addr) {
	this.items2[j].img = (url)//this.tokenIconList[j].url;
	console.log('this.items2[j].img:', this.items2[j].img); 
}
}
localStorage.setItem(this.wallet.address + '_' + this.rpcListDefaul.chainId, JSON.stringify(this.items2));
this.$forceUpdate()
      };
      img.onerror = () => {
		console.log(this.imageValidss);  
        // 图片加载失败，地址无效
		this.imageValidss = false;

      };
      img.src = url; // 设置要检测的图片地址
return  this.imageValidss;
    },

			onRowClicked(item) {
				// window.location.href = this.rpcListDefaul.explorerUrl + '/tx/' + item.hash;
				window.open(this.rpcListDefaul.explorerUrl + '/tx/' + item.hash);
			},
			onRowClicked2(item) {
				// window.location.href = this.rpcListDefaul.explorerUrl + '/address/' + item;
				window.open(this.rpcListDefaul.explorerUrl + '/address/' + item)
			},
			timeAgo(timestamp) {
				const now = new Date();
				const past = new Date(timestamp);

				const diffYears = now.getFullYear() - past.getFullYear();
				const diffMonths = now.getMonth() - past.getMonth();
				const diffDays = now.getDate() - past.getDate();
				const diffHours = now.getHours() - past.getHours();
				const diffMinutes = now.getMinutes() - past.getMinutes();
				const diffSeconds = now.getSeconds() - past.getSeconds();

				if (diffYears > 0) {
					return `${diffYears} ${this.$t('package.years_ago')}`;
				} else if (diffMonths > 0) {
					return `${diffMonths} ${this.$t('package.months_ago')}`;
				} else if (diffDays > 0) {
					return `${diffDays} ${this.$t('package.days_ago')}`;
				} else if (diffHours > 0) {
					return `${diffHours} ${this.$t('package.hours_ago')}`;
				} else if (diffMinutes > 0) {
					return `${diffMinutes} ${this.$t('package.minutes_ago')}`;
				} else {
					return `${diffSeconds} ${this.$t('package.seconds_ago')}`;
				}
			},
			getTranfsNumber(valueInWei) {
				const valueInEth = parseFloat(valueInWei) / Math.pow(10, 18);
				return valueInEth;
			},
			getTranfsGas(gasUsed1, gasPrice1) {
				const gasUsed = parseInt(gasUsed1);
				const gasPrice = parseInt(gasPrice1);
				const totalGasFeeInWei = gasUsed * gasPrice;
				const totalGasFeeInEth = totalGasFeeInWei / Math.pow(10, 18);
				return totalGasFeeInEth;
			},
			getTranfsRecord() {
				var walletAddress = this.wallet.address;
				var requestUrl = 'https://api.etherscan.io/api?module=account&action=txlist&address=' + walletAddress +
					'&sort=desc&page=1&offset=10&apikey=CGXWP64D542PG8AVZHT4G93D4U12KU31NS'
				axios.get(requestUrl)
					.then(response => {
						this.items = response.data.result;
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			logout() {
				this.$bvModal.show('modal-center')
			},
			logOutSure() {
				this.$router.push('/')
			},
			initAll() {
				this.isLoading = true;
				this.getBlockHeight();
				if (localStorage.getItem(this.wallet.address + '_' + this.rpcListDefaul.chainId) == null) {
					Promise.all([this.getZichan(), this.getTranfsRecord()]).then((resList) => {
						console.log('resList--', resList);
						// var tokenList = [{
						// 	"name": this.rpcListDefaul.name,
						// 	"symbol": this.rpcListDefaul.symbol,
						// 	"img": this.rpcListDefaul.img,
						// 	"balance": this.balance,
						// 	"address": ''
						// }]
						// localStorage.setItem(this.wallet.address + '_' + this.rpcListDefaul.chainId, JSON
						// 	.stringify(tokenList));
						// this.items2 = tokenList;
						// this.$forceUpdate()
						this.isLoading = false;
						this.getZzichan();
					}).catch(error => {
						this.isLoading = false;
						console.log('error--', error);
					})
				} else {
					var tokenLocaList = JSON.parse(localStorage.getItem(this.wallet.address + '_' + this.rpcListDefaul
						.chainId));
					// console.log(tokenLocaList)

					Promise.all([this.getTokensNum(tokenLocaList), this.getTranfsRecord()]).then((resList) => {
						console.log('resList--', resList);
						this.isLoading = false;
						this.getZzichan();
					}).catch(error => {
						this.isLoading = false;
						console.log('error--', error);
					})
				}
			},
			async getZichan() {
				var ethRequestUrl =
					'https://api.ethplorer.io/getAddressInfo/'+ this.wallet.address +'?apiKey=EK-3wfck-EXTpbdU-umU1Y'
				await axios.get(ethRequestUrl)
					.then(response => {
						console.log(response)
						var tokenList = [{
							"name": this.rpcListDefaul.name,
							"symbol": this.rpcListDefaul.symbol,
							"img": this.rpcListDefaul.img,
							"balance": Number(response.data.ETH.balance).toFixed(6),
							"price": Number(response.data.ETH.price.rate).toFixed(6),
							"address": ''
						}]
						if(response.data.tokens){
							for (var i = 0; i < response.data.tokens.length; i++) {
								var obj = {
									"name": response.data.tokens[i].tokenInfo.name,
									"symbol": response.data.tokens[i].tokenInfo.symbol,
									"img": require('../../static/icon/defalu.png'),
									"balance": (Number(response.data.tokens[i].balance) / (10 ** Number(response.data
										.tokens[i].tokenInfo.decimals))).toFixed(6),
									"price": response.data.tokens[i].tokenInfo.price ? Number(response.data.tokens[i]
										.tokenInfo.price.rate).toFixed(6) : 0,
									"address": response.data.tokens[i].tokenInfo.address
								}

			const ul1= 'https://www.myicewallet.com/icons/'+response.data.tokens[i].tokenInfo.symbol+'.png'
			this.checkImage(ul1,response.data.tokens[i].tokenInfo.address)

								for (var j = 0; j < this.tokenIconList.length; j++) {
									if (this.tokenIconList[j].name == response.data.tokens[i].tokenInfo.symbol) {
										obj.img = this.tokenIconList[j].url;
									}
								}
								tokenList.push(obj)
							}
						}
						localStorage.setItem(this.wallet.address + '_' + this.rpcListDefaul.chainId, JSON
							.stringify(tokenList));
						this.items2 = tokenList;
						this.$forceUpdate()
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			async getTokensNum(tokenLocaList) {
				console.log(this.rpcListDefaul.rpcUrl)
				// 获取主币余额
				var provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
				// 获取钱包地址的余额
				const balanceBigNumber = await provider.getBalance(this.wallet.address);
				// 将余额转换为以太单位（ETH 或 BNB）
				const balance = ethers.utils.formatEther(balanceBigNumber);
				this.balance = balance;
				tokenLocaList[0].balance = Number(balance).toFixed(6);
				if (tokenLocaList.length == 1) {
					this.items2 = tokenLocaList;
					this.isLoading = false;
					this.$forceUpdate()
				} else {
					for (var i = 1; i < tokenLocaList.length; i++) {
						const extendedContract = new web3.eth.Contract(this.extendedTokenContractABI, tokenLocaList[i]
							.address);
						try {
							const balance = await extendedContract.methods.balanceOf(this.wallet.address).call();
							const decimals = await extendedContract.methods.decimals().call();
							tokenLocaList[i].balance = (balance / (10 ** decimals)).toFixed(6);
						} catch (error) {
							console.error(`Error fetching balance for ${tokenLocaList[i].address}:`, error);
						}
					}
					this.items2 = tokenLocaList;
					this.isLoading = false;
					this.$forceUpdate()
				}
			},
			getZzichan(){
				var all = 0;
				for(var i=0;i<this.items2.length;i++){
					all += Number((Number(this.items2[i].balance)*Number(this.items2[i].price)).toFixed(6))
				}
				this.allPrice = all.toFixed(6);
				this.ethPrice =  Number((Number(this.items2[0].balance)*Number(this.items2[0].price)).toFixed(6));
			},
			// async copyText(data) {
			// 	try {
			// 		const textToCopy = data;
			// 		await navigator.clipboard.writeText(textToCopy);
			// 		this.$bvToast.toast("复制成功", {
			// 			title: `提示`,
			// 			variant: "success",
			// 			solid: true,
			// 		});
			// 	} catch (err) {
			// 		console.error("复制失败", err);
			// 	}
			// },
			onCopy(e) {
				console.log('复制成功！' + e)
				this.$bvToast.toast(this.$t('copy_success'), {
					title:this.$t('tip'),
					variant: "success",
					solid: true,
				});
			},
			onError(e) {
				console.log('复制失败！' + e)
			},
			maskString(str) {
				if (str.length <= 12) return str; // 如果长度小于12，则不需要遮罩
				return str.slice(0, 6) + '...' + str.slice(-6); // 截取前6和后6个字符，中间用三个点代替
			},
			// 获取主币数量
			async getEthBalance(isLoad) {
				if (isLoad == 1) {
					this.isLoading = true;
				}
				// 获取主币余额
				this.provider = new ethers.providers.JsonRpcProvider(this.rpcListDefaul.rpcUrl);
				// 获取钱包地址的余额
				const balanceBigNumber = await this.provider.getBalance(this.wallet.address);
				// 将余额转换为以太单位（ETH 或 BNB）
				const balance = ethers.utils.formatEther(balanceBigNumber);
				this.balance = balance;
				// this.isLoading = false;
			},
			async getBlockHeight(isLoad) {
				try {
					if (isLoad == 1) {
						this.isLoading = true;
					}
					const blockNumber = await web3.eth.getBlockNumber();
					this.blockNumber = blockNumber;
					// this.isLoading = false;
				} catch (error) {
					console.error('Error fetching block height:', error);
					this.$bvToast.toast(this.$t('package.failed_to_get_height') + error, {
						title: this.$t('tip'),
						autoHideDelay: 5000, // 显示时间为5000毫秒，即5秒
						variant: 'danger',
						solid: true,
						toaster: 'b-toaster-top-right', // 设置 Toast 显示的位置
						appendToast: true // 每次显示新的 Toast 而不是替换旧的
					});
				}
			},
			updateNet(val) {
				web3 = this.$store.state.web3;
				this.rpcListDefaul = JSON.parse(val);
				// location.reload();
				// setTimeout(() => {
				this.initAll();
				// },1000)

				// this.getBlockHeight(1);
			},
			updateToken() {
				var tokenLocaList = JSON.parse(localStorage.getItem(this.wallet.address + '_' + this.rpcListDefaul
					.chainId));
				this.items2 = tokenLocaList;
				this.getZzichan();
				this.$forceUpdate()
			},
			viewDetails(item) {
				alert(`Viewing details for ${item.name}`);
			},
			deleteItem(item) {
				this.items = this.items.filter(i => i.id !== item.id);
				alert(`Deleted ${item.name}`);
			},
			toggleNav() {
				this.showSlider = true;
			},
			xiasi() {
				this.showall = !this.showall;
			},
			choseneting() {
				this.showPage = true;
			},
			showErweimaFun() {
				this.showErweiam = true;
			},
			pageShowFun() {
				this.pageShow = true;
			},
			pageSteShow() {
				this.pageSet = true;
			},
			pageShowAdd() {
				this.showAdd = true;
			},
			updateShowPage(newValue) {
				this.showPage = newValue;
				this.showErweiam = newValue;
				this.pageShow = newValue;
				this.pageSet = newValue;
				this.showAdd = newValue;
			},
			goUrl() {
				this.$router.push({
					path: '/Send'
				})
			},
			sendToken(item) {
				this.$router.push({
					path: '/Send',
					query: {
						toekninfo: JSON.stringify(item)
					}
				})
			},
			delToken(item){
				this.delItem = item;
				this.$bvModal.show('modal-del')
			},
			sureDel(){
				for(var i=0;i<this.items2.length;i++){
					if(this.items2[i].address == this.delItem.address){
						this.items2.splice(i, 1);
					}
				}
				localStorage.setItem(this.wallet.address + '_' + this.rpcListDefaul.chainId, JSON
					.stringify(this.items2));
				this.$forceUpdate();
			}
		}
	};
</script>
<style lang="less">
	.package_record {
		.table-header th {
			color: #27183F;
		}

		.table-row td {
			line-height: 40px;
			color: #27183F;
			font-size: calc(12px + 0.2vw);
		}
	}

	.table-header2 th {
		color: #27183F !important;
		font-size: calc(8px + 0.3vw) !important;
	}

	.table-row2 td {
		/* border:none; */
	}
</style>
<style scoped lang="less">
	.table-image {
		width: 14px;
		height: 14px;
		object-fit: cover;
		border-radius: 50%;
	}

	.ml-2 {
		margin-left: 0.5rem;
	}

	.slider_row {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 0;
		margin: 0;
		z-index: 2;
		height: 100vh;
		overflow-y: scroll;
	}

	/* Slide transition for mobile view */
	.slide-enter-active,
	.slide-leave-active {
		transition: transform 0.3s ease, opacity 0.3s ease;
	}

	.slide-enter,
	.slide-leave-to {
		transform: translateX(-100%);
	}

	@media (max-width: 768px) {
		.sidebar {
			transform: translateX(-100%);
		}

		.slide-enter-to {
			transform: translateX(0);
		}

		.slide-leave {
			transform: translateX(-100%);
		}
	}

	.package_tokens_content_add {
		display: flex;
		align-items: center;
		margin-top: calc(10px + 0.6vw);
		cursor: pointer;

		img {
			width: calc(4px + 0.4vw) !important;
		}

		p {
			color: #0091FE;
			font-size: calc(4px + 0.6vw);
			margin-left: 5px;
		}
	}

	.phone_nav {
		height: 60px;
		background: rgba(255, 255, 255, 0.5);
		box-shadow: 0px 4px 4px 0px rgba(158, 188, 254, 0.2);
		border-radius: 0px 0px 24px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;

		.phone_nav_logo {
			width: 118px;
			height: 32px;
		}

		.phone_nav_menu {
			width: 56px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #1A183F;
			border-radius: 5px;

			img {
				width: 26px;
				height: 26px;
			}
		}
	}

	.add_height {
		min-height: 100vh;
		position: relative;
		height: 100%;
	}

	.package_left {
		width: 100%;
		background-color: #ffffff;

		.package_left_logo {
			padding: calc(10px + 1vw);

			.package_left_logo_logo {
				width: 118px;
				height: 32px;
			}

			.package_left_logoInfo {
				display: table;
				position: relative;
				border-radius: 10px;
				margin-top: calc(10px + 1vw);

				.package_left_logoInfo_bgimg {
					width: 100%;
				}

				.package_left_logoInfo_mid {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					padding: calc(10px + 1vw);
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.package_left_logoInfo_mid_tit {
						display: flex;
						align-items: center;
						cursor: pointer;

						p {
							font-size: calc(8px + 0.5vw);
							color: #ffffff;
							font-weight: 600;
						}

						img {
							width: calc(8px + 0.5vw);
							margin-left: 5px;
						}
					}

					.package_left_logoInfo_mid_address {
						font-size: calc(8px + 0.3vw);
						color: #ffffff;
					}

					.package_left_logoInfo_mid_zichan {
						font-size: calc(12px + 1vw);
						color: #ffffff;
						font-weight: 600;
					}

					.package_left_logoInfo_mid_bot {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							font-size: calc(8px + 0.4vw);
							color: #ffffff;
						}

						img {
							width: calc(12px + 1vw);
							margin-left: 4px;
							cursor: pointer;
						}
					}
				}
			}
		}

		.package_left_nav {
			width: 100%;
			padding-bottom: calc(5px + 0.2vw);
			border-bottom: 1px solid #E3E5ED;

			.package_left_nav_one {
				width: 100%;
				padding-left: calc(10px + 1vw);
				display: flex;
				align-items: center;
				height: calc(30px + 1vw);
				cursor: pointer;

				img {
					width: calc(12px + 0.5vw);
					height: calc(12px + 0.5vw);
				}

				p {
					color: #1A183F;
					font-size: calc(10px + 0.4vw);
					margin-left: calc(10px + 0.3vw);
				}
			}

			.package_left_nav_two {
				width: 100%;
				padding: 0 calc(10px + 1vw);
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: calc(30px + 1vw);

				img {
					width: calc(12px + 0.5vw);
					height: calc(12px + 0.5vw);
				}

				p {
					color: #1A183F;
					font-size: calc(10px + 0.4vw);
				}
			}
		}
	}

	.package_gg {
		width: 100%;
		padding: calc(6px + 0.5vw);
		display: flex;
		align-items: center;
		justify-content: space-between;

		.package_gg_left {
			display: flex;
			align-items: center;

			img {
				width: calc(20px + 1vw);
			}

			.package_gg_left_right {
				margin-left: calc(3px + 1vw);

				.package_gg_left_right_p1 {
					color: #1A183F;
					font-size: calc(8px + 0.4vw);
					font-weight: 600;
				}

				.package_gg_left_right_p2 {
					color: #757C8B;
					font-size: calc(8px + 0.4vw);

					span {
						color: #0091FE;
					}
				}
			}

			.package_gg_left_right_sm {
				.package_gg_left_right_p1 {
					color: #1A183F;
					font-size: 12px;
					font-weight: 600;
					margin-top: 10px;
				}

				.package_gg_left_right_p2 {
					color: #757C8B;
					font-size: 12px;
					margin-top: 5px;

					span {
						color: #0091FE;
					}
				}
			}
		}

		.package_gg_right {
			display: flex;
			align-items: center;

			div {
				background-color: #0091FE;
				font-size: calc(12px + 0.2vw);
				color: #ffffff;
				padding: calc(5px + 0.2vw) calc(6px + 1vw);
				display: table;
				border-radius: 8px;
			}

			img {
				width: calc(10px + 1vw);
				margin-left: calc(3px + 1vw);
			}
		}
	}

	.package_tokens {
		width: 100%;
		padding-right: calc(6px + 0.5vw);

		.package_tokens_mid {
			display: table;
			position: relative;
			width: 100%;

			img {
				width: 100%;
			}

			.package_tokens_content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: calc(6px + 1vw);

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(10px + 0.6vw);
					font-weight: 600;
				}


			}

			.package_tokens_mid_mid {
				width: 100%;
				padding: calc(6px + 1vw);
				background-color: #ffffff;
				border-radius: 10px;

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(10px + 0.6vw);
					font-weight: 600;
				}

				.daibi_name {
					img {
						width: calc(10px + 1vw);
					}

					p {
						color: #27183F;
						font-size: calc(8px + 0.4vw);
					}
				}

				.daibi_jiage {
					color: #27183F;
					font-size: calc(8px + 0.4vw);
					line-height: calc(10px + 1vw);
				}

				.daibi_button {
					p {
						font-size: calc(8px + 0.2vw);
						line-height: calc(10px + 1vw);
						cursor: pointer;
					}
				}
			}
		}
	}

	.package_tokens_sm {
		width: 100%;
		margin-top: 15px;

		.package_tokens_mid {
			display: table;
			position: relative;
			width: 100%;

			img {
				width: 100%;
			}

			.package_tokens_content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: calc(6px + 1vw);

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(12px + 0.6vw);
					font-weight: 600;
				}

				.package_tokens_content_add {
					display: flex;
					align-items: center;
					margin-top: calc(10px + 0.6vw);
					cursor: pointer;

					img {
						width: 10px;
					}

					p {
						color: #0091FE;
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}

			.package_tokens_mid_mid {
				width: 100%;
				padding: calc(6px + 1vw);
				background-color: #ffffff;
				border-radius: 10px;

				.package_tokens_content_tit {
					color: #1A183F;
					font-size: calc(10px + 0.6vw);
					font-weight: 600;
				}

				.daibi_name {
					img {
						width: calc(10px + 1vw);
					}

					p {
						color: #27183F;
						font-size: calc(8px + 0.4vw);
					}
				}

				.daibi_jiage {
					color: #27183F;
					font-size: calc(8px + 0.4vw);
					line-height: calc(10px + 1vw);
				}

				.daibi_button {
					p {
						font-size: calc(8px + 0.2vw);
						line-height: calc(10px + 1vw);
						cursor: pointer;
					}
				}
			}
		}
	}

	.package_net {
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(6px + 1vw);

		.package_net_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}

		.package_net_one {
			width: 100%;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F3F5FB;
			border-radius: 4px;
			cursor: pointer;

			img {
				width: calc(35px + 1vw);
			}

			.package_net_one_p1 {
				color: #1A183F;
				font-size: calc(5px + 0.6vw);
				font-weight: 600;
			}

			.package_net_one_p2 {
				color: #757C8B;
				font-size: calc(3px + 0.6vw);
				margin-top: calc(1px + 0.3vw);
			}
		}
	}

	.package_net_sm {
		width: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: calc(6px + 1vw);

		.package_net_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}

		.package_net_one {
			width: 100%;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F3F5FB;
			border-radius: 4px;

			img {
				width: calc(35px + 1vw);
			}

			.package_net_one_p1 {
				color: #1A183F;
				font-size: 14px;
				font-weight: 600;
			}

			.package_net_one_p2 {
				color: #757C8B;
				font-size: 12px;
				margin-top: 4px;
			}
		}
	}

	.package_record {
		width: 100%;
		background-color: #ffffff;
		padding: calc(6px + 1vw) calc(6px + 1vw) 0;
		border-radius: 10px;

		.package_record_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}
	}

	.nolist {
		width: 100%;

		img {
			width: calc(80px + 1vw);
			margin: 0 auto;
			display: block;
		}

		p {
			color: #1A183F;
			font-size: calc(10px + 0.3vw);
			padding: calc(6px + 1vw) 0;
			text-align: center;
		}
	}

	.package_record_sm {
		width: 100%;
		background-color: #ffffff;
		padding: calc(6px + 1vw) calc(6px + 1vw) 0;
		border-radius: 10px;
		margin-bottom: 15px;

		.package_record_tit {
			color: #1A183F;
			font-size: calc(10px + 0.6vw);
			font-weight: 600;
			margin-bottom: 15px;
		}
	}

	.zhuanchuStyle {
		height: 24px;
		width: 40px;
		background-color: #ffb400;
		font-size: 12px;
		color: #ffffff;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.zhuanruStyle {
		height: 24px;
		width: 40px;
		background-color: #17c671;
		font-size: 12px;
		color: #ffffff;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>