import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/pages/index.vue';
import Help from '@/pages/help.vue';
import HelpList from '@/pages/help_list.vue';
import HelpDetail from '@/pages/help_detail.vue';
import Create from '@/pages/create.vue';
import Package from '@/pages/package.vue';
import Send from '@/pages/send.vue';
import Home from '@/pages/home.vue'

Vue.use(Router);

export default new Router({
	base: '/',
	mode: 'history', // 使用 HTML5 历史模式
	routes: [
		// {
		// 	path: '/',
		// 	name: 'Index',
		// 	component: Index,
		// },
		{
            path: '/',
			name: 'Home',
			component: Home,
		},
		{
			path: '/Help',
			name: 'Help',
			component: Help,
		},
		{
			path: '/HelpList',
			name: 'HelpList',
			component: HelpList,
		},
		{
			path: '/HelpDetail',
			name: 'HelpDetail',
			component: HelpDetail,
		},
		{
			path: '/Create',
			name: 'Create',
			component: Create,
		},
		{
			path: '/Package',
			name: 'Package',
			component: Package,
		},
		{
			path: '/Send',
			name: 'Send',
			component: Send,
		}
	],
});