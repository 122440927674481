export default {
  ethPackage: "我的冰钱包",
  cancel: "取消",
  add: "添加",
  tip: "提示",
  input_password: "输入密码",
  please_enter_password: "请输入密码",
  back: "返回",
  copy_success: "复制成功",
  upload_keystore: "请上传Keystore文件并输入密码",
  no_mnemonic: "无助记词",
  file_parse_failed: "解析文件失败，请核对密码",
  wallet_address_parse_failed: "解析钱包地址失败，请确认私钥真实性！",
  wallet_generation_failed: "生成钱包失败",
  step_1: "1",
  step_2: "2",
  step_2_label: "步骤2",
  input_password_to_unlock: "输入您的密码解锁钱包",
  next_step: "下一步",
  Accessmywallet:'访问我的钱包',
  'Trusted by millions': '完全去中心化钱包',  
    'worldwide since 2015': '离线运行是最安全的方式',  
    'Store': '支持离线运行生成钱包',  
    'Ethereum': '支持以太坊所有生态',  
    'safely': '我的冰钱包社区',  
    'Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.': '我的冰钱包支持断网离线生成钱包，确保您的钱包是最安全的状态。',  
    'Stay in control': '请断网离线掌管您的钱包',  
    'We secure your': '我的冰钱包是完全去中心化钱包，我们无法为您掌管钱包，钱包唯一主人只有您自己，妥善保管您的钱包，只有断网离线脱离互联网掌管钱包是最安全的方式。',  
    'Buy, sell, and': '跨链币种',  
    'swap safely': '安全交换',  
    'Convert USD, EUR': '通过我们集成去中心化合作伙伴交换加密货币，数百种加密货币和法定货币可供兑换，无需依赖中心化交易所。',  
    'Easy asset': '以太坊生态系统',  
    'management': 'web3开发社区',  
    'Compare and secure the': '以太坊生态系统拥有整个 web3 中最大的开发社区，我的冰钱包是最值得信赖和久经考验的以太坊钱包。',  
    'How to secure your Ethereum wallet': '如何保护您的以太坊钱包',  
    'Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.': '断网离线生成钱包，脱离互联网保存钱包是最安全的方式，切勿点击可疑链接，因为它们可能是试图窃取您资产的钓鱼行为。请注意，我的冰钱包绝不会要求您提供任何钱包信息。如有任何疑问，请联系我们的支持团队。',  
    'Frequently asked questions': '常见问题',  
    'Supporting all ERC-20 tokens': '支持所有ERC-20代币',  
    'If it is on Ethereum': '我的冰钱包支持以太坊区块链所有代币，无需手动添加自定义代币。',  
    'Grab control of your future': '掌控自己的未来',  
    'Create a new wallet': '创建一个新钱包',  
    'Get better at crypto': '请提供我们更好的意见让我们变得更好',  
    'Level up your skills with security tips, industry insights, new and more!': '我们谦逊的接受您的意见，让我们变得更好！',  
    'Enter your email': '输入您的电子邮件',  
    'Sign me up!': '联系我们',  
    'Warning: Trading cryptocurrencies involves significant high risks.': '警告：不要把钱包密钥库、私钥、助记词暴露互联网上！',  
    'BTC Direct was rated a 4.5 out of 5 based on 10475 reviews': 'MyIceWallet根据15175条评论获得4.7/5的评分',  
    '© 2013 - 2024 - BTC Direct Europe B.V.': '© 2024 MyIceWallet. All rights reserved',  
    'Sitemap': '网站地图',  
    'Disclaimer': '免责声明',  
    'Terms of Service': '服务条款',  
    'Privacy Policy': '隐私政策',  
    'Fiat onramp': '常见问题',
    'Back up your wallet': '备份您的钱包',  
    'Never share your private key': '切勿分享您的私钥',  
    'Keep your wallet and device software up to date': '请勿到陌生电脑或手机运行您的钱包',  
    'Be cautious of phishing scams': '警惕网络钓鱼诈骗',  
    'What is a Ethereum wallet?': '什么是以太坊钱包？',  
    'Are Ethereum wallets free?': '以太坊钱包是免费的吗？',  
    'What is the safest Ethereum Wallet?': '什么是以太坊最安全的钱包？',  
    'Which app can I use to create my Ethereum wallet?': '我可以使用哪个应用程序来创建我的以太坊钱包？',
    'boots1': "通过创建多个安全备份来保护您的资产。我的冰钱包不控制或保留您的私钥副本，因此请将您的备份存放在安全的地方，私钥或助记词最好拿笔写在纸上或者打印，不要保存邮箱或云盘里！。",  
    'boots2': "您的私钥（也称为助记词）是钱包的主私钥。分享它将如同将资产的控制权交给其他人。请确保您的私钥保密，切勿在线或通过文本透露，以维护钱包的安全性和完整性。",  
    'boots3': "不可以使用陌生电脑或手机运行您的钱包，警惕陌生环境的病毒，以保护您的资产安全。",  
    'boots4': "对于要求您提供钱包信息的未经请求的消息或电子邮件，请始终保持警惕。验证来源，切勿点击可疑链接，因为它们可能是企图窃取您资产的钓鱼尝试。请注意，我的冰钱包 绝不会主动要求您提供私钥或助记词。如有任何疑问，请联系我们的支持团队。",  
    'boots5': "以太坊钱包是一种数字工具，允许您安全地购买、存储和管理以太坊。它使您能够发送和接收以太坊，并访问购买、出售、交换等功能。我的冰钱包是完全去中心化钱包，钱包文件与私钥，助记词可以导入以太坊官方客户端使用",  
    'boots6': "是的，我的冰钱包是免费的。但是如购买或转账以太坊等交易可能会产生网络费用。我的冰钱包为新手和经验丰富的加密货币爱好者提供了一个安全平台来管理他们的以太坊和其他加密货币。",  
    'boots7': "一个安全的以太坊钱包是让您完全控制私钥并提供有助于保护资产安全的功能的钱包。我的冰钱包 致力于通过提供安全的以太坊存放地来满足这些标准。您可以掌控私钥和助记词，确保只有您能访问您的资产。我的冰钱包与您同在。",  
    'boots8': "我的冰钱包是完全去中心化钱包，钱包文件与私钥助记词可以导入以太坊官方客户端钱包进行交互，无论您使用哪款以太坊钱包，请一定选择去中心化钱包！", 
  components: {
    add_custom_token: "添加自定义代币",
    enter_correct_address: "请输入正确的合约地址",
    select_network: "选择网络",
    enter_contract_addressm: "请输入合约地址",
    about_us: "关于我们",
    advertise_with_us: "与我们一起做广告",
    privacy: "隐私",
    terms: "条款",
    help_center: "帮助中心",
    frequently_asked_questions: "常问问题",
    join_community: "加入社区",
    buy_crypto: "购买加密货币",
    swap_tokens: "兑换代币",
    more_features: "更多功能",
    resources: "资源",
    products: "产品",
    access_my_wallet: "访问我的钱包",
    go_to: "前往",
    mew_mobile: "MEW移动",
    encrypt: "加密",
    ethereum_virtual_machine: "以太网虚拟机",
    select_software_wallet: "选择软件钱包",
    keystore: "密钥库",
    mnemonic: "助记词",
    private_key: "私钥",
    save_wallet_warning:
      "请你脱离网络保存你的钱包，多备份几份存在U盘里，私钥与助记词可以拿笔写在纸上，注意不要储存邮箱里，云盘里",
    access_wallet_with_keystore: "使用密钥库文件访问钱包",
    select_file: "选择文件",
    input_password: "输入密码",
    step_1: "步骤1",
    select_keystore_file: "选择您的密钥库文件",
    select_keystore_file_warning: "请选择解锁钱包的密钥库文件",
    not_recommended: "不建议",
    access_wallet: "访问钱包",
    access_wallet_with_mnemonic: "使用助记词访问钱包",
    input_phrase: "输入短语",
    address_and_network: "地址和网络",
    input_mnemonic: "输入您的助记词",
    input_mnemonic_order: "请按照正确的顺序输入您写下的助记词。",
    select_address_and_network: "选择地址和网络",
    address: "地址",
    amount: "数量",
    network: "网络",
    access_wallet_with_private_key: "使用私钥访问钱包",
    input_private_key: "输入您的私钥",
    please_enter_private_key: "请输入私钥",
  },
  mps: {
    my_public_address: "我的公共地址用于接收资金",
    receive_token_instruction:
      "要从另一个账户接收TOKEN，请将TOKEN从该账户发送到此地址。",
    copy: "复制",
    my_paper_wallet: "我的纸钱包",
    paper_wallet: "纸钱包",
    my_wallet_address: "我的钱包地址",
    my_private_key: "我的私钥",
    private_key_warning:
      "你可能会失去你的钱，如何你与任何人分享这个私钥！请将您的私钥放在安全的地方！",
    print: "打印",
    settings: "设置",
    wallet_address: "钱包地址",
    max_wallets: "您最多可以添加10个。",
    enter_wallet_address: "请输入钱包地址",
    confirm_add: "确认添加",
    currency_setting: "货币设定",
    usd: "美元",
    jpy: "日元",
    normal_priority: "正常优先级",
    higher_priority: "更高优先级",
    highest_priority: "最高优先级",
    fifteen_minutes: "15分钟",
    wallet_address_exists: "钱包地址已存在",
  },
  chdhl: {
    create_new_wallet: "创建新钱包",
    select_wallet_creation_method: "请选择创建新钱包的方法",
    already_have_wallet: "已经有钱包了？",
    access_wallet: "访问钱包",
    keystore_file: "密钥库文件",
    keystore_warning:
      "在线使用密钥库文件会使您的钱包更容易丢失资金。我们不建议使用这种方法创建钱包。",
    mnemonic_phrase: "助记词",
    mnemonic_warning:
      "在线使用助记词会使您的钱包更容易丢失资金。我们不建议使用这种方式创建钱包。",
    return_to_home: "返回首页",
    not_recommended: "不建议",
    offline_storage_advice:
      "请你脱离网络保存你的钱包，多备份几份存在U盘里，私钥与助记词可以拿笔写在纸上，注意不要储存邮箱里，云盘里!",
    enter_password: "输入密码",
    download_file: "下载文件",
    step_3: "3",
    reenter_password: "请再次输入密码",
    create_wallet: "创建钱包",
    download_keystore_file: "下载密钥库文件",
    important_info_before_download: "下载密钥库文件之前需要了解的重要事项",
    dont_lose_it: "别弄丢了",
    be_careful: "小心点，丢了就找不回来了。",
    do_not_share: "不要分享",
    phishing_warning: "如果您在恶意的钓鱼网站上使用此文件，您的资金将被窃取。",
    make_a_backup: "做一个备份",
    protect_it: "保护它，就像它有一天可能会值几百万美元一样。",
    confirm_download: "确认下载",
    step_3s: "步骤3",
    well_done: "做的好！",
    ready_to_use:
      "你现在已经准备好利用以太坊所提供的一切，只能在脱机设置中使用Keystore文件访问。",
    create_another_wallet: "创建另一个钱包",
    write_down_phrase: "记下短语",
    verify_phrase: "验证短语",
    update: "更新",
    write_them_down: "把它们写下来",
    select_correct_words: "请根据数字选择正确的单词，并输入多余的单词。",
    verify: "验证",
    password_mismatch: "两次密码不一致",
    verification_failed: "验证失败",
    keystore_generation_failed: "生成Keystore文件失败",
  },
  help_detail: {
    search_articles: "搜索文章…",
    all_series: "全部系列",
    introduction: "介绍",
    overview: "简介",
    about_mew: "关于 MEW 的所有事物的介绍。",
    author: "作者",
    updated_week_ago: "一周前已更新",
    description:
      "是一个非托管的开源客户端界面，允许用户直接与以太坊区块链交互，完全控制他们的加密资产。MEW 可用作网页钱包、适用于 iOS 和 Android 的 MEW 钱包应用程序以及浏览器钱包扩展。",
    wallet_app: "钱包应用程序",
    mobile_wallet_guide: "使用我们的移动 MEW 钱包的指南",
    encryption: "加密",
    enkrypt_resources: "Enkrypt 网络浏览器扩展钱包的资源和指南。",
    security_privacy: "安全和隐私",
    wallet_security: "如何在 MEW 上保证你的钱包安全。",
    access_wallet: "访问钱包",
    access_methods: "如何使用支持的方法访问 MEW 上的钱包。",
    send_transaction: "发送交易",
    send_transaction_guide: "如何在 MEW 上发送交易",
    exchange: "交换",
    exchange_guide: "如何在我们的交换提供商提供的 MEW 上进行交换。",
    tokens: "代币",
    token_interaction: "如何与 MEW 上的代币进行交互。",
    decentralized_apps: "去中心化应用",
    dapp_interaction: "如何使用 MEW 连接并与 DApps 交互。",
    mew_offline: "MEW 离线",
    offline_usage:
      "如何在版本 5 上离线使用 MEW。MyEtherWallet 版本 6 尚未提供 MEW 离线功能。",
    networks_nodes: "网络和节点",
    connect_networks: "如何连接不同的网络和节点。",
  },
  help_list: {
    and: "和",
    other: "位其他",
    articles: "篇文章",
    return_to_basics: "回归基础：给以太坊初学者的",
    mew_tips: "条 MEW 建议",
    exchange_difference:
      "交易所（即 Coinbase、kraken、Gemini、Binance等）和 MyEtherWallet 之间有什么区别？",
    get_started: "立即开始",
    create_wallet: "如何使用 MEW 创建以太坊钱包",
    transfer_crypto: "将您的加密货币从币安转移到 Enkrypt 或 MEW 钱包",
    create_cold_wallet: "如何创建冷钱包（又称纸钱包）",
    buy_sell_crypto: "使用 MEW 投资组合购买和出售加密货币",
    what_is_non_custodial_wallet: "什么是非托管钱包？",
    check_balance: "是在以太坊区块链上检查你的余额",
    explore_deeply: "深入探索",
    crypto_terms: "以太坊/加密货币社区常用术语表",
    what_if_mew_fails: "如果 MEW 发生故障会发生什么情况？",
    does_mew_support_btc: "MEW 是否支持比特币（BTC）或其他货币？",
    what_is_eth_smart_contract: "什么是以太坊智能合约？",
    error_window_blocked: "错误：窗口被阻塞",
    ensure_unique_address: "确保 MEW 不会向不同的人提供相同的公共地址",
    submit_pull_request: "如何向 MEW 的 GitHub 提交 Pull 请求",
    does_mew_have_api: "MyEtherWallet 有 API 吗？",
    mew_api_request_limit: "MEW API 对我可以发出的请求数量有限制吗？",
    use_mew_as_pwa: "将 MEW 用作渐进式 Web 应用程序（PWA）或 Chrome 应用程序",
  },
  index: {
    mew_team_advice: "MyEtherWallet 团队的建议和解答",
    most_reputable_wallet: "我的冰钱包支持以太坊生态所有ERC20代币储存与发送。",
    create_new_wallet: "创建新的钱包",
    or: "或",
    access_my_wallet: "访问我的钱包",
    our_products_make_crypto_easier: "您独有掌控自己的密钥库，助记词，私钥",
    available_on_mobile_and_desktop: "请你脱离网络保存你的钱包，多备份几份存在U盘里，私钥与助记词可以拿笔写在纸上，注意不要储存邮箱里，云盘里!",
    mew_mobile_app: "MEW移动应用程序",
    stake_swap_manage_crypto: "随时随地质押、交换和管理您的加密货币。",
    security_like_hardware_wallet:
      "使用可与硬件钱包媲美的安全性保护您的移动加密货币。",
    powerful_nft_management: "强大的NFT管理、链上浏览器等。",
    want_to_enter_crypto: "想要进入加密货币领域？",
    mew_is_best_choice: "MEW就是您的最佳选择。",
    ethereum_ecosystem: "以太坊生态系统拥有整个web3中最大的开发社区。",
    trusted_ethereum_wallet: "MEW是最值得信赖且久经考验的以太坊钱包。",
    not_investment_advice: "并非投资建议。所有决策均由用户自行承担风险。",
    truly_own_your_crypto: "真正拥有你的加密资产。",
    self_custody_wallet:
      "MEW是一个自我保管钱包，这就意味着你持有自己的钥匙，除了你之外的任何人（甚至MEW团队也不行）都无法访问你的加密货币。",
    buy_store_send_swap_tokens: "购买、存储、发送和交换代币",
    store_tokens: "储存代币",
    buy_sell: "买卖",
    swap: "交换",
    mew_supports_eth_and_erc20: "MEW支持ETH和所有ERC-20代币！",
    get_tokens: "拿到令牌",
    make_crypto_yours: "让你的加密货币成为加密货币。",
    stake_your_eth: "质押你的ETH并获得以太坊区块链奖励。",
    be_your_own_bank: "成为自己的银行",
    crypto_as_banking:
      "与借出您的资金的大型金融银行不同，在加密货币领域，您就是银行。通过帮助自动保护以太坊的安全来获得奖励。",
    staking_made_easy: "质押变得简单",
    easy_staking_with_mew:
      "在MEW，我们让您可以轻松地从您的钱包中质押你的加密货币并立即开始获得奖励。",
    start_staking_now: "立即开始质押",
    staking: "质押",
    no_minimum_liquidity: "无最低流动性质押要求",
    annual_rate_up_to: "年利率高达",
    powerful_wallet: "一款功能强大的钱包，满足您所有的加密需求。",
    store_all_your_nfts:
      "您所有的NFT。在移动设备或桌面上，将来自不同链的所有NFT安全地保存在一个地方。",
    access_web3_apps:
      "访问web3应用。使用Enkrypt在桌面上使用您喜爱的web3应用，或使用MEW移动应用在移动设备上使用您喜爱的web3应用。",
    multi_chain_access:
      "多条链。访问去中心化网络并在流行网络之间架起资产桥梁。",
    why_mew_is_good_choice: "为什么说MEW是一个不错的选择？",
    crypto_expert_since_2015: "自2015年以来一直是加密货币老手",
    first_trusted_wallet: "MEW是世界上第一个也是最受信赖的以太坊和L2钱包之一。",
    private: "私人的",
    no_tracking: "我们不会追踪任何个人身份信息、账户地址或资产余额。",
    hardware_wallet_support: "硬件钱包支持",
    support_major_hardware_wallets:
      "我们支持所有主要的硬件钱包，包括MEW网络产品组合中的Ledger和Trezor以及Enkrypt。",
    secure_transparent: "安全透明",
    independent_audits:
      "在HackenProof和之前的HackerOne的Bug Bounty计划中接受独立审计。",
    self_custody: "自行监护",
    you_control_your_assets: "只有您才能控制您的资产。您的密钥，您的加密货币。",
    easy_to_use: "便于使用",
    start_in_minutes: "几分钟内即可开始，无需任何先验知识。",
    arm_yourself_with_knowledge: "用知识武装自己。",
    new_to_crypto: "首次接触加密货币？探索MEWtopia！",
    free_resources: "利用我们的免费资源库了解如何充分利用以太坊！",
    help_center: "帮助中心",
    faq: "经常问的问题",
    common_crypto_questions: "关于加密货币的最常见问题",
    chat_with_support: "与支持人员聊天",
    get_human_help: "获得真实人类的帮助",
    create_new_wallet: "创建新的钱包",
    ethereum_eth: "以太坊(ETH)",
    ondo: "翁多(ONDO)",
    unibot: "联机机器人(UNIBOT)",
    busd: "币安美元(BUSD)",
    icx: "图标(ICX)",
    grt: "图表(GRT)",
    aurora: "极光(AURORA)",
    root: "根网络(ROOT)",
    lime: "IME实验室(LIME)",
  },
  package: {
    ethereum: "以太坊",
    buy_store_send: "购买、存储、发送",
    usd: "美元",
    swap_tokens: "和交换代币",
    mew_supports_eth_and_erc20: "MEW 支持 ETH 和所有 ERC-20",
    make_your_crypto_yours: "让你的加密货币成为加密货币。",
    tokens: "代币！",
    cryptocurrency: "密货币。",
    meet_all_your_crypto_needs: "满足您所有的加密需求。",
    why_mew_is_good_choice: "为什么说 MEW 是一个不错的选择？",
    buy_hardware_wallet: "购买硬件钱包",
    portfolio_value: "进入查看钱包信息",
    wallet: "钱包",
    send: "发送",
    settings: "设置",
    logout: "退出",
    other_apps: "其他应用",
    apps: "应用",
    nft_manager: "NFT 经理",
    exchange: "交换",
    stake: "赌注",
    received: "收到",
    buy_sell: "买卖",
    contract: "合同",
    info: "信息",
    new_feature_stake_any_amount_of_eth:
      "警惕陌生链接，警惕陌生环境，警惕授权二维码",
    stake_eth_no_minimum:
      "不要泄露您钱包密钥库，助记词，私钥，不要授权，不要在陌生环境运行钱包，钱包不要备份在邮箱，网盘里！",
    stake_now: "立即质押",
    advertise_with_us: "与我们一起做广告",
    network: "网络",
    block_height: "区块高度：",
    total_assets: "总资产：",
    add_custom_token: "添加自定义令牌",
    send: "发送",
    delete: "删除",
    transaction_history: "交易记录",
    transfer_out: "转出",
    transfer_in: "转入",
    no_transactions: "您还没有交易哦",
    are_you_sure_logout: "您确定要退出吗？",
    are_you_sure_delete_token: "您确定要删除当前代币吗？",
    tx_hash: "TX哈希",
    transaction_time: "交易时间",
    from: "从",
    to: "到",
    amount: "数量",
    fee: "手续费",
    token: "代币",
    hold: "持有",
    price: "价格",
    years_ago: "年前",
    months_ago: "个月前",
    days_ago: "天前",
    hours_ago: "小时前",
    minutes_ago: "分钟前",
    seconds_ago: "秒前",
    failed_to_get_height: "获取高度失败",
  },
  send: {
    line1: "为什么说 MEW 是一",
    line2: "个不错的选择？",
    line3: "操作提示",
    line4: "确认",
  },
  zhensend: {
    balance: "余额：",
    max_limit: "最大限度",
    your: "你的",
    low_balance: "余额太低",
    transaction_fee: "每笔交易都需要少量",
    cannot_send: "才能执行。即使您有代币要兑换，当您的",
    near_zero_balance: "余额接近零时，您将无法发送任何内容，直到您为账户注资。",
    enter_address: "请输入转账地址",
    fee: "手续费",
    total: "总计：",
    insufficient_fee: "不足以支付网络费用。",
    custom: "自定义",
    max_fee: "最高费用",
    max_tip: "最高小费",
    send_transaction: "发送交易",
    zero_balance: "代币余额为0哦~",
    enter_amount: "请输入转账数量",
    insufficient_token_balance: "代币余额不足",
    transaction_cost: "交易费用为",
    transaction_success: "交易成功",
    insufficient_gas: "GAS不足",
    transaction_failed: "交易失败，请检查输入内容",
    gas_limit_failed: "Gas Limit 估算失败",
    token_amount_prompt: "请输入数量"
  }
};
