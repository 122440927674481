<template>
	<div>
		<transition name="slide-up">
			<div v-if="showPage" class="popup">
				<!-- 密钥库  步骤一 -->
				<div class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="5" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.select_network') }}</p>
									<div class="chose_token">
										<div class="chose_token_one" v-for="(value,index) in list" :key="index" @click="choseing(index)">
											<div class="chose_token_one_left">
												<img :src="value.img" alt="">
												<p>{{value.name}}</p>
											</div>
											<img :src="value.active?require('../../static/icon_xuanzhong@2x.png'):require('../../static/icon_weixuanzhong@2x.png')" alt="" class="chose_token_one_right">
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import jsonData from '../../static/rpc.json';
	import Web3 from 'web3';
	export default {
		name: 'MyImport',
		props: {
			showPage: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		data() {
			return {
				list:[],
				rpcListDefaul:null
			}
		},
		created(){
			this.rpcListDefaul = JSON.parse(localStorage.getItem("rpc"));
			this.list = jsonData.rpcList;
			for(var i=0;i<this.list.length;i++){
				this.list[i].active = false;
				if(this.list[i].chainId == this.rpcListDefaul.chainId){
					this.list[i].active = true;
				}
			}
		},
		methods: {
			togglePopup() {
				this.$emit('updateShowPage', !this.showPage);
			},
			choseing(index){
				for(var i=0;i<this.list.length;i++){
					this.list[i].active = false;
				}
				this.list[index].active = true;
				var web3 = new Web3(this.list[index].rpcUrl);
				this.$store.commit('setWeb', web3);
				localStorage.setItem("rpc", JSON.stringify(this.list[index]));
				this.$emit('updateNet',JSON.stringify(this.list[index]));
				this.$forceUpdate();
				this.togglePopup();
			}
		},
	}
</script>
<style scoped lang="less">
	/* 弹出框的样式 */
	.popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		width: 100%;
		height: 100%;
		background-color: #F3F5FB;
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.popup_common {
			width: 100%;
			height: 1005;
		}
	}

	/* 过渡效果 */
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	}

	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(100%);
		opacity: 0;
	}

	.popup_close {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		right: 30px;
	}
	.popup_content {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);
	
		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}
	}
	.chose_token {
		width: 100%;
		border: 1px solid #E3E5ED;
		border-radius: 10px;
		margin-top: 15px;
		.chose_token_one {
			width: 100%;
			height: calc(35px + 1vw);
			border-bottom: 1px solid #E3E5ED;
			padding: 0 calc(5px + 1vw);
			display: flex;
			align-items: center;
			justify-content: space-between;
			.chose_token_one_left {
				display: flex;
				align-items: center;
				img {
					width: calc(10px + 1vw);
					border-radius: 50%;
				}
				p {
					font-size: (10px + 1vw);
					color: #1A183F;
					margin-left: 10px;
				}
			}
			.chose_token_one_right {
				width: calc(12px + 0.5vw);
			}
		}
		.chose_token_one:last-child {
			border-bottom: none;
		}
	}
</style>