<template>
	<div>
		<transition name="slide-up">
			<div v-if="showErweiam" class="popup">
				<!-- 密钥库  步骤一 -->
				<div class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="3" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit" style="text-align: left;">{{ $t('mps.my_public_address') }}</p>
									<p class="erweima_tip">{{ $t('mps.receive_token_instruction') }}</p>
									<div class="erweima_content">
										<img src="../../static/pic_qb_touzi@2x.png" alt=""
											class="erweima_content_bgimg">
										<div class="erweima_content_mid">
											<div class="erweima_content_mid">
												<!-- <img src="../../static/pic_erweima@2x.png" alt=""
													class="erweima_content_mid_left"> -->
												<div class="qrcode-container">
													<canvas ref="qrcodeCanvas" class="erweima_content_mid_left"
														width="90" height="90"></canvas>
												</div>
												<div style="margin-left: 10px;width: 40%;">
													<p class="erweima_content_mid_address"
														style="word-break: break-all;overflow-wrap: break-word;">
														{{wallet.address}}
													</p>
													<div class="erweima_content_mid_copy"
														v-clipboard:copy="wallet.address" v-clipboard:success="onCopy"
														v-clipboard:error="onError">
														<img src="../../static/icon_qb_tz_fuzhi@2x.png" alt="">
														<p>{{ $t('mps.copy') }}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import QRCode from 'qrcode'
	export default {
		name: 'MyErweima',
		props: {
			showErweiam: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		watch: {
			showErweiam(newval, old) {
				console.log(old)
				if (newval) {
					this.$nextTick(() => {
						this.generateQRCode()
					})
				}
			}
		},
		data() {
			return {
				wallet: null,
			}
		},
		created() {
			this.wallet = JSON.parse(localStorage.getItem("wallet"));
		},
		methods: {
			generateQRCode() {
				var _this = this;
				const canvas = _this.$refs.qrcodeCanvas
				QRCode.toCanvas(canvas, _this.wallet.address, error => {
					if (error) console.error(error)
				})
			},
			// async copyText(data) {
			// 	try {
			// 		const textToCopy = data;
			// 		await navigator.clipboard.writeText(textToCopy);
			// 		this.$bvToast.toast("复制成功", {
			// 			title: `提示`,
			// 			variant: "success",
			// 			autoHideDelay: 60000,
			// 			solid: true,
			// 		});
			// 	} catch (err) {
			// 		console.error("复制失败", err);
			// 	}
			// },
			// 复制成功 or 失败（提示信息！！！）
			onCopy: function(e) {
				console.log('复制成功！' + e)
				this.$bvToast.toast(this.$t('copy_success'), {
					title:this.$t('tip'),
					variant: "success",
					autoHideDelay: 5000,
					solid: true,
				});
			},
			onError: function(e) {
				console.log('复制失败！' + e)
			},
			togglePopup() {
				this.$emit('updateErweima', !this.showErweiam);
			},
		},
	}
</script>
<style>
	.b-toaster {
		z-index: 99999999999 !important;
	}

	.qrcode-container {
		width: calc(90px + 1vw);
		/* 容器宽度 */
		height: calc(90px + 1vw);
		/* 容器高度 */
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qrcode-container>canvas {
		width: 100% !important;
		/* 覆盖二维码的默认宽度 */
		height: 100% !important;
		/* 覆盖二维码的默认高度 */
		background-size: cover !important;
		/* 确保二维码背景图像覆盖整个容器 */
	}
</style>
<style scoped lang="less">
	/* 弹出框的样式 */
	.popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		width: 100%;
		height: 100%;
		background-color: #F3F5FB;
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.popup_common {
			width: 100%;
			height: 1005;
		}
	}

	/* 过渡效果 */
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	}

	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(100%);
		opacity: 0;
	}

	.popup_close {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		right: 30px;
	}

	.popup_content {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);

		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}

		.erweima_tip {
			font-size: calc(6px + 0.6vw);
			color: #1A183F;
			text-align: left;
			margin-top: 10px;
		}

		.erweima_content {
			width: 100%;
			position: relative;
			margin-top: 10px;

			.erweima_content_bgimg {
				width: 100%;
			}

			.erweima_content_mid {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.erweima_content_mid_left {
					width: calc(90px + 1vw);
				}

				.erweima_content_mid_address {
					font-size: calc(8px + 0.2vw);
					color: #ffffff;
					text-align: left;
				}

				.erweima_content_mid_copy {
					display: flex;
					align-items: center;
					margin-top: 15px;
					cursor: pointer;

					img {
						width: calc(12px + 1vw);
						margin-right: 8px;
					}

					p {
						font-size: calc(8px + 0.2vw);
						color: #ffffff;
					}
				}
			}
		}
	}
</style>