<template>
	<div>
		<img style="width: 100%;position: relative;" src="@/assets/bg@1x.png" alt="">
		<div class="index_one" style="position: absolute;top: 0;">
			<div class="col-sm-9 col-lg-7 p-0 mage d-flex align-items-center mt-3 justify-content-between">
				<div><img style="width: 200px;" src="@/assets/lo.png" alt=""></div>
				<div class="d-flex align-items-center position-relative">
					<div @click="gjshow=!gjshow" class="d-flex align-items-center mr-3 pl-3 pt-1 pb-1 pr-3 dtd cursor">
						<img class="mr-1 dittp" src="@/assets/icon_yyqh@2x.png" alt="">
						<div class="diziti">{{ languageText }}</div>
						<div v-if="gjshow" class="position-absolute p-3 d-flex sikmm" style="width: calc(250px + 0.4vw);">
							<img @click="swichLanguage('zh')" class="mr-2 mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/Group 34491@2x.png" alt="">
							<img @click="swichLanguage('en')" class="mr-2 mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/Group 34493@2x.png" alt="">
							<img @click="swichLanguage('ja')" class="mr-2 mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/icon_ja_8@2x.png" alt="">
							<img @click="swichLanguage('ko')" class="mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/icon_ko_8@2x.png" alt="">
							<img @click="swichLanguage('ru')" class="mr-2" style="width: calc(35px + 0.4vw);" src="@/assets/icon_ru_8@2x.png" alt="">
						</div>
					</div>
					<div class="mr-3 pl-3 pt-1 pb-1 pr-3 tqqd cursor" @click="findMyPacage()">{{ $t('Accessmywallet') }}</div>
				</div>
			</div>
			<!-- 第一部分 -->
			<div class="col-md-12 col-sm-12 p-0 mage">
				<div class="mt-5 mb-5 d-flex align-items-center col-sm-12 p-0 justify-content-around">
					<div class="d-flex align-items-center">
						<div class="mr-4 yujna">{{ $t('ethPackage') }}</div>
						<div class="d-flex align-items-center">
							<img class="mr-2 tpd1xwy" src="@/assets/icon_zuo@2x.png" alt="">
							<div class="ybiandxz">
								<div>****</div>
								<div>{{ $t('Trusted by millions') }}</div>
								<div>{{ $t('worldwide since 2015') }}</div>
							</div>
							<img class="ml-2 tpd1xwy" src="@/assets/icon_you@2x.png" alt="">
						</div>
					</div>
				</div>
				<div class="col-md-7 col-sm-12 p-0 top-si mage">{{ $t('index.most_reputable_wallet') }}</div>
				<div class="col-md-7 col-sm-12 p-0  d-flex align-items-center justify-content-around mt-4 position-relative mage">
					<img class="position-absolute" style="left: -5%;width: calc(200px + 0.4vw);top: 1%;" src="@/assets/peggy@1x.png" alt="">
					<div class="align-items-center" style="text-align: center;">
						<div class="pt-3 pb-3 pl-5 pr-5 baiseyuanjiao mr-2 cursor" @click="CreateMyPacage()" style="background: #4bc0a6;font-size: calc(16px + 0.4vw);">{{
							$t('index.create_new_wallet')
						}}</div>
						<div>
						    <div class="pt-2 pb-2 mt-1 pl-4 pr-4 baiseyuanjiao cursor" @click="findMyPacage()" style="color: #000;"> <div style="font-size: calc(10px + 0.4vw);">{{ $t('index.or') }} </div> 
							<div class="pt-3 mt-2 pb-3 pl-5 pr-5 baiseyuanjiao mr-2 cursor" @click="findMyPacage()" style="background: #005ae5;font-size: calc(16px + 0.4vw);">{{ $t('index.access_my_wallet') }}</div>
						    </div>
						</div>
						
					</div>
				</div>
			</div>
			<!-- 第二部分 -->
			<div class="col-sm-9 col-lg-7 p-0 mage mt-5">
				<div class="col-sm-12 p-0 text-center uhhnbi mb-3">{{ $t('index.our_products_make_crypto_easier') }}
				</div>
				<div class="col-sm-12 p-0 text-center asuhhnbi mb-4">{{ $t('index.available_on_mobile_and_desktop') }}
				</div>
				<div class="col-sm-12 p-0 d-flex justify-content-between">
					<div class="p-3 yuaniaio" style="position: relative;">
						<div class="jskl mb-1">{{ $t('Store') }}</div>
						<div class="jskl mb-1">{{ $t('Ethereum') }}</div>
						<div class="jskl mb-3">{{ $t('safely') }}</div>
						<div class="jsklhui">{{ $t('Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.') }}</div>
						<div class="p-3 " style="position: absolute;bottom: 0;">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_one@2x.png" alt="">
						</div>
					</div>
					</div>
					<div class="p-3 yuaniaio" style="position: relative;">
						<div class="jskl mb-1">{{ $t('Stay in control') }}</div>
						<div class="jsklhui">{{ $t('We secure your') }}</div>
						<div class="p-3 " style="position: absolute;bottom: 0;">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_two@2x.png" alt="">
						</div>
					</div>
					</div>
					<div class="p-3 yuaniaio">
						<div class="jskl mb-1">{{ $t('Buy, sell, and') }}</div>
						<div class="jskl mb-1">{{ $t('swap safely') }}</div>
						<div class="jskl mb-3">{{ $t('safely') }}</div>
						<div class="jsklhui">{{ $t('Convert USD, EUR') }}</div>
						<div class="p-3 ">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_three@2x.png" alt="">
						</div>
					</div>
					</div>
					<div class="p-3 yuaniaio" style="position: relative;">
						<div class="jskl mb-1">{{ $t('Easy asset') }}</div>
						<div class="jskl mb-1">{{ $t('management') }}</div>
						<div class="jsklhui">{{ $t('Compare and secure the') }}</div>
						<div class="p-3 " style="position: absolute;bottom: 0;">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_four@2x.png" alt="">
						</div>
					</div>
					</div>
				</div>
				
			</div>
			<!-- 第三部分 -->
			<div class="col-sm-9 col-lg-7 p-0 mage mt-5">
				<div class="col-sm-12 p-0 text-center uhhnbi mb-3">{{ $t('How to secure your Ethereum wallet') }}
				</div>
				<div class="col-sm-12 p-0 text-center asuhhnbi mb-4">{{ $t('Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.') }}
				</div>
				<div v-for="(item,index) in item" :key="index" class="pb-3  mb-3" @click="fun(item.id)" style="border-bottom: 1px solid #19183F;cursor: pointer;">
					<div class="d-flex justify-content-between">
						<div>{{ item.name }}</div>
						<div style="position: relative;right: 2%;">
							<div class="image" v-if="sbbfid == item.id && show" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(0deg);"><img style="width: 16px;height: 16px; " src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
							<div class="image" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(180deg);" v-else><img style="width: 16px;height: 16px;" src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
						</div>
					    </div>
					<div class="mt-3 xindeys" v-if="sbbfid == item.id && show">{{ item.xuanz }}</div>
				</div>
			</div>
			<!-- 第四部分 -->
			<div class="col-sm-9 col-lg-7 p-0 mage mt-5">
				<div class="col-sm-12 p-0 text-center uhhnbi mb-3">{{ $t('Frequently asked questions') }}
				</div>
				<div v-for="(item,index) in items" :key="index" class="pb-3  mb-3" @click="funs(item.id)" style="border-bottom: 1px solid #19183F;cursor: pointer;">
					<div class="d-flex justify-content-between">
						<div>{{ item.name }}</div>
						<div style="position: relative;right: 2%;">
							<div class="image"  v-if="sbbfids == item.id && shows" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(0deg);"><img style="width: 16px;height: 16px; " src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
							<div class="image" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(180deg);" v-else><img style="width: 16px;height: 16px;" src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
						</div>
					    </div>
					<div class="mt-3 xindeys"  v-if="sbbfids == item.id && shows">{{ item.xuanz }}</div>
				</div>
			</div>
			<!-- 第五部分 -->
			<div class="col-sm-9 col-lg-7 p-0 mage mt-5">
				<div class="col-sm-10 d-flex align-items-center" style="margin: auto;">
				    <div style="width: 50%;">
                         <img style="width: 100%;" src="@/assets/pic_erc@2x.png" alt="">
					</div>
					<div class="ml-4" style="width: 30%;">
                          <div class="uhhnbi mb-3">{{ $t('Supporting all ERC-20 tokens') }}</div>
						  <div class="asuhhnbi">{{ $t('If it is on Ethereum') }}</div>
                    </div>
			    </div>
			</div>
			<!-- 第6部分 -->
			<div class="col-sm-9 col-lg-8 p-0 mage mt-5" style="text-align: center;">
				<img class="col-sm-3" src="@/assets/pic_control@2x.png" alt="">
				<div class="col-sm-5 pt-3 pb-3" style="margin: auto;background: #E5EBFC;border-radius: 15px;">
				   <div class="col-sm-6" style="margin: auto;">
					   <div class="uhhnbi mb-3">{{ $t('Grab control of your future') }}</div>
					   <div class="ann pt-2 pb-2 cursor" @click="CreateMyPacage()">{{ $t('Create a new wallet') }}</div>
				   </div>
			    </div>
			</div>
			<!-- 第七部分 -->
			<div class="col-sm-9 col-lg-7 p-0 mage mt-5">
				<img class="col-sm-12 p-0 position-relative" style="height: 30vh;" src="@/assets/pic_sign_me_up@2x.png" alt="">
				<div class="position-absolute col-sm-12 p-0" style="top: 15%;left: 5%;">
                    <div class="col-sm-3 p-0 uhhnbi" style="color: white;">{{ $t('Get better at crypto') }}</div>
					<div class="col-sm-5 p-0 asuhhnbi" style="color: white;margin-top: 2%;">{{ $t('Level up your skills with security tips, industry insights, new and more!') }}</div>
				    <div class="d-flex col-sm-12 p-0 cursor" style="margin-top: 2%;">
						<!-- <div class="col-sm-4 pt-2 pb-2 mr-2" style="color: white;border-radius: 16px;border: 1px solid #6D93FF;">{{ $t('Enter your email') }}</div> -->
						<div class="pt-2 pb-2 pl-5 pr-5" style="color: #003DE5;background: #FFFFFF;border-radius: 16px;"><a href="mailto:123456@qq.com?subject=TestObject&body=1211212" style="color: #000000;">{{ $t('Sign me up!') }}</a></div>
					</div>
				</div>
			</div>
			<!-- 第8部分 -->
			<div class="col-sm-12 p-0 mage mt-5" style="background: #19183F;">
				<div class="col-sm-5 p-0" style="margin: auto;">
					<div class="d-flex pb-3 pt-4" style="justify-content: center;">
                      <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_one@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_two@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_three@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_four@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_five@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_fix@2x.png" alt=""></div>
				   </div>
                   <div class="yanse mb-2">{{ $t('Warning: Trading cryptocurrencies involves significant high risks.') }}</div>
				   <div class="yanse mb-2">{{ $t('BTC Direct was rated a 4.5 out of 5 based on 10475 reviews') }}</div>
				   <div class="yanse mb-2">{{ $t('© 2013 - 2024 - BTC Direct Europe B.V.') }}</div>
				   <div class="d-flex pb-4 yanse" style="justify-content: center;">
                      <div class="mr-2">{{ $t('Sitemap') }}</div>
					  <div class="mr-2">{{ $t('Disclaimer') }}</div>
					  <div class="mr-2">{{ $t('Terms of Service') }}</div>
					  <div class="mr-2">{{ $t('Privacy Policy') }}</div>
					  <div class="mr-2">API</div>
					  <div class="mr-2">{{ $t('Fiat onramp') }}</div>
				   </div>
				</div>
			</div>
		</div>
		<!-- 手机版 -->
		<div class="sjiindex" style="position: absolute;top: 0;width: 100%;">
            <div class="mage d-flex align-items-center mt-3 justify-content-between" style="width: 95%;">
				<div><img style="width: 120px;" src="@/assets/lo.png" alt=""></div>
				<div class="d-flex align-items-center position-relative">
					<div @click="gjshow=!gjshow" class="d-flex align-items-center mr-2 pl-3 pt-1 pb-1 pr-3 dtd cursor">
						<img class="mr-1 dittp" src="@/assets/icon_yyqh@2x.png" alt="">
						<div class="diziti">{{ languageText }}</div>
						<div v-if="gjshow" class="position-absolute p-3 d-flex sikmm" style="width: calc(230px + 0.4vw);">
							<img @click="swichLanguage('zh')" class="mr-2 mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/Group 34491@2x.png" alt="">
							<img @click="swichLanguage('en')" class="mr-2 mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/Group 34493@2x.png" alt="">
							<img @click="swichLanguage('ja')" class="mr-2 mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/icon_ja_8@2x.png" alt="">
							<img @click="swichLanguage('ko')" class="mb-3" style="width: calc(35px + 0.4vw);" src="@/assets/icon_ko_8@2x.png" alt="">
							<img @click="swichLanguage('ru')" class="mr-2" style="width: calc(35px + 0.4vw);" src="@/assets/icon_ru_8@2x.png" alt="">
						</div>
					</div>
					<div class=" pl-3 pt-1 pb-1 pr-3 tqqd cursor" @click="findMyPacage()">{{ $t('Accessmywallet') }}</div>
				</div>
			</div>
			<!-- 第一部分 -->
			<div class=" mage" style="width: 90%;">
				<div class="mt-4 mb-4 d-flex align-items-center justify-content-around" style="width: 100%;">
					<div class="d-flex align-items-center">
						<div class="mr-2 yujna" >{{ $t('ethPackage') }}</div>
						<div class="d-flex align-items-center">
							<img class="mr-1" style="width: 14px;height: 48px;" src="@/assets/icon_zuo@2x.png" alt="">
							<div class="ybiandxz" >
								<div>****</div>
								<div>{{ $t('Trusted by millions') }}</div>
								<div>{{ $t('worldwide since 2015') }}</div>
							</div>
							<img class="ml-1" style="width: 14px;height: 48px;" src="@/assets/icon_you@2x.png" alt="">
						</div>
					</div>
				</div>
				<div class="col-sm-9 p-0 top-si mage">{{ $t('index.most_reputable_wallet') }}</div>
				<div class="col-md-7 col-sm-12 p-0  d-flex align-items-center justify-content-around mt-4 position-relative mage">
					<img class="position-absolute" style="left: -12%;width: calc(90px + 0.4vw);top: 35%;" src="@/assets/peggy@1x.png" alt="">
					<div class="align-items-center" style="text-align: center;">
						<div class="pt-3 pb-3 pl-4 pr-4 baiseyuanjiao mr-2 cursor" @click="CreateMyPacage()" style="background: #4bc0a6;font-size: calc(16px + 0.4vw);">{{
							$t('index.create_new_wallet')
						}}</div>
						<div>
						    <div class="pt-2 pb-2 mt-1 pl-4 pr-4 baiseyuanjiao cursor" @click="findMyPacage()" style="color: #000;"> <div style="font-size: calc(12px + 0.4vw);">{{ $t('index.or') }} </div> 
							<div class="pt-3 mt-2 pb-3 pl-4 pr-4 baiseyuanjiao mr-2 cursor" @click="findMyPacage()" style="background: #005ae5;font-size: calc(16px + 0.4vw);">{{ $t('index.access_my_wallet') }}</div>
						    </div>
						</div>
						
					</div>
				</div>
			</div>
			<!-- 第二部分 -->
			<div class=" mage mt-4" style="width: 90%;">
				<div class="col-sm-12 p-0 text-center uhhnbi mb-2">{{ $t('index.our_products_make_crypto_easier') }}
				</div>
				<div class="col-sm-12 p-0 text-center asuhhnbi mb-3">{{ $t('index.available_on_mobile_and_desktop') }}
				</div>
				<div style="width: 100%;overflow-y: auto;">
					<div class="col-sm-12 p-0 d-flex justify-content-between" style="width: 880px;">
					<div class="p-3 yuaniaio mr-3" style="width: 200px;position: relative;">
						<div class="jskl mb-1">{{ $t('Store') }}</div>
						<div class="jskl mb-1">{{ $t('Ethereum') }}</div>
						<div class="jskl mb-3">{{ $t('safely') }}</div>
						<div class="jsklhui">{{ $t('Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.') }}</div>
					    <div class="p-3 " style="position: absolute;bottom: 0;">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_one@2x.png" alt="">
						</div>
					</div>
					</div>
					<div class="p-3 yuaniaio mr-3" style="position: relative;width: 200px;">
						<div class="jskl mb-1">{{ $t('Stay in control') }}</div>
						<div class="jsklhui">{{ $t('We secure your') }}</div>
						<div class="p-3" style="position: absolute;bottom: 0;">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_two@2x.png" alt="">
						</div>
					</div>
					</div>
					<div class="p-3 yuaniaio mr-3" style="position: relative;width: 200px;">
						<div class="jskl mb-1">{{ $t('Buy, sell, and') }}</div>
						<div class="jskl mb-1">{{ $t('swap safely') }}</div>
						<div class="jskl mb-3">{{ $t('safely') }}</div>
						<div class="jsklhui">{{ $t('Convert USD, EUR') }}</div>
						<div class="p-3 " >
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_three@2x.png" alt="">
						</div>
					</div>
					</div>
					<div class="p-3 yuaniaio" style="position: relative;width: 200px;">
						<div class="jskl mb-1">{{ $t('Easy asset') }}</div>
						<div class="jskl mb-1">{{ $t('management') }}</div>
						<div class="jsklhui">{{ $t('Compare and secure the') }}</div>
						<div class="p-3 " style="position: absolute;bottom: 0;">
						<div style="width: 100%;">
							<img style="width: 100%;" src="@/assets/pic_four@2x.png" alt="">
						</div>
					</div>
					</div>
				</div>
				</div>
				
			</div>
			<!-- 第三部分 -->
			<div class="mage mt-4" style="width: 90%;">
				<div class="col-sm-12 p-0 text-center uhhnbi mb-3">{{ $t('How to secure your Ethereum wallet') }}
				</div>
				<div class="col-sm-12 p-0 text-center asuhhnbi mb-3">{{ $t('Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.') }}
				</div>
				<div v-for="(item,index) in item" :key="index" class="pb-2  mb-3" @click="fun(item.id)" style="border-bottom: 1px solid #19183F;cursor: pointer;">
					<div class="d-flex justify-content-between">
						<div>{{ item.name }}</div>
						<div style="position: relative;right: 4%;">
							<div class="image"  v-if="sbbfid == item.id && show" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(0deg);"><img style="width: 16px;height: 16px; " src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
							<div class="image" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(180deg);" v-else><img style="width: 16px;height: 16px;" src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
						</div>
					    </div>
					<div class="mt-3 xindeys" v-if="sbbfid == item.id && show">{{ item.xuanz }}</div>
				</div>
			</div>
			<!-- 第四部分 -->
			<div class="mage mt-5" style="width: 90%;">
				<div class="col-sm-12 p-0 text-center uhhnbi mb-3">{{ $t('Frequently asked questions') }}
				</div>
				<div v-for="(item,index) in items" :key="index" class="pb-2  mb-3" @click="funs(item.id)" style="border-bottom: 1px solid #19183F;cursor: pointer;">
					<div class="d-flex justify-content-between">
						<div>{{ item.name }}</div>
						<div style="position: relative;right: 4%;">
							<div class="image"  v-if="sbbfids == item.id && shows" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(0deg);"><img style="width: 16px;height: 16px; " src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
							<div class="image" style="position: absolute;transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;transform: rotate(180deg);" v-else><img style="width: 16px;height: 16px;" src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
						</div>
					    <!-- <div v-if="sbbfids == item.id && shows"><img style="width: 16px;height: 16px;" src="@/assets/icon_shanglajiantou@2x.png" alt=""></div>
					    <div v-else><img style="width: 16px;height: 16px;" src="@/assets/icon_xialajiantou@2x.png" alt=""></div> -->
					    </div>
					<div class="mt-3 xindeys" v-if="sbbfids == item.id && shows">{{ item.xuanz }}</div>
				</div>
			</div>
			<!-- 第五部分 -->
			<div class="mage mt-5" style="width: 90%;">
				<div class="d-flex align-items-center" style="margin: auto;width: 80%;">
				    <div style="width: 50%;">
                         <img style="width: 100%;" src="@/assets/pic_erc@2x.png" alt="">
					</div>
					<div class="ml-3" style="width: 40%;">
                          <div class="uhhnbi mb-2">{{ $t('Supporting all ERC-20 tokens') }}</div>
						  <div class="asuhhnbi">{{ $t('If it is on Ethereum') }}</div>
                    </div>
			    </div>
			</div>
			<!-- 第6部分 -->
			<div class="mage mt-4" style="text-align: center;width: 90%;">
				<img class="col-sm-3" style="width: 45%;" src="@/assets/pic_control@2x.png" alt="">
				<div class=" pt-3 pb-3" style="margin: auto;background: #E5EBFC;border-radius: 15px;width: 60%;">
				   <div class="col-sm-6" style="margin: auto;">
					   <div class="uhhnbi mb-3">{{ $t('Grab control of your future') }}</div>
					   <div class="ann pt-2 pb-2 cursor" @click="CreateMyPacage()">{{ $t('Create a new wallet') }}</div>
				   </div>
			    </div>
			</div>
			<!-- 第七部分 -->
			<div class="mage mt-4" style="width: 90%;position: relative;">
				<img  style="width: 100%;position: relative;height: 160px;object-fit: cover;border-radius: 12px;" src="@/assets/pic_sign_me_up@2x.png" alt="">
				<div  style="left: 5%;width: 100%;position: absolute;top: 10px;">
                    <div class="uhhnbi mt-2" style="color: white;width: 20%;font-size: 11px;">{{ $t('Get better at crypto') }}</div>
					<div class="p-0" style="color: white;margin-top: 1%;font-size: 8px;margin-bottom: 20px;width: 70%;">{{ $t('Level up your skills with security tips, industry insights, new and more!') }}</div>
				    <div class="d-flex " style="width: 100%;">
						<!-- <div class="pl-3 pt-2 pb-2 mr-2" style="color: white;border-radius: 16px;border: 1px solid #6D93FF;width: 40%;font-size: 8px;">{{ $t('Enter your email') }}</div> -->
						<div class="pt-2 pb-2 pl-3 pr-3" style="color: #003DE5;background: #FFFFFF;border-radius: 16px;font-size: 8px;"><a href="mailto:123456@qq.com?subject=TestObject&body=1211212" style="color: #000000;">{{ $t('Sign me up!') }}</a></div>
					</div>
				</div>
			</div>
			<!-- 第8部分 -->
			<div class=" mage mt-5" style="background: #19183F;width: 100%;">
				<div class="col-sm-5 p-0" style="margin: auto;width: 90%;">
					<div class="d-flex pb-3 pt-4" style="justify-content: center;">
                      <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_one@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_two@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_three@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_four@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_five@2x.png" alt=""></div>
					  <div class="mr-2 cursor"><img class="usimg" src="@/assets/icon_footer_fix@2x.png" alt=""></div>
				   </div>
                   <div class="yanse mb-2" style="font-size: 12px !important;">{{ $t('Warning: Trading cryptocurrencies involves significant high risks.') }}</div>
				   <div class="yanse mb-2">{{ $t('BTC Direct was rated a 4.5 out of 5 based on 10475 reviews') }}</div>
				   <div class="yanse mb-2">{{ $t('© 2013 - 2024 - BTC Direct Europe B.V.') }}</div>
				   <div class="d-flex pb-4 yanse" style="justify-content: center;font-size: calc(1px + 0.4vw);">
                      <div class="mr-2">{{ $t('Sitemap') }}</div>
					  <div class="mr-2">{{ $t('Disclaimer') }}</div>
					  <div class="mr-2">{{ $t('Terms of Service') }}</div>
					  <div class="mr-2">{{ $t('Privacy Policy') }}</div>
					  <div class="mr-2">API</div>
					  <div class="mr-2">{{ $t('Fiat onramp') }}</div>
				   </div>
				</div>
			</div>
		</div>
		<my-import :showPage="showPage" @updateShowPage="updateShowPage"></my-import>
		<!-- 访问我的钱包弹窗 -->
	</div>
</template>

<script>
import MyHeader from '@/components/header.vue';
import MyImport from '@/components/import.vue';
export default {
	name: 'MyIndexPage',
	components: {
		MyHeader,
		MyImport
	},
	data() {
		return {
			gjshow:false,
			languageText:'中文简体',
			sbbfid:1,
			showPage: false,
			show:false,
			sbbfids:1,
			shows:false,
			item:[
				{
					name:this.$t('Back up your wallet') ,
					id:1,
					xuanz:this.$t('boots1')
				},
				{
					name:this.$t('Never share your private key'),
					id:2,
					xuanz:this.$t('boots2')
				},
				{
					name:this.$t('Keep your wallet and device software up to date'),
					id:3,
					xuanz:this.$t('boots3')
				},
				{
					name:this.$t('Be cautious of phishing scams'),
					id:4,
					xuanz:this.$t('boots4')
				}
			],
			items:[
				{
					name:this.$t('What is a Ethereum wallet?'),
					id:1,
					xuanz:this.$t('boots5')
				},
				{
					name:this.$t('Are Ethereum wallets free?'),
					id:2,
					xuanz:this.$t('boots6')
				},
				{
					name:this.$t('What is the safest Ethereum Wallet?'),
					id:3,
					xuanz:this.$t('boots7')
				},
				{
					name:this.$t('Which app can I use to create my Ethereum wallet?'),
					id:4,
					xuanz:this.$t('boots8')
				}
			]
		}
	},
	created(){
			if(this.$i18n.locale == 'en'){
				this.languageText = 'ENGLISH'
			}else if(this.$i18n.locale == 'zh'){
				this.languageText = '中文简体'
			}else if(this.$i18n.locale == 'ja'){
				this.languageText = 'JAPANESE'
			}else if(this.$i18n.locale == 'ko'){
				this.languageText = 'KOREAN'
			}else if(this.$i18n.locale == 'ru'){
				this.languageText = 'RUSSIAN'
			}
			
		},
	methods: {
		CreateMyPacage(){
				this.$router.push({
					path: '/Create'
				})
			},
		swichLanguage(lang) {
				this.$i18n.locale = lang;
				if(this.$i18n.locale == 'en'){
					this.languageText = 'ENGLISH'
				}else if(this.$i18n.locale == 'zh'){
					this.languageText = '中文简体'
				}else if(this.$i18n.locale == 'ja'){
				    this.languageText = 'JAPANESE'
			    }else if(this.$i18n.locale == 'ko'){
				    this.languageText = 'KOREAN'
			    }else if(this.$i18n.locale == 'ru'){
				    this.languageText = 'RUSSIAN'
			    }
				this.item=[
				{
					name:this.$t('Back up your wallet') ,
					id:1,
					xuanz:this.$t('boots1')
				},
				{
					name:this.$t('Never share your private key'),
					id:2,
					xuanz:this.$t('boots2')
				},
				{
					name:this.$t('Keep your wallet and device software up to date'),
					id:3,
					xuanz:this.$t('boots3')
				},
				{
					name:this.$t('Be cautious of phishing scams'),
					id:4,
					xuanz:this.$t('boots4')
				}
			]
			this.items=[
			{
					name:this.$t('What is a Ethereum wallet?'),
					id:1,
					xuanz:this.$t('boots5')
				},
				{
					name:this.$t('Are Ethereum wallets free?'),
					id:2,
					xuanz:this.$t('boots6')
				},
				{
					name:this.$t('What is the safest Ethereum Wallet?'),
					id:3,
					xuanz:this.$t('boots7')
				},
				{
					name:this.$t('Which app can I use to create my Ethereum wallet?'),
					id:4,
					xuanz:this.$t('boots8')
				}
			]
			},
		fun(e){
			if(this.sbbfid==e){
				this.show=!this.show
			}else{
				this.sbbfid=e;
				this.show=true;
			}
			
		},
		funs(e){
			if(this.sbbfids==e){
				this.shows=!this.shows
			}else{
				this.sbbfids=e;
				this.shows=true;
			}
			
		},
		// 访问我的钱包
		findMyPacage() {
			this.showPage = true;
		},
		updateShowPage(newValue) {
			this.showPage = newValue;
		}
	},
}
</script>
<style scoped lang="less">
// 屏幕宽度小于1000的手机样式
@media (max-width: 1000px) {  
  .index_one{
      display: none;
  }
  .sjiindex{
    display: block !important;
  }
  .top-si {
	text-align: center;
	font-size: calc(20px + 0.4vw) !important;
	font-weight: 600;
}
.baiseyuanjiao {
	border-radius: 12px;
	color: #FFFFFF;
	font-size: calc(6px + 0.4vw);
}
.yujna {
	font-size: calc(13px + 0.4vw) !important;
	color: #114b57;
	font-weight: 700;
}
}  

// 
.sjiindex{
	display: none;
}
.image.hidden {  
    opacity: 0;  
    transform: rotate(90deg);  
}  
  
.image.inactive {  
    opacity: 0;  
}
.byuanjiao{
	width: 24%;
	background-color: #ECECEC;
	border-radius: 0 0 15px 15px;
}
.yuaniaio{
	width: 24%;
	background-color: #ECECEC;
	border-radius: 15px;
	border: 1px solid #dbdce5;
}
.mage {
	margin: 0 auto;
}
.cursor{
	cursor: pointer;
}
.usimg{
	width: calc(30px + 0.4vw);
	height: calc(30px + 0.4vw);
}
.top-si {
	text-align: center;
	font-size: calc(50px + 0.5vw);
	font-weight: 600;
}

.tqqd {
	background: #19183F;
	border-radius: 20px 20px 20px 20px;
	color: #FFFFFF;
	font-size: calc(9px + 0.4vw);
}

.dtd {
	border: 1px solid #19183F;
	border-radius: 20px 20px 20px 20px;
}

.dittp {
	width: calc(15px + 0.4vw);
	height: calc(15px + 0.4vw);
}

.diziti {
	color: #19183F;
	font-size: calc(9px + 0.4vw);
}

.ybiandxz {
	text-align: center;
	font-size: calc(9px + 0.4vw);
	line-height: 17px;
}

.tpd1xwy {
	width: 14px;
	height: 41px;
}

.yujna {
	font-size: calc(20px + 0.4vw);
	color: #114b57;
	font-weight: 700;
}

.baiseyuanjiao {
	border-radius: 12px;
	color: #FFFFFF;
	font-size: calc(11px + 0.4vw);
}

.uhhnbi {
	font-weight: 700;
	color: #19183F;
	font-size: calc(16px + 0.4vw);
}

.asuhhnbi {
	color: #19183F;
	font-size: calc(10px + 0.4vw);
}

.jsklhui {
	color: #19183F;
	font-size: calc(10px + 0.4vw);
}

.jskl {
	font-weight: 700;
	color: #19183F;
	font-size: calc(13px + 0.4vw);
}
.ann{
	color: white;
	width: 100%;
	text-align: center;
	font-size: calc(8px + 0.4vw);
	background: #003DE5;
    border-radius: 16px;
}
.yanse{
	width: 100%;
	text-align: center;
	color: #FFFFFF;
	font-size: calc(6px + 0.4vw);
}
.sikmm{
	flex-wrap: wrap;
	justify-content: space-between;
	border: 1px solid #19183F;
	border-radius: 20px;
	background: #ffffff;
	top: 130%;
	left: 0;
	z-index: 99999;
}
.xindeys{
	font-size: 14px;
color: #19183F;
line-height: 22px;
width: 80%;
}
</style>