<template>
	<div>
		<transition name="slide-up">
			<div v-if="showAdd" class="popup">
				<!-- 密钥库  步骤一 -->
				<div class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="4" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('components.add_custom_token') }}</p>
									<input type="text" class="addtoken_input" v-model="tokenContractAddress"
										:placeholder="$t('components.enter_contract_addressm')" />
									<input type="text" class="addtoken_input" v-if="showInfo" v-model="tokenSymbol"
										disabled :placeholder="$t('components.enter_contract_addressm')" />
									<input type="text" class="addtoken_input" v-if="showInfo" v-model="tokenDecimals"
										disabled :placeholder="$t('components.enter_contract_addressm')" />
									<div class="addtoken_content_buttons">
										<div class="popup_content_buttons_left" @click="togglePopup()">{{ $t('cancel') }}</div>
										<div class="popup_content_buttons_right" @click="addToken()">{{ $t('add') }}</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</transition>
		<div style="position: fixed;width: 100%;height:100%;background-color: rgba(0, 0, 0, 0.2);top:0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 999999999999;"
			v-if="isLoading">
			<b-spinner v-if="isLoading" label="Loading..." style="width: 3rem; height: 3rem;"
				variant="primary"></b-spinner>
		</div>
	</div>
</template>

<script>
	import abiData from '../../static/abi.json';
	import axios from 'axios';
	// import Web3 from 'web3';
	export default {
		name: 'MyImport',
		props: {
			showAdd: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		data() {
			return {
				tokenContractAddress: null,
				tokenSymbol: null,
				tokenDecimals: null,
				isLoading: false,
				extendedTokenContractABI: null,
				web3: null,
				wallet: null,
				showInfo: false,
				jiaoyan: false,
				tokenName: null,
				tokenBalance: null,
				rpcListDefaul: null,
				tokenPrice:0,
				tokenIconList: [{
						name: 'USDT',
						url: require('../../static/icon/usdt.png')
					},
					{
						name: 'DAI',
						url: require('../../static/icon/dai.png')
					},
					{
						name: 'UNI',
						url: require('../../static/icon/uni.png')
					},
					{
						name: 'BNB',
						url: require('../../static/icon/bnb.png')
					},
					{
						name: 'SHIB',
						url: require('../../static/icon/shi.png')
					},
					{
						name: 'USDC',
						url: require('../../static/icon/usdc.png')
					},
					{
						name: 'XRP',
						url: require('../../static/icon/xrp.png')
					},
				],
			}
		},
		created() {
			this.extendedTokenContractABI = abiData.tokenContractABI;
		},
		watch: {
			tokenContractAddress(newVal) {
				if (newVal) {
					this.isValidContractAddress(newVal).then(res => {
						if (res) {
							this.isLoading = true;
							Promise.all([this.getTokenInfo(), this.getPrice()]).then((resList) => {
								console.log('resList--', resList);
								this.isLoading = false;
							}).catch(error => {
								this.isLoading = false;
								console.log('error--', error);
							})
						}
					})
				}
			}
		},
		methods: {
			addToken() {
				if (!this.jiaoyan) {
					this.$bvToast.toast(this.$t('components.enter_correct_address'), {
						title: $t('tip'),
						variant: "success",
						solid: true,
					});
					return;
				}
				var wallet = JSON.parse(localStorage.getItem("wallet"));
				var rpcListDefaul = JSON.parse(localStorage.getItem("rpc"));
				var tokenLocaList = JSON.parse(localStorage.getItem(wallet.address + '_' + rpcListDefaul
					.chainId));
					var imgUrl = require('../../static/icon/defalu.png');
				for(var i=0;i<this.tokenIconList.length;i++){
					if(this.tokenSymbol == this.tokenIconList[i].name){
						imgUrl = this.tokenIconList[i].url;
					}
				}
				var data = {
					address: this.tokenContractAddress,
					balance:this.tokenBalance,
					img:imgUrl,
					name: this.tokenName,
					symbol: this.tokenSymbol,
					price:this.tokenPrice
				}
				tokenLocaList.push(data);
				localStorage.setItem(wallet.address + '_' + rpcListDefaul.chainId, JSON.stringify(tokenLocaList));
				this.showInfo = false;
				this.$emit('updateShowAdd', !this.showAdd);
				this.$emit('updateToken');
				// {
				// 	name: 'Tether USD',
				// 	symbol: 'USDT',
				// 	address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
				// 	decimals: 6
				// },
			},
			togglePopup() {
				this.$emit('updateShowAdd', !this.showAdd);
			},
			async getTokenInfo() {
				var wallet = JSON.parse(localStorage.getItem("wallet"));
				var web3 = this.$store.state.web3
				const extendedContract = new web3.eth.Contract(this.extendedTokenContractABI, this.tokenContractAddress);
				try {
					const name = await extendedContract.methods.name().call();
					const symbol = await extendedContract.methods.symbol().call();
					const balance = await extendedContract.methods.balanceOf(wallet.address).call();
					const decimals = await extendedContract.methods.decimals().call();
					const balance2 = balance / (10 ** decimals);
					this.isLoading = false;
					this.showInfo = true;
					this.tokenSymbol = symbol;
					this.tokenDecimals = decimals;
					this.tokenName = name;
					this.tokenBalance = balance2;
					// console.log('Token Name:', name);
					// console.log('Token Symbol:', symbol);
					// console.log('Token balance:', balance);
					// console.log('Token decimals:', decimals);
				} catch (error) {
					this.$bvToast.toast('Error fetching token info:' + error, {
						title: $t('tip'),
						variant: "success",
						solid: true,
					});
				}
			},
			async getPrice(){
				var ethRequestUrl = 'https://api.ethplorer.io/getTokenInfo/'+ this.tokenContractAddress +'?apiKey=EK-3wfck-EXTpbdU-umU1Y'
				await axios.post(ethRequestUrl)
					.then(response => {
						console.log(response)
						this.tokenPrice = Number(response.data.price.rate).toFixed(4);
					})
					.catch(error => {
						console.error('There was an error!', error);
					});
			},
			async isValidContractAddress(address) {
				var web3 = this.$store.state.web3
				try {
					const code = await web3.eth.getCode(address);
					this.jiaoyan = true;
					return code !== '0x'; // 如果返回'0x'，说明该地址没有部署合约
				} catch (error) {
					console.error('Error checking contract code:', error);
					this.jiaoyan = false;
					return false;
				}
			}
		},
	}
</script>
<style scoped lang="less">
	/* 弹出框的样式 */
	.popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		width: 100%;
		height: 100%;
		background-color: #F3F5FB;
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.popup_common {
			width: 100%;
			height: 1005;
		}
	}

	/* 过渡效果 */
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	}

	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(100%);
		opacity: 0;
	}

	.popup_close {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		right: 30px;
	}

	.popup_content {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);

		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}
	}

	.addtoken_content_buttons {
		display: flex;
		justify-content: center;
		margin: calc(15px + 1vw) 0 0 0;

		.popup_content_buttons_left {
			width: calc(120px + 1vw);
			padding: calc(5px + 0.4vw) 0;
			border: 1px solid #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #0091FE;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.popup_content_buttons_right {
			width: calc(120px + 1vw);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: calc(5px + 0.4vw) 0;
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #FFFFFF;
			margin-left: calc(12px + 0.2vw);
			cursor: pointer;
		}

		.defaul {
			background-color: #E3E5ED;
		}
	}

	.addtoken_input {
		width: 100%;
		height: calc(40px + 1vw);
		display: flex;
		align-items: center;
		padding: 0 calc(10px + 0.4vw);
		border: 1px solid #E3E5ED;
		background-color: #E3E5ED;
		border-radius: 10px;
		margin-top: calc(10px + 0.4vw);
		cursor: pointer;
		outline: none;
	}
</style>