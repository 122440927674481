import Vue from 'vue'
import App from './App.vue'
import router from './router';

import i18n from './i18n';

// Import Bootstrap and BootstrapVue CSS files
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import store from './store.js'
// Import BootstrapVue
import BootstrapVue from 'bootstrap-vue';

import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	store,
	render: h => h(App),
}).$mount('#app')