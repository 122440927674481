export default {
  ethPackage: "내 아이스 월렛",
  cancel: "취소",
  add: "추가",
  tip: "팁",
  input_password: "비밀번호 입력",
  please_enter_password: "비밀번호를 입력해 주세요",
  back: "뒤로",
  copy_success: "복사 성공",
  upload_keystore: "Keystore 파일을 업로드하고 비밀번호를 입력해 주세요",
  no_mnemonic: "니모닉 없음",
  file_parse_failed: "파일을 파싱하는 데 실패했습니다. 비밀번호를 확인해 주세요",
  wallet_address_parse_failed: "지갑 주소를 파싱하는 데 실패했습니다. 개인 키의 신뢰성을 확인해 주세요!",
  wallet_generation_failed: "지갑 생성에 실패했습니다",
  step_1: "1",
  step_2: "2",
  step_2_label: "단계 2",
  input_password_to_unlock: "지갑을 잠금 해제하기 위해 비밀번호를 입력해 주세요",
  next_step: "다음 단계",
  Accessmywallet:'내 지갑 방문',
  'Trusted by millions': '완전한 분산형 지갑',
'worldwide since 2015': '오프라인 운영이 가장 안전한 방법입니다.',
'Store': '오프라인 운영',
'Ethereum': '이더리움 생태계',
'safely': '내 아이스 월렛 커뮤니티',
'Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.': '내 아이스 월렛은 오프라인에서 지갑 생성을 지원하여 귀하의 지갑이 가장 안전한 상태임을 보장합니다.',
'Stay in control': '네트워크를 차단하고 오프라인에서 지갑을 관리하세요.',
'We secure your': '내 아이스 월렛은 완전한 분산형 지갑입니다. 우리는 귀하의 지갑을 관리할 수 없으며, 지갑의 유일한 주인은 오직 귀하 자신입니다. 지갑을 적절히 보관하고, 네트워크에서 분리하여 오프라인으로 지갑을 관리하는 것이 가장 안전한 방법입니다.',
'Buy, sell, and': '크로스 체인 통화',
'swap safely': '안전한 거래',
'Convert USD, EUR': '우리는 통합된 분산형 파트너를 통해 암호화폐를 교환할 수 있으며, 수백 가지 암호화폐와 법정 화폐를 교환할 수 있습니다. 중앙 집중식 거래소에 의존할 필요가 없습니다.',
'Easy asset': '이더리움 생태계',
'management': 'Web3 개발 커뮤니티',
'Compare and secure the': '이더리움 생태계는 전체 Web3에서 가장 큰 개발 커뮤니티를 보유하고 있으며, 내 아이스 월렛은 가장 신뢰할 수 있고 검증된 이더리움 지갑입니다.',
'How to secure your Ethereum wallet': '이더리움 지갑을 보호하는 방법',
'Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.': '네트워크에서 분리하여 오프라인으로 지갑을 생성하고, 인터넷에서 지갑을 저장하는 것이 가장 안전한 방법입니다. 의심스러운 링크를 클릭하지 마세요. 그것은 귀하의 자산을 훔치려는 피싱 시도일 수 있습니다. 주의해 주세요. 내 아이스 월렛은 결코 귀하에게 지갑 정보를 요구하지 않습니다. 질문이 있으시면 지원 팀에 문의해 주세요.',
'Frequently asked questions': 'よくある質問',
'Supporting all ERC-20 tokens': 'ERC-20 토큰 전부 지원',
'If it is on Ethereum': '내 아이스 월렛은 이더리움 블록체인상의 모든 토큰을 지원하며, 사용자 지정 토큰을 수동으로 추가할 필요가 없습니다.',
'Grab control of your future': '자신의 미래를 잡아라',
'Create a new wallet': '새로운 지갑을 생성',
'Get better at crypto': '더 나아지기 위해 귀하의 소중한 의견을 주시기 바랍니다.',
'Level up your skills with security tips, industry insights, new and more!': '우리는 겸손하게 귀하의 의견을 받아들이고 더 나아지도록 노력하겠습니다!',
'Enter your email': '이메일을 입력',
'Sign me up!': '문의하기',
'Warning: Trading cryptocurrencies involves significant high risks.': '경고: 지갑 파일의 비밀키 정보를 절대 인터넷에 공개하지 마십시오!',
'BTC Direct was rated a 4.5 out of 5 based on 10475 reviews': 'MyIceWallet은 15,175개의 리뷰를 기반으로 4.7/5의 평가를 받았습니다.',
'© 2013 - 2024 - BTC Direct Europe B.V.': '© 2024 MyIceWallet. All rights reserved',
'Sitemap': '사이트맵',
'Disclaimer': '면책조항',
'Terms of Service': '서비스이용약관',
'Privacy Policy': '개인정보보호정책',
'Fiat onramp': '자주 묻는 질문 (FAQ)',
'Back up your wallet': '지갑을 백업',
'Never share your private key': '개인키를 절대 공유하지 마세요',
'Keep your wallet and device software up to date': '낯선 컴퓨터나 스마트폰에서 지갑을 실행하지 마세요.',
'Be cautious of phishing scams': '피싱 사기에 경계심을 갖야 합니다',
'What is a Ethereum wallet?': '이더리움 지갑이란?',
'Are Ethereum wallets free?': '이더리움 지갑은 무료인가?',
'What is the safest Ethereum Wallet?': '가장 안전한 이더리움 지갑은 무엇인가?',
'Which app can I use to create my Ethereum wallet?': '내 이더리움 지갑을 생성하는 데에 어떤 앱을 사용할 수 있나요?',
'boots1': "여러 개의 안전한 백업을 생성하여 귀하의 자산을 보호하세요. 내 아이스 월렛은 귀하의 개인 키 복사본을 관리하거나 보관하지 않으므로, 백업은 안전한 장소에 보관하세요. 개인 키나 복구 구문은 종이에 손으로 쓰거나 인쇄하는 것이 좋으며, 이메일이나 클라우드 저장소에는 저장하지 마세요!",
'boots2': "귀하의 개인 키(암호 절어어드로도 불림)는 지갑의 주 개인 키입니다. 이를 공유하는 것은 자산의 관리권을 타인에게 넘겨주는 것과 같습니다. 귀하의 개인 키를 철저히 비밀로 유지하시고, 온라인상에서나 문자로도 공유하지 마시고, 지갑의 안전성과 완전성을 유지하십시오.",
'boots3': "낯선 컴퓨터나 스마트폰에서 지갑을 사용하지 마세요. 낯선 환경의 바이러스에 주의하여 귀하의 자산을 보호하세요.",
'boots4': "지갑 정보를 요구하는 비공식적인 메시지나 이메일에 항상 주의하세요. 출처를 확인하고, 의심스러운 링크는 클릭하지 마세요. 그것들은 귀하의 자산을 훔치려는 피싱 시도일 수 있습니다. 내 아이스 월렛은 결코 귀하에게 개인 키나 복구 구문을 요청하지 않습니다. 질문이 있으시면 지원 팀에 문의해 주세요.",
'boots5': "이더리움 지갑은 이더리움을 안전하게 구매, 저장 및 관리할 수 있는 디지털 도구입니다. 이더리움을 송수신하고 구매, 판매, 교환 등의 기능에 접근할 수 있습니다. 내 아이스 월렛은 완전한 분산형 지갑으로, 지갑 파일과 개인 키, 복구 구문을 이더리움 공식 클라이언트에 임포트하여 사용할 수 있습니다.",
'boots6': "네, 내 아이스 월렛은 무료입니다. 하지만 이더리움 구매나 송금과 같은 거래에는 네트워크 수수료가 발생할 수 있습니다. 내 아이스 월렛은 초보자와 경험이 풍부한 암호화폐 애호가들에게 이더리움과 기타 암호화폐를 관리할 수 있는 안전한 플랫폼을 제공합니다.",
'boots7': "안전한 이더리움 지갑은 당신이 개인 키를 완전히 제어하고 자산 보호에 도움이 되는 기능을 제공하는 지갑입니다. 내 아이스 월렛은 안전한 이더리움 저장소를 제공함으로써 이러한 기준을 충족하는 데 전념하고 있습니다. 당신은 개인 키와 복구 구문을 관리할 수 있으며, 오직 당신만이 자산에 접근할 수 있습니다. 내 아이스 월렛이 항상 당신과 함께합니다.",
'boots8': "내 아이스 월렛은 완전한 분산형 지갑으로, 지갑 파일과 개인 키, 복구 구문을 이더리움 공식 클라이언트 지갑에 임포트하여 상호 작용할 수 있습니다. 어떤 이더리움 지갑을 사용하든 반드시 분산형 지갑을 선택하세요!",
  components: {
    add_custom_token: "커스텀 토큰 추가",
    enter_correct_address: "올바른 계약 주소를 입력해 주세요",
    select_network: "네트워크 선택",
    enter_contract_addressm: "계약 주소를 입력해 주세요",
    about_us: "우리에 대해",
    advertise_with_us: "우리와 함께 광고하기",
    privacy: "개인정보 보호",
    terms: "이용 약관",
    help_center: "도움 센터",
    frequently_asked_questions: "자주 묻는 질문",
    join_community: "커뮤니티 참여",
    buy_crypto: "암호화폐 구매",
    swap_tokens: "토큰 스왑",
    more_features: "더 많은 기능",
    resources: "리소스",
    products: "제품",
    access_my_wallet: "내 지갑에 접근하기",
    go_to: "로 가기",
    mew_mobile: "MEW 모바일",
    encrypt: "암호화",
    ethereum_virtual_machine: "이더리움 가상 머신",
    select_software_wallet: "소프트웨어 지갑 선택",
    keystore: "키스토어",
    mnemonic: "니모닉",
    private_key: "개인 키",
    save_wallet_warning: "지갑을 오프라인으로 저장하고 USB 드라이브에 백업하며, 개인 키와 니모닉을 종이에 적어 두세요. 이메일이나 클라우드 저장소에 저장하지 마세요.",
    access_wallet_with_keystore: "키스토어 파일로 지갑에 접근하기",
    select_file: "파일 선택",
    input_password: "비밀번호 입력",
    step_1: "단계 1",
    select_keystore_file: "키스토어 파일 선택",
    select_keystore_file_warning: "지갑을 잠금 해제하기 위한 키스토어 파일을 선택해 주세요",
    not_recommended: "추천하지 않음",
    access_wallet: "지갑에 접근하기",
    access_wallet_with_mnemonic: "니모닉으로 지갑에 접근하기",
    input_phrase: "구문 입력",
    address_and_network: "주소 및 네트워크",
    input_mnemonic: "니모닉을 입력해 주세요",
    input_mnemonic_order: "올바른 순서로 니모닉을 입력해 주세요.",
    select_address_and_network: "주소 및 네트워크 선택",
    address: "주소",
    amount: "금액",
    network: "네트워크",
    access_wallet_with_private_key: "개인 키로 지갑에 접근하기",
    input_private_key: "개인 키를 입력해 주세요",
    please_enter_private_key: "개인 키를 입력해 주세요",
  },
  mps: {
    my_public_address: "자금을 받을 내 공개 주소",
    receive_token_instruction: "다른 계정에서 TOKEN을 받으려면, 해당 계정에서 이 주소로 TOKEN을 보내 주세요.",
    copy: "복사",
    my_paper_wallet: "내 종이 지갑",
    paper_wallet: "종이 지갑",
    my_wallet_address: "내 지갑 주소",
    my_private_key: "내 개인 키",
    private_key_warning: "이 개인 키를 누구와 공유하면 자금을 잃을 수 있습니다! 개인 키는 안전한 곳에 보관해 주세요!",
    print: "인쇄",
    settings: "설정",
    wallet_address: "지갑 주소",
    max_wallets: "최대 10개의 지갑을 추가할 수 있습니다.",
    enter_wallet_address: "지갑 주소를 입력해 주세요",
    confirm_add: "추가 확인",
    currency_setting: "통화 설정",
    usd: "미국 달러",
    jpy: "일본 엔",
    normal_priority: "정상 우선",
    higher_priority: "높은 우선",
    highest_priority: "최고 우선",
    fifteen_minutes: "15분",
    wallet_address_exists: "지갑 주소가 이미 존재합니다",
  },
  chdhl: {
    create_new_wallet: "새 지갑 만들기",
    select_wallet_creation_method: "새 지갑을 만들 방법을 선택해 주세요",
    already_have_wallet: "이미 지갑이 있습니까?",
    access_wallet: "지갑에 접근하기",
    keystore_file: "키스토어 파일",
    keystore_warning: "온라인에서 키스토어 파일을 사용하면 자금을 잃을 위험이 높아집니다. 이 방법으로 지갑을 만드는 것은 추천하지 않습니다.",
    mnemonic_phrase: "니모닉 구문",
    mnemonic_warning: "온라인에서 니모닉을 사용하면 자금을 잃을 위험이 높아집니다. 이 방법으로 지갑을 만드는 것은 추천하지 않습니다.",
    return_to_home: "홈으로 돌아가기",
    not_recommended: "추천하지 않음",
    offline_storage_advice: "지갑을 오프라인으로 저장하고 USB 드라이브에 백업을 만들고, 개인 키와 니모닉을 종이에 적어 두세요. 이메일이나 클라우드 저장소에 저장하지 마세요!",
    enter_password: "비밀번호 입력",
    download_file: "파일 다운로드",
    step_3: "3",
    reenter_password: "비밀번호를 다시 입력해 주세요",
    create_wallet: "지갑 만들기",
    download_keystore_file: "키스토어 파일 다운로드",
    important_info_before_download: "키스토어 파일 다운로드 전에 알아야 할 중요한 정보",
    dont_lose_it: "잃어버리지 마세요",
    be_careful: "주의하세요, 잃어버리면 복구할 수 없습니다.",
    do_not_share: "공유하지 마세요",
    phishing_warning: "이 파일을 악성 피싱 사이트에서 사용하면 자금이 도난당할 수 있습니다.",
    make_a_backup: "백업을 만들기",
    protect_it: "언젠가 수백만 달러의 가치가 있을 수 있으니 보호하세요.",
    confirm_download: "다운로드 확인",
    step_3s: "단계 3",
    well_done: "잘 했습니다!",
    ready_to_use: "이제 이더리움이 제공하는 모든 것을 활용할 준비가 되었습니다. 오프라인 설정에서만 키스토어 파일을 사용합니다.",
    create_another_wallet: "다른 지갑 만들기",
    write_down_phrase: "구문 적어 두기",
    verify_phrase: "구문 확인",
    update: "업데이트",
    write_them_down: "적어 두기",
    select_correct_words: "번호에 따라 올바른 단어를 선택하고 여분의 단어를 입력해 주세요.",
    verify: "확인",
    password_mismatch: "비밀번호가 일치하지 않습니다",
    verification_failed: "확인 실패",
    keystore_generation_failed: "키스토어 파일 생성 실패",
  },
  help_detail: {
    search_articles: "기사 검색…",
    all_series: "모든 시리즈",
    introduction: "소개",
    overview: "개요",
    about_mew: "MEW에 대한 모든 정보 소개.",
    author: "저자",
    updated_week_ago: "1주일 전에 업데이트됨",
    description: "비관리형 오픈 소스 클라이언트 인터페이스로, 사용자가 이더리움 블록체인과 직접 상호작용하고 암호 자산을 완전히 제어할 수 있게 합니다. MEW는 웹 지갑, iOS 및 Android용 모바일 지갑 앱, 브라우저 지갑 확장으로 사용할 수 있습니다.",
    wallet_app: "지갑 앱",
    mobile_wallet_guide: "모바일 MEW 지갑 사용 가이드",
    encryption: "암호화",
    enkrypt_resources: "Enkrypt 웹 브라우저 확장 지갑의 리소스 및 가이드.",
    security_privacy: "보안 및 개인정보 보호",
    wallet_security: "MEW에서 지갑을 안전하게 유지하는 방법.",
    access_wallet: "지갑에 접근",
    access_methods: "지원되는 방법을 사용하여 MEW에서 지갑에 접근하는 방법.",
    send_transaction: "거래 전송",
    send_transaction_guide: "MEW에서 거래를 전송하는 방법",
    exchange: "교환",
    exchange_guide: "우리의 교환 파트너가 제공하는 MEW에서의 교환 방법.",
    tokens: "토큰",
    token_interaction: "MEW에서 토큰과 상호작용하는 방법.",
    decentralized_apps: "분산형 앱",
    dapp_interaction: "MEW를 사용하여 DApps에 연결하고 상호작용하는 방법.",
    mew_offline: "MEW 오프라인",
    offline_usage: "버전 5에서 MEW를 오프라인으로 사용하는 방법. MEW 버전 6은 아직 오프라인 기능을 지원하지 않습니다.",
    networks_nodes: "네트워크 및 노드",
    connect_networks: "다양한 네트워크 및 노드에 연결하는 방법.",
  },
  help_list: {
    and: "와",
    other: "다른",
    articles: "기사",
    return_to_basics: "기본으로 돌아가기：",
    mew_tips: "MEW의 팁",
    exchange_difference: "거래소(예: Coinbase, Kraken, Gemini, Binance)와 MyEtherWallet의 차이점은 무엇인가요?",
    get_started: "시작하기",
    create_wallet: "MEW를 사용하여 이더리움 지갑을 만드는 방법",
    transfer_crypto: "Binance에서 Enkrypt 또는 MEW 지갑으로 암호화폐를 전송하는 방법",
    create_cold_wallet: "콜드 지갑(종이 지갑이라고도 함)을 만드는 방법",
    buy_sell_crypto: "MEW 포트폴리오를 사용하여 암호화폐를 사고 파는 방법",
    what_is_non_custodial_wallet: "비관리형 지갑이란 무엇인가요?",
    check_balance: "이더리움 블록체인에서 잔액을 확인하는 방법",
    explore_deeply: "깊이 탐색하기",
    crypto_terms: "이더리움/암호화폐 커뮤니티에서 일반적으로 사용되는 용어집",
    what_if_mew_fails: "MEW가 실패하면 어떻게 되나요?",
    does_mew_support_btc: "MEW는 비트코인(BTC) 또는 다른 통화를 지원하나요?",
    what_is_eth_smart_contract: "이더리움 스마트 계약이란 무엇인가요?",
    error_window_blocked: "오류: 창이 차단되었습니다",
    ensure_unique_address: "MEW가 다른 사람에게 동일한 공개 주소를 제공하지 않도록 확인하세요",
    submit_pull_request: "MEW의 GitHub에 풀 요청을 제출하는 방법",
    does_mew_have_api: "MyEtherWallet에는 API가 있나요?",
    mew_api_request_limit: "MEW API에 대해 보낼 수 있는 요청 수에 제한이 있나요?",
    use_mew_as_pwa: "MEW를 점진적 웹 앱(PWA) 또는 Chrome 앱으로 사용하는 방법",
  },
  index: {
    mew_team_advice: "MyEtherWallet 팀의 조언 및 답변",
    most_reputable_wallet: "내 아이스 월렛은 이더리움 생태계의 모든 ERC20 토큰 저장 및 전송을 지원합니다.",
    create_new_wallet: "새 지갑 만들기",
    or: "또는",
    access_my_wallet: "내 지갑에 접근하기",
    our_products_make_crypto_easier: "당신은 자신의 키 관리, 복구 구문, 개인 키를 독점적으로 관리합니다.",
    available_on_mobile_and_desktop: "네트워크에서 분리하여 지갑을 저장하세요. 이메일이나 클라우드 저장소에 저장하지 않도록 주의하세요!",
    mew_mobile_app: "MEW 모바일 앱",
    stake_swap_manage_crypto: "언제 어디서나 암호화폐를 스테이크, 스왑 및 관리하세요.",
    security_like_hardware_wallet: "하드웨어 지갑에 필적하는 보안으로 모바일 암호화폐를 보호하세요.",
    powerful_nft_management: "강력한 NFT 관리, 온체인 브라우저 등.",
    want_to_enter_crypto: "암호화폐 세계에 들어가고 싶으신가요?",
    mew_is_best_choice: "MEW가 당신의 최선의 선택입니다.",
    ethereum_ecosystem: "이더리움 생태계는 전체 web3에서 가장 큰 개발자 커뮤니티를 보유하고 있습니다.",
    trusted_ethereum_wallet: "MEW는 가장 신뢰받고 검증된 이더리움 지갑 중 하나입니다.",
    not_investment_advice: "투자 조언이 아닙니다. 모든 결정은 사용자의 책임입니다.",
    truly_own_your_crypto: "진정으로 당신의 암호 자산을 소유하세요.",
    self_custody_wallet: "MEW는 자가 보관 지갑으로, 당신이 자신의 키를 보유하고 있다는 의미입니다. 다른 누구도 (MEW 팀조차도) 당신의 암호화폐에 접근할 수 없습니다.",
    buy_store_send_swap_tokens: "토큰을 구매, 저장, 전송 및 스왑하세요",
    store_tokens: "토큰 저장",
    buy_sell: "구매 및 판매",
    swap: "스왑",
    mew_supports_eth_and_erc20: "MEW는 ETH 및 모든 ERC-20 토큰을 지원합니다!",
    get_tokens: "토큰 받기",
    make_crypto_yours: "당신의 암호화폐를 진정으로 당신의 것으로 만드세요.",
    stake_your_eth: "ETH를 스테이크하고 이더리움 블록체인 보상을 받으세요.",
    be_your_own_bank: "자신의 은행이 되세요",
    crypto_as_banking: "자금을 대출하는 대형 금융 은행과는 달리, 암호화폐 세계에서는 당신이 은행입니다. 이더리움을 안전하게 보호하여 보상을 받으세요.",
    staking_made_easy: "스테이킹이 쉬워졌습니다",
    easy_staking_with_mew: "MEW에서는 지갑에서 암호화폐를 쉽게 스테이크하고 즉시 보상을 시작할 수 있습니다.",
    start_staking_now: "지금 스테이킹 시작하기",
    staking: "스테이킹",
    no_minimum_liquidity: "최소 유동성 스테이킹 요구 없음",
    annual_rate_up_to: "연 이율 최대",
    powerful_wallet: "모든 암호화폐 요구를 충족하는 강력한 지갑.",
    store_all_your_nfts: "다양한 체인에서 모든 NFT를 한 곳에 안전하게 보관하세요. 모바일 또는 데스크톱에서.",
    access_web3_apps: "Web3 앱에 접근하세요. 데스크톱에서 Enkrypt를 사용하여 좋아하는 Web3 앱을 사용하거나 모바일 앱에서 MEW를 사용하여 좋아하는 Web3 앱에 접근하세요.",
    multi_chain_access: "다중 체인 접근. 분산형 네트워크에 연결하고 인기 있는 네트워크 간에 자산을 브리징하세요.",
    why_mew_is_good_choice: "왜 MEW가 좋은 선택인가요?",
    crypto_expert_since_2015: "2015년부터 암호화폐 전문가",
    first_trusted_wallet: "MEW는 세계 최초의 신뢰할 수 있는 이더리움 및 L2 지갑 중 하나입니다.",
    private: "비공식",
    no_tracking: "우리는 개인 정보, 계정 주소 또는 자산 잔액을 추적하지 않습니다.",
    hardware_wallet_support: "하드웨어 지갑 지원",
    support_major_hardware_wallets: "MEW 네트워크 제품군 및 Enkrypt에서 Ledger 및 Trezor를 포함한 모든 주요 하드웨어 지갑을 지원합니다.",
    secure_transparent: "안전하고 투명",
    independent_audits: "HackenProof 및 이전 HackerOne 버그 바운티 프로그램에서 독립 감사 받음.",
    self_custody: "자가 보관",
    you_control_your_assets: "오직 당신만이 당신의 자산을 통제합니다. 당신의 키, 당신의 암호화폐.",
    easy_to_use: "사용하기 쉬움",
    start_in_minutes: "몇 분 안에 시작할 수 있으며, 사전 지식이 필요하지 않습니다.",
    arm_yourself_with_knowledge: "지식으로 무장하세요.",
    new_to_crypto: "암호화폐에 처음 접하시나요? MEWtopia를 탐색하세요!",
    free_resources: "우리의 무료 리소스 라이브러리를 활용하여 이더리움을 최대한 활용하는 방법을 배우세요!",
    help_center: "도움 센터",
    faq: "자주 묻는 질문",
    common_crypto_questions: "암호화폐에 대한 가장 일반적인 질문",
    chat_with_support: "지원팀과 채팅하기",
    get_human_help: "실제 사람의 도움을 받으세요",
    create_new_wallet: "새 지갑 만들기",
    ethereum_eth: "이더리움(ETH)",
    ondo: "온도(ONDO)",
    unibot: "유니봇(UNIBOT)",
    busd: "바이낸스 USD(BUSD)",
    icx: "아이콘(ICX)",
    grt: "더 그래프(GRT)",
    aurora: "오로라(AURORA)",
    root: "루트 네트워크(ROOT)",
    lime: "IME 연구소(LIME)",
  },
  package: {
    ethereum: "이더리움",
    buy_store_send: "구매, 저장, 전송",
    usd: "미국 달러",
    swap_tokens: "토큰 스왑",
    mew_supports_eth_and_erc20: "MEW는 ETH와 모든 ERC-20을 지원합니다",
    make_your_crypto_yours: "당신의 암호화폐를 진정으로 당신의 것으로 만드세요.",
    tokens: "토큰!",
    cryptocurrency: "암호화폐.",
    meet_all_your_crypto_needs: "모든 암호화폐 요구를 충족합니다.",
    why_mew_is_good_choice: "왜 MEW가 좋은 선택인가요?",
    buy_hardware_wallet: "하드웨어 지갑 구매",
    portfolio_value: "지갑 정보를 확인하세요",
    wallet: "지갑",
    send: "전송",
    settings: "설정",
    logout: "로그아웃",
    other_apps: "다른 앱",
    apps: "앱",
    nft_manager: "NFT 관리자",
    exchange: "교환",
    stake: "스테이크",
    received: "수신됨",
    buy_sell: "구매 및 판매",
    contract: "계약",
    info: "정보",
    new_feature_stake_any_amount_of_eth: "낯선 링크, 낯선 환경, 낯선 QR 코드에 주의하세요",
    stake_eth_no_minimum: "당신의 지갑 키 저장소, 시드 구문, 개인 키를 누설하지 마세요. 임의로 권한을 부여하지 마세요.",
    stake_now: "지금 스테이크",
    advertise_with_us: "우리와 광고하기",
    network: "네트워크",
    block_height: "블록 높이:",
    total_assets: "총 자산:",
    add_custom_token: "커스텀 토큰 추가",
    send: "전송",
    delete: "삭제",
    transaction_history: "거래 내역",
    transfer_out: "전송",
    transfer_in: "전입",
    no_transactions: "아직 거래가 없습니다",
    are_you_sure_logout: "정말 로그아웃 하시겠습니까?",
    are_you_sure_delete_token: "현재 토큰을 삭제하시겠습니까?",
    tx_hash: "TX 해시",
    transaction_time: "거래 시간",
    from: "에서",
    to: "으로",
    amount: "금액",
    fee: "수수료",
    token: "토큰",
    hold: "보유",
    price: "가격",
    years_ago: "년 전",
    months_ago: "개월 전",
    days_ago: "일 전",
    hours_ago: "시간 전",
    minutes_ago: "분 전",
    seconds_ago: "초 전",
    failed_to_get_height: "높이 가져오기 실패",
  },
  send: {
    line1: "왜 MEW가",
    line2: "좋은 선택인가요?",
    line3: "작업 팁",
    line4: "확인",
  },
  zhensend: {
    balance: "잔고:",
    max_limit: "최대 한도",
    your: "당신의",
    low_balance: "잔고가 너무 낮습니다",
    transaction_fee: "각 거래에는 소량이 필요합니다",
    cannot_send: "를 실행하기 위해. 교환할 토큰이 있더라도,",
    near_zero_balance: "잔고가 거의 0에 가까울 경우, 계좌에 자금을 추가할 때까지 아무 것도 보낼 수 없습니다.",
    enter_address: "전송 주소를 입력해 주세요",
    fee: "거래 수수료",
    total: "합계:",
    insufficient_fee: "네트워크 수수료를 지불하기에 불충분합니다.",
    custom: "커스텀",
    max_fee: "최대 수수료",
    max_tip: "최대 팁",
    send_transaction: "거래 전송",
    zero_balance: "토큰 잔고가 0입니다~",
    enter_amount: "전송 금액을 입력해 주세요",
    insufficient_token_balance: "토큰 잔고가 부족합니다",
    transaction_cost: "거래 수수료는",
    transaction_success: "거래 성공",
    insufficient_gas: "GAS가 부족합니다",
    transaction_failed: "거래 실패, 입력 내용을 확인해 주세요",
    gas_limit_failed: "Gas Limit 추정 실패",
    token_amount_prompt: "수량을 입력하세요"
  }
};
