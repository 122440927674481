<template>
	<div style="position: relative;z-index: 1;">
		<b-container class="bv-example-row">
			<b-row class="text-center" align-h="center">
				<b-col cols="12" sm="12" md="10" class="header_view">
					<b-navbar toggleable="lg" type="dark">
						<b-navbar-brand href="#" class="mr-auto">
							<img src="../../static/logo.png" alt="Kitten" class="header_view_logo" @click="goHome()">
						</b-navbar-brand>
						<b-navbar-toggle target="nav-collapse" class="ms-auto"
							style="background-color: #1A183F;"></b-navbar-toggle>
						<b-collapse id="nav-collapse" is-nav align-h="center">
							<b-navbar-nav class="ml-auto" style="align-items: center;">
								<b-nav-item href="#">
									<div class="header_view_nav">{{ $t('components.buy_crypto') }}</div>
								</b-nav-item>
								<b-nav-item href="#">
									<div class="header_view_nav">{{ $t('components.swap_tokens') }}</div>
								</b-nav-item>
								<b-nav-item href="#">
									<div class="header_view_nav">{{ $t('components.more_features') }}</div>
								</b-nav-item>
								<b-nav-item href="#">
									<div class="header_view_nav">{{ $t('components.resources') }}</div>
								</b-nav-item>
								<b-nav-item href="#">
									<div class="header_view_nav">{{ $t('components.products') }}</div>
								</b-nav-item>
								<b-nav-item-dropdown :text="languageText" right class="custom-dropdown">
									<b-dropdown-item>
										<div class="header_view_nav" style="text-align: center;"
											@click="swichLanguage('zh')">中文简体</div>
									</b-dropdown-item>
									<b-dropdown-item>
										<div class="header_view_nav" style="text-align: center;"
											@click="swichLanguage('en')">ENGLISH</div>
									</b-dropdown-item>
									<b-dropdown-item>
										<div class="header_view_nav" style="text-align: center;"
											@click="swichLanguage('ja')">JAPANESE</div>
									</b-dropdown-item>
									<b-dropdown-item>
										<div class="header_view_nav" style="text-align: center;"
											@click="swichLanguage('ko')">KOREAN</div>
									</b-dropdown-item>
									<b-dropdown-item>
										<div class="header_view_nav" style="text-align: center;"
											@click="swichLanguage('ru')">RUSSIAN</div>
									</b-dropdown-item>
								</b-nav-item-dropdown>
								<b-nav-item @click="findMyPacage()">
									<div class="header_view_button">{{ $t('components.access_my_wallet') }}</div>
								</b-nav-item>
							</b-navbar-nav>
						</b-collapse>
					</b-navbar>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'MyHeader',
		data(){
			return {
				languageText:'中文简体'
			}
		},
		created(){
			if(this.$i18n.locale == 'en'){
				this.languageText = 'ENGLISH'
			}else if(this.$i18n.locale == 'zh'){
				this.languageText = '中文简体'
			}else if(this.$i18n.locale == 'ja'){
				this.languageText = 'JAPANESE'
			}else if(this.$i18n.locale == 'ko'){
				this.languageText = 'KOREAN'
			}else if(this.$i18n.locale == 'ru'){
				this.languageText = 'RUSSIAN'
			}
		},
		methods: {
			goUrl(url) {
				this.$router.push({
					path: url
				})
			},
			findMyPacage(){
				console.log('123')
				this.$emit('findPacage');
			},
			swichLanguage(lang) {
				this.$i18n.locale = lang;
				if(this.$i18n.locale == 'en'){
					this.languageText = 'ENGLISH'
				}else if(this.$i18n.locale == 'zh'){
					this.languageText = '中文简体'
				}else if(this.$i18n.locale == 'ja'){
				    this.languageText = 'JAPANESE'
			    }else if(this.$i18n.locale == 'ko'){
				    this.languageText = 'KOREAN'
			    }else if(this.$i18n.locale == 'ru'){
				    this.languageText = 'RUSSIAN'
			    }
			},
			goHome(){
				if(this.$route.path == '/'){
					return;
				}
				this.$router.push({
					path: '/'
				})
			}
		},
	}
</script>
<style scoped lang="less">
	.header_view {
		background: rgba(255, 255, 255, 0.5);
		box-shadow: 0px 4px 4px 0px rgba(158, 188, 254, 0.2);
		border-radius: 0px 0px 24px 24px;
	}
	.header_view_logo {
		width: 118px;
		height: 32px;
	}
	.header_view_button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: #1A183F;
		color: #ffffff;
		border-radius: 8px;
		font-size: 16px;
		padding: 8px 20px;
		letter-spacing: 1px;
	}
	.header_view_nav {
		color: #1A183F;
		margin-right: 15px;
	}
</style>