export default {
  ethPackage: "MyIceWallet",
  cancel: "Cancel",
  add: "Add",
  tip: "Tip",
  input_password: "Input Password",
  please_enter_password: "Please enter your password",
  back: "Back",
  copy_success: "Copy successful",
  upload_keystore: "Please upload the Keystore file and enter your password",
  no_mnemonic: "No mnemonic",
  file_parse_failed: "Failed to parse file, please check the password",
  wallet_address_parse_failed: "Failed to parse wallet address, please confirm the authenticity of the private key!",
  wallet_generation_failed: "Wallet generation failed",
  step_1: "1",
  step_2: "2",
  step_2_label: "Step 2",
  input_password_to_unlock: "Enter your password to unlock the wallet",
  next_step: "Next Step",
  Accessmywallet:'Access my wallet',
  'Trusted by millions':'Fully decentralized wallet.',
  'worldwide since 2015':'Offline operation is the safest method.',
  'Store':'Generate wallet offline.',
  'Ethereum':'Supports ERC20 tokens.',
  'safely':'MyIceWallet Community.',
  'Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.':'MyIceWallet supports offline wallet generation, ensuring that your wallet is in the safest state.',
  'Stay in control':'Please disconnect from the internet to manage your wallet.',
  'We secure your':'MyIceWallet is a fully decentralized wallet. We cannot manage your wallet for you; the only owner of the wallet is you. Safeguard your wallet properly. The safest way to manage your wallet is to disconnect from the internet and operate it offline.',
  'Buy, sell, and':'Cross-chain currencies.',
  'swap safely':'Secure exchange.',
  'safely':"MyIceWallet Community.",
  'Convert USD, EUR':'Exchange cryptocurrencies through our integrated decentralized partners. Hundreds of cryptocurrencies and fiat currencies are available for exchange, without relying on centralized exchanges.',
  'Easy asset':'Ethereum ecosystem.',
  'management':"Web3 development community.",
  'Compare and secure the':'The Ethereum ecosystem has the largest development community in the entire Web3 space, and My Ice Wallet is the most trusted and proven Ethereum wallet.',
  'How to secure your Ethereum wallet':'How to secure your Ethereum wallet',
  'Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.':'Generate your wallet offline by disconnecting from the internet. Storing your wallet offline is the safest method. Do not click on suspicious links, as they may be phishing attempts to steal your assets. Please note that My Ice Wallet will never ask you for any wallet information. If you have any questions, please contact our support team.',
  'Frequently asked questions':'Frequently asked questions',
  'Supporting all ERC-20 tokens':'Supporting all ERC-20 tokens',
  'If it is on Ethereum':'MyIceWallet supports all tokens on the Ethereum blockchain without the need to manually add custom tokens.',
  'Grab control of your future':'Grab control of your future',
  'Create a new wallet':"Create a new wallet",
  'Get better at crypto':'We accept your suggestion.',
  'Level up your skills with security tips, industry insights, new and more!':'We humbly accept your feedback to help us improve!',
  'Enter your email':'Enter your email',
  'Sign me up!':'Contact us.',
  'Warning: Trading cryptocurrencies involves significant high risks.':'Warning: Do not expose your wallet private key on the internet!',
  'BTC Direct was rated a 4.5 out of 5 based on 10475 reviews':"MyIceWallet has a rating of 4.7/5 based on 15,175 reviews.",
  '© 2013 - 2024 - BTC Direct Europe B.V.':'© 2024 MyIceWallet. All rights reserved',
  'Sitemap':'Sitemap',
  'Disclaimer':"Disclaimer",
  'Terms of Service':'Terms of Service',
  'Privacy Policy':"Privacy Policy",
  'Fiat onramp':"Technical Q&A",

  'Back up your wallet':'Back up your wallet',
  'Never share your private key':"Never share your private key",
  'Keep your wallet and device software up to date':"Do not run your wallet on unfamiliar computers or phones.",
  'Be cautious of phishing scams':"Be cautious of phishing scams",
  'What is a Ethereum wallet?':"What is a Ethereum wallet?",
  'Are Ethereum wallets free?':"Are Ethereum wallets free?",
  'What is the safest Ethereum Wallet?':"What is the safest Ethereum Wallet?",
  'Which app can I use to create my Ethereum wallet?':"Which app can I use to create my Ethereum wallet?",
  'boots1':"Protect your assets by creating multiple secure backups. My Ice Wallet does not control or retain copies of your private keys, so please store your backups in a safe place. It's best to write your private keys or recovery phrases on paper or print them out, and avoid saving them in email or cloud storage!",
  'boots2':"Your secret phrase (also known as a seed phrase) is the master private key to your wallet. Sharing it would be like handing over control of your assets to someone else. Ensure you keep your secret phrase confidential, and never disclose it online or through text to maintain the security and integrity of your wallet.",
  'boots3':"Do not use unfamiliar computers or phones to run your wallet. Be vigilant about viruses in unfamiliar environments to protect the security of your assets.",
  'boots4':"Always be vigilant about unsolicited messages or emails that ask you for wallet information. Verify the source, and never click on suspicious links, as they may be phishing attempts to steal your assets. Please note that MyIceWallet will never actively ask you for your private keys or recovery phrases. If you have any questions, please contact our support team.",
  'boots5':"An Ethereum wallet is a digital tool that allows you to securely buy, store, and manage Ethereum. It enables you to send and receive Ethereum and access features like buying, selling, and exchanging. My Ice Wallet is a fully decentralized wallet, and wallet files, along with private keys and recovery phrases, can be imported for use with the official Ethereum client.",
  'boots6':"Yes, MyIceWallet is free. However, transactions such as buying or transferring Ethereum may incur network fees. My Ice Wallet provides a secure platform for both beginners and experienced cryptocurrency enthusiasts to manage their Ethereum and other cryptocurrencies.",
  'boots7':"A secure Ethereum wallet allows you to have complete control over your private keys and provides features that help protect your assets. My Ice Wallet is dedicated to meeting these standards by offering a secure storage solution for Ethereum. You have control over your private keys and recovery phrases, ensuring that only you can access your assets. My Ice Wallet is here for you.",
  'boots8':"MyIceWallet is a fully decentralized wallet, and wallet files, along with private keys and recovery phrases, can be imported into the official Ethereum client wallet for interaction. No matter which Ethereum wallet you use, always choose a decentralized wallet!",
  components: {
    add_custom_token: "Add Custom Token",
    enter_correct_address: "Please enter the correct contract address",
    select_network: "Select Network",
    enter_contract_addressm: "Please enter the contract address",
    about_us: "About Us",
    advertise_with_us: "Advertise with Us",
    privacy: "Privacy",
    terms: "Terms",
    help_center: "Help Center",
    frequently_asked_questions: "Frequently Asked Questions",
    join_community: "Join the Community",
    buy_crypto: "Buy Cryptocurrency",
    swap_tokens: "Swap Tokens",
    more_features: "More Features",
    resources: "Resources",
    products: "Products",
    access_my_wallet: "Access My Wallet",
    go_to: "Go to",
    mew_mobile: "MEW Mobile",
    encrypt: "Encrypt",
    ethereum_virtual_machine: "Ethereum Virtual Machine",
    select_software_wallet: "Select Software Wallet",
    keystore: "Keystore",
    mnemonic: "Mnemonic",
    private_key: "Private Key",
    save_wallet_warning: "Please save your wallet offline, back it up on a USB drive, and write down your private key and mnemonic on paper. Do not store them in email or cloud storage.",
    access_wallet_with_keystore: "Access Wallet with Keystore File",
    select_file: "Select File",
    input_password: "Input Password",
    step_1: "Step 1",
    select_keystore_file: "Select Your Keystore File",
    select_keystore_file_warning: "Please select the Keystore file to unlock the wallet",
    not_recommended: "Not Recommended",
    access_wallet: "Access Wallet",
    access_wallet_with_mnemonic: "Access Wallet with Mnemonic",
    input_phrase: "Input Phrase",
    address_and_network: "Address and Network",
    input_mnemonic: "Enter your mnemonic",
    input_mnemonic_order: "Please enter your mnemonic in the correct order.",
    select_address_and_network: "Select Address and Network",
    address: "Address",
    amount: "Amount",
    network: "Network",
    access_wallet_with_private_key: "Access Wallet with Private Key",
    input_private_key: "Enter your private key",
    please_enter_private_key: "Please enter your private key",
  },
  mps: {
    my_public_address: "My public address for receiving funds",
    receive_token_instruction: "To receive TOKEN from another account, please send TOKEN from that account to this address.",
    copy: "Copy",
    my_paper_wallet: "My Paper Wallet",
    paper_wallet: "Paper Wallet",
    my_wallet_address: "My Wallet Address",
    my_private_key: "My Private Key",
    private_key_warning: "You may lose your funds if you share this private key with anyone! Please keep your private key in a safe place!",
    print: "Print",
    settings: "Settings",
    wallet_address: "Wallet Address",
    max_wallets: "You can add up to 10 wallets.",
    enter_wallet_address: "Please enter wallet address",
    confirm_add: "Confirm Add",
    currency_setting: "Currency Setting",
    usd: "USD",
    jpy: "JPY",
    normal_priority: "Normal Priority",
    higher_priority: "Higher Priority",
    highest_priority: "Highest Priority",
    fifteen_minutes: "15 minutes",
    wallet_address_exists: "Wallet address already exists",
  },
  chdhl: {
    create_new_wallet: "Create New Wallet",
    select_wallet_creation_method: "Please select a method to create a new wallet",
    already_have_wallet: "Already have a wallet?",
    access_wallet: "Access Wallet",
    keystore_file: "Keystore File",
    keystore_warning: "Using a keystore file online makes your wallet more susceptible to losing funds. We do not recommend this method for creating a wallet.",
    mnemonic_phrase: "Mnemonic Phrase",
    mnemonic_warning: "Using a mnemonic online makes your wallet more susceptible to losing funds. We do not recommend this method for creating a wallet.",
    return_to_home: "Return to Home",
    not_recommended: "Not Recommended",
    offline_storage_advice: "Please save your wallet offline, back it up on a USB drive, and write down your private key and mnemonic on paper. Do not store them in email or cloud storage!",
    enter_password: "Enter Password",
    download_file: "Download File",
    step_3: "3",
    reenter_password: "Please re-enter your password",
    create_wallet: "Create Wallet",
    download_keystore_file: "Download Keystore File",
    important_info_before_download: "Important information to know before downloading the Keystore file",
    dont_lose_it: "Don't lose it",
    be_careful: "Be careful, once lost, it cannot be recovered.",
    do_not_share: "Do not share",
    phishing_warning: "If you use this file on a malicious phishing site, your funds will be stolen.",
    make_a_backup: "Make a backup",
    protect_it: "Protect it as if it could be worth millions someday.",
    confirm_download: "Confirm Download",
    step_3s: "Step 3",
    well_done: "Well done!",
    ready_to_use: "You are now ready to take advantage of everything Ethereum has to offer, using the Keystore file only in offline settings.",
    create_another_wallet: "Create Another Wallet",
    write_down_phrase: "Write down the phrase",
    verify_phrase: "Verify Phrase",
    update: "Update",
    write_them_down: "Write them down",
    select_correct_words: "Please select the correct words according to the numbers and enter any extra words.",
    verify: "Verify",
    password_mismatch: "Passwords do not match",
    verification_failed: "Verification failed",
    keystore_generation_failed: "Failed to generate Keystore file",
  },
  help_detail: {
    search_articles: "Search Articles…",
    all_series: "All Series",
    introduction: "Introduction",
    overview: "Overview",
    about_mew: "Introduction to everything about MEW.",
    author: "Author",
    updated_week_ago: "Updated a week ago",
    description: "A non-custodial open-source client interface that allows users to interact directly with the Ethereum blockchain, fully controlling their crypto assets. MEW can be used as a web wallet, a mobile wallet app for iOS and Android, and a browser wallet extension.",
    wallet_app: "Wallet App",
    mobile_wallet_guide: "Guide to using our mobile MEW wallet",
    encryption: "Encryption",
    enkrypt_resources: "Resources and guides for the Enkrypt web browser extension wallet.",
    security_privacy: "Security and Privacy",
    wallet_security: "How to keep your wallet secure on MEW.",
    access_wallet: "Access Wallet",
    access_methods: "How to access wallets on MEW using supported methods.",
    send_transaction: "Send Transaction",
    send_transaction_guide: "How to send transactions on MEW",
    exchange: "Exchange",
    exchange_guide: "How to exchange on MEW provided by our exchange partners.",
    tokens: "Tokens",
    token_interaction: "How to interact with tokens on MEW.",
    decentralized_apps: "Decentralized Apps",
    dapp_interaction: "How to connect and interact with DApps using MEW.",
    mew_offline: "MEW Offline",
    offline_usage: "How to use MEW offline on version 5. MEW version 6 does not yet support offline functionality.",
    networks_nodes: "Networks and Nodes",
    connect_networks: "How to connect to different networks and nodes.",
  },
  help_list: {
    and: "and",
    other: "other",
    articles: "articles",
    return_to_basics: "Back to Basics: A",
    mew_tips: "Tips from MEW",
    exchange_difference: "What is the difference between exchanges (e.g., Coinbase, Kraken, Gemini, Binance) and MyEtherWallet?",
    get_started: "Get Started",
    create_wallet: "How to create an Ethereum wallet using MEW",
    transfer_crypto: "How to transfer your cryptocurrency from Binance to Enkrypt or MEW wallet",
    create_cold_wallet: "How to create a cold wallet (also known as a paper wallet)",
    buy_sell_crypto: "Buying and selling cryptocurrency using MEW Portfolio",
    what_is_non_custodial_wallet: "What is a non-custodial wallet?",
    check_balance: "How to check your balance on the Ethereum blockchain",
    explore_deeply: "Explore Deeply",
    crypto_terms: "Glossary of common Ethereum/cryptocurrency terms",
    what_if_mew_fails: "What happens if MEW fails?",
    does_mew_support_btc: "Does MEW support Bitcoin (BTC) or other currencies?",
    what_is_eth_smart_contract: "What is an Ethereum smart contract?",
    error_window_blocked: "Error: Window blocked",
    ensure_unique_address: "Ensure MEW does not provide the same public address to different people",
    submit_pull_request: "How to submit a Pull Request to MEW's GitHub",
    does_mew_have_api: "Does MyEtherWallet have an API?",
    mew_api_request_limit: "Is there a limit on the number of requests I can make to the MEW API?",
    use_mew_as_pwa: "Using MEW as a Progressive Web App (PWA) or Chrome App",
  },
  index: {
    mew_team_advice: "Advice and answers from the MyEtherWallet team",
    most_reputable_wallet: "MyIceWallet supports the storage and sending of all ERC20 tokens in the Ethereum ecosystem.",
    create_new_wallet: "Create a New Wallet",
    or: "or",
    access_my_wallet: "Access My Wallet",
    our_products_make_crypto_easier: "You have exclusive control over your key vault, recovery phrase, and private keys.",
    available_on_mobile_and_desktop: "Please disconnect from the internet and store your wallet offline!",
    mew_mobile_app: "MEW Mobile App",
    stake_swap_manage_crypto: "Stake, swap, and manage your cryptocurrency anytime, anywhere.",
    security_like_hardware_wallet: "Protect your mobile cryptocurrency with security comparable to hardware wallets.",
    powerful_nft_management: "Powerful NFT management, on-chain browser, and more.",
    want_to_enter_crypto: "Want to enter the cryptocurrency space?",
    mew_is_best_choice: "MEW is your best choice.",
    ethereum_ecosystem: "The Ethereum ecosystem has the largest developer community in the entire web3.",
    trusted_ethereum_wallet: "MEW is one of the most trusted and time-tested Ethereum wallets.",
    not_investment_advice: "Not investment advice. All decisions are made at the user's own risk.",
    truly_own_your_crypto: "Truly own your crypto assets.",
    self_custody_wallet: "MEW is a self-custody wallet, which means you hold your own keys, and no one (not even the MEW team) can access your cryptocurrency.",
    buy_store_send_swap_tokens: "Buy, store, send, and swap tokens",
    store_tokens: "Store Tokens",
    buy_sell: "Buy and Sell",
    swap: "Swap",
    mew_supports_eth_and_erc20: "MEW supports ETH and all ERC-20 tokens!",
    get_tokens: "Get Tokens",
    make_crypto_yours: "Make your cryptocurrency truly yours.",
    stake_your_eth: "Stake your ETH and earn rewards on the Ethereum blockchain.",
    be_your_own_bank: "Be your own bank",
    crypto_as_banking: "Unlike large financial banks that lend your funds, in cryptocurrency, you are the bank. Earn rewards by helping to secure Ethereum.",
    staking_made_easy: "Staking made easy",
    easy_staking_with_mew: "At MEW, we make it easy for you to stake your cryptocurrency from your wallet and start earning rewards right away.",
    start_staking_now: "Start staking now",
    staking: "Staking",
    no_minimum_liquidity: "No minimum liquidity staking requirement",
    annual_rate_up_to: "Annual rate up to",
    powerful_wallet: "A powerful wallet to meet all your crypto needs.",
    store_all_your_nfts: "Store all your NFTs securely in one place from different chains, on mobile or desktop.",
    access_web3_apps: "Access web3 apps. Use Enkrypt on desktop to use your favorite web3 apps, or use the MEW mobile app on mobile to access your favorite web3 apps.",
    multi_chain_access: "Multi-chain access. Connect to decentralized networks and bridge assets between popular networks.",
    why_mew_is_good_choice: "Why MEW is a good choice?",
    crypto_expert_since_2015: "Crypto expert since 2015",
    first_trusted_wallet: "MEW is one of the world's first and most trusted Ethereum and L2 wallets.",
    private: "Private",
    no_tracking: "We do not track any personal information, account addresses, or asset balances.",
    hardware_wallet_support: "Hardware wallet support",
    support_major_hardware_wallets: "We support all major hardware wallets, including Ledger and Trezor in the MEW network product suite and Enkrypt.",
    secure_transparent: "Secure and transparent",
    independent_audits: "Undergo independent audits in the HackenProof and previous HackerOne Bug Bounty programs.",
    self_custody: "Self-custody",
    you_control_your_assets: "Only you control your assets. Your keys, your cryptocurrency.",
    easy_to_use: "Easy to use",
    start_in_minutes: "Get started in minutes with no prior knowledge required.",
    arm_yourself_with_knowledge: "Arm yourself with knowledge.",
    new_to_crypto: "New to cryptocurrency? Explore MEWtopia!",
    free_resources: "Utilize our free resource library to learn how to make the most of Ethereum!",
    help_center: "Help Center",
    faq: "Frequently Asked Questions",
    common_crypto_questions: "Common questions about cryptocurrency",
    chat_with_support: "Chat with Support",
    get_human_help: "Get help from real humans",
    create_new_wallet: "Create a New Wallet",
    ethereum_eth: "Ethereum (ETH)",
    ondo: "Ondo (ONDO)",
    unibot: "Unibot (UNIBOT)",
    busd: "Binance USD (BUSD)",
    icx: "ICON (ICX)",
    grt: "The Graph (GRT)",
    aurora: "Aurora (AURORA)",
    root: "Root Network (ROOT)",
    lime: "IME Labs (LIME)",
  },
  package: {
    ethereum: "Ethereum",
    buy_store_send: "Buy, Store, Send",
    usd: "USD",
    swap_tokens: "and Swap Tokens",
    mew_supports_eth_and_erc20: "MEW supports ETH and all ERC-20",
    make_your_crypto_yours: "Make your cryptocurrency truly yours.",
    tokens: "Tokens!",
    cryptocurrency: "Cryptocurrency.",
    meet_all_your_crypto_needs: "Meet all your crypto needs.",
    why_mew_is_good_choice: "Why MEW is a good choice?",
    buy_hardware_wallet: "Buy Hardware Wallet",
    portfolio_value: "View Wallet information",
    wallet: "Wallet",
    send: "Send",
    settings: "Settings",
    logout: "Logout",
    other_apps: "Other Apps",
    apps: "Apps",
    nft_manager: "NFT Manager",
    exchange: "Exchange",
    stake: "Stake",
    received: "Received",
    buy_sell: "Buy and Sell",
    contract: "Contract",
    info: "Info",
    new_feature_stake_any_amount_of_eth: "Be cautious of unknown links, be wary of using wallets in unfamiliar environments.",
    stake_eth_no_minimum: "Do not disclose your wallet key store, recovery phrase, or private key. Do not authorize anything.",
    stake_now: "Stake Now",
    advertise_with_us: "Advertise with Us",
    network: "Network",
    block_height: "Block Height:",
    total_assets: "Total Assets:",
    add_custom_token: "Add Custom Token",
    send: "Send",
    delete: "Delete",
    transaction_history: "Transaction History",
    transfer_out: "Out",
    transfer_in: "In",
    no_transactions: "You have no transactions yet",
    are_you_sure_logout: "Are you sure you want to log out?",
    are_you_sure_delete_token: "Are you sure you want to delete the current token?",
    tx_hash: "TX Hash",
    transaction_time: "Transaction Time",
    from: "From",
    to: "To",
    amount: "Amount",
    fee: "Fee",
    token: "Token",
    hold: "Hold",
    price: "Price",
    years_ago: "years ago",
    months_ago: "months ago",
    days_ago: "days ago",
    hours_ago: "hours ago",
    minutes_ago: "minutes ago",
    seconds_ago: "seconds ago",
    failed_to_get_height: "Failed to get height",
  },
  send: {
    line1: "Why MEW is a",
    line2: "Good Choice?",
    line3: "Operation Tips",
    line4: "Confirm",
  },
  zhensend: {
    balance: "Balance:",
    max_limit: "all",
    your: "Your",
    low_balance: "Balance is too low",
    transaction_fee: "Each transaction requires a small amount",
    cannot_send: "to execute. Even if you have tokens to exchange, when your",
    near_zero_balance: "balance is close to zero, you will not be able to send anything until you fund your account.",
    enter_address: "Please enter the transfer address",
    fee: "Transaction Fee",
    total: "Total:",
    insufficient_fee: "Not enough to cover network fees.",
    custom: "Custom",
    max_fee: "Maximum Fee",
    max_tip: "Maximum Tip",
    send_transaction: "Send Transaction",
    zero_balance: "Token balance is 0~",
    enter_amount: "Please enter the transfer amount",
    insufficient_token_balance: "Insufficient token balance",
    transaction_cost: "Transaction fee is",
    transaction_success: "Transaction successful",
    insufficient_gas: "Insufficient GAS",
    transaction_failed: "Transaction failed, please check the input",
    gas_limit_failed: "Gas Limit estimation failed",
    token_amount_prompt: "Please enter the quantity"
  }
};
